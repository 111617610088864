import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import { ScrollPanel } from "primereact/scrollpanel";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

export const Timers = (props) => {
  const [timers, setTimers] = useState([]);
  const [showModalCrear, setModalCrear] = useState(false);

  const TimersRender = useMemo(
    () =>
      timers.map((timer) => (
        <Timer
          key={timer}
          removeTimer={() => setTimers((tim) => tim.filter((e) => e !== timer))}
          title={timer.title}
        />
      )),
    [timers]
  );
  return (
    <div className="box" data-html2canvas-ignore>
      <Panel
        id="timers-panel"
        header={`Timers`}
        toggleable
        collapsed={props.timerCollapsed}
        onToggle={(e) => props.setTimerCollapsed(!props.timerCollapsed)}
        pt={{
          content: { className: "hide" },
        }}
      >
      </Panel>
      <div className="p-panel-content">
        <ScrollPanel
          style={{
            width: "100%",
            display: props.timerCollapsed ? "none" : "flex",
            flexDirection: "column",
            background: "white",
          }}
        >
          {TimersRender}
          <a
            style={{ color: "black", cursor: "pointer" }}
            onClick={() => setModalCrear(true)}
          >
            Create timer +
          </a>
        </ScrollPanel>
        {showModalCrear && (
          <ModalCrearTimer
            onClose={() => setModalCrear(false)}
            crearTimer={(title) => {
              setTimers((tims) => [
                ...tims,
                { id: `${tims.length + 1}-${new Date().getTime()}`, title },
              ]);
              setModalCrear(false);
            }}
          />
        )}
      </div>
    </div>
  );
};

const ModalCrearTimer = ({ crearTimer, onClose }) => {
  const [title, setTitle] = useState("");
  return (
    <Dialog
      header={"Crear timer"}
      className="p-fluid"
      visible={true}
      contentClassName="overflow-y-visible"
      onHide={onClose}
    >
      <InputText
        className="p-d-inline"
        placeholder="Titulo"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      <div
        style={{ display: "flex", justifyContent: "flex-end", marginTop: 5 }}
      >
        <div style={{ display: "inline-block" }}>
          <Button
            label="Crear"
            icon="pi pi-plus"
            className="btn-primary"
            onClick={() => crearTimer(title)}
          />
        </div>
      </div>
    </Dialog>
  );
};

const Timer = ({ removeTimer, title }) => {
  const timer = useTimer();

  return (
    <div>
      <b>{title}</b>
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <div style={{ width: 60 }}>{timer.renderedStreamDuration}</div>
        {!timer.isStartBtnDisabled && (
          <i
            onClick={timer.startHandler}
            style={{ cursor: "pointer", fontSize: 10 }}
            className="pi pi-play"
          />
        )}
        {!timer.isResumeBtnDisabled && (
          <i
            onClick={timer.resumeHandler}
            style={{ cursor: "pointer", fontSize: 10 }}
            className="pi pi-play"
          />
        )}
        {!timer.isPauseBtnDisabled && (
          <i
            onClick={timer.pauseHandler}
            style={{ cursor: "pointer", fontSize: 10 }}
            className="pi pi-pause"
          />
        )}
        <i
          onClick={timer.stopHandler}
          style={{ cursor: "pointer", fontSize: 10, marginLeft: 5 }}
          className="pi pi-stop-circle"
        />
        <i
          onClick={removeTimer}
          style={{ cursor: "pointer", fontSize: 10, marginLeft: 5 }}
          className="pi pi-times"
        />
      </div>
    </div>
  );
};

export const useTimer = () => {
  const [renderedStreamDuration, setRenderedStreamDuration] =
      useState("00:00:00"),
    streamDuration = useRef(0),
    previousTime = useRef(0),
    requestAnimationFrameId = useRef(null),
    [isStartTimer, setIsStartTimer] = useState(false),
    [isStopTimer, setIsStopTimer] = useState(false),
    [isPauseTimer, setIsPauseTimer] = useState(false),
    [isResumeTimer, setIsResumeTimer] = useState(false),
    isStartBtnDisabled = isPauseTimer || isResumeTimer || isStartTimer,
    isStopBtnDisabled = !(isPauseTimer || isResumeTimer || isStartTimer),
    isPauseBtnDisabled = !(isStartTimer || (!isStartTimer && isResumeTimer)),
    isResumeBtnDisabled = !isPauseTimer;

  const updateTimer = useCallback(() => {
    let now = performance.now();
    let dt = now - previousTime.current;

    if (dt >= 1000) {
      streamDuration.current = streamDuration.current + Math.round(dt / 1000);
      const formattedStreamDuration = new Date(streamDuration.current * 1000)
        .toISOString()
        .substr(11, 8);
      setRenderedStreamDuration(formattedStreamDuration);
      previousTime.current = now;
    }
    requestAnimationFrameId.current = requestAnimationFrame(updateTimer);
  }, []);

  const startTimer = useCallback(() => {
    previousTime.current = performance.now();
    requestAnimationFrameId.current = requestAnimationFrame(updateTimer);
  }, [updateTimer]);

  useEffect(() => {
    if (isStartTimer && !isStopTimer) {
      startTimer();
    }
    if (isStopTimer && !isStartTimer) {
      streamDuration.current = 0;
      cancelAnimationFrame(requestAnimationFrameId.current);
      setRenderedStreamDuration("00:00:00");
    }
  }, [isStartTimer, isStopTimer, startTimer]);

  const startHandler = () => {
    setIsStartTimer(true);
    setIsStopTimer(false);
  };

  const stopHandler = () => {
    setIsStopTimer(true);
    setIsStartTimer(false);
    setIsPauseTimer(false);
    setIsResumeTimer(false);
  };

  const pauseHandler = () => {
    setIsPauseTimer(true);
    setIsStartTimer(false);
    setIsResumeTimer(false);
    cancelAnimationFrame(requestAnimationFrameId.current);
  };

  const resumeHandler = () => {
    setIsResumeTimer(true);
    setIsPauseTimer(false);
    startTimer();
  };

  return {
    renderedStreamDuration,
    isStartBtnDisabled,
    isStopBtnDisabled,
    isPauseBtnDisabled,
    isResumeBtnDisabled,
    startHandler,
    stopHandler,
    pauseHandler,
    resumeHandler,
  };
};
