import axios from "../utilities/api-axios";
import { TokenAuthService } from "./TokenAuthService";
import { LoginModel } from "../model/LoginModel";
const apiUrl = process.env.REACT_APP_API_URL;
const PUBLIC_VAPID_KEY =
  "BAqa6zoBu6nNrDfTTbAh5fmMlnyqLGoDm9j9v9YsEPHtcJS0rb1wJq8e3FYRg3PLqQA775l1X6ZHwwWsvA3vmzs";

export class MapService {
  tokenService;

  constructor() {
    this.tokenService = new TokenAuthService();
  }

  async takePictureMap(data){
    return axios.post(`${apiUrl}/events/takePicture`, data);
  }

  async getToken() {
    return this.tokenService.getToken();
  }

  async getOfflineDrivers(eventId, selectedCategories) {
    return axios.get(
      `${apiUrl}/drivers/getDisconnecteds/${eventId}/${selectedCategories.join(
        ","
      )}`
    );
  }

  async updateByIdRoutes(request) {
    // request.stages.map((stage) => {
    //     let h = stage.StartTime.split(':')[0];
    //     let m = stage.StartTime.split(':')[1];
    //     stage.StartDate = new Date(new Date(stage.StartDate).setHours(h, m)).toISOString();
    // });
    let token = this.tokenService.getToken();
    return axios.put(`${apiUrl}/circuitRoutes`, request, {
      headers: { authorization: `Bearer ${token}` },
    });
  }

  async createCircuit(request) {
    let token = this.tokenService.getToken();
    return axios.post(`${apiUrl}/circuitRoutes`, request, {
      headers: { authorization: `Bearer ${token}` },
    });
  }

  async saveRoutes(circuitRoutes, eventId) {
    let token = this.tokenService.getToken();
    return axios.post(
      `${apiUrl}/circuitRoutes`,
      { eventId: eventId },
      circuitRoutes,
      { headers: { authorization: `Bearer ${token}` } }
    );
  }

  async savePois(poiValues) {
    let token = this.tokenService.getToken();
    return axios.post(`${apiUrl}/interestpoints`, poiValues, {
      headers: { authorization: `Bearer ${token}` },
    });
  }

  async getRoutesByEventId(eventId) {
    let token = this.tokenService.getToken();
    return axios.get(`${apiUrl}/circuitRoutes/${eventId}`, {
      headers: { authorization: `Bearer ${token}` },
    });
  }

  async getRouteByCircuitId(circuitId) {
    let token = this.tokenService.getToken();
    const response = await axios.get(
      `${apiUrl}/circuitRoutes/circuit/${circuitId}`,
      { headers: { authorization: `Bearer ${token}` } }
    );
    return response.data;
  }

  async getPoisByEventId(eventId) {
    let token = this.tokenService.getToken();
    return axios.get(`${apiUrl}/interestpoints/${eventId}`, {
      headers: { authorization: `Bearer ${token}` },
    });
  }

  async getCoordenates(driverId, cirId) {
    let token = this.tokenService.getToken();
    const response = await axios.get(
      `${apiUrl}/drivertest/${cirId}/${driverId}`,
      { headers: { authorization: `Bearer ${token}` } }
    );
    //await this.viewerSubscription(token);
    return response.data;
  }

  async getLastDriverPositionsByEventId(id, selectedCategories) {
    let token = this.tokenService.getToken();
    const response = await axios.get(
      `${apiUrl}/positionRecords/event/${id}/${selectedCategories.join(",")}`,
      { headers: { authorization: `Bearer ${token}` } }
    );
    return response.data;
  }

  async getLastPositionDriver(eveId, userId, selectedCategories) {
    let token = this.tokenService.getToken();
    const response = await axios.get(
      `${apiUrl}/positionRecords/event/${eveId}/driver/${userId}/${selectedCategories.join(
        ","
      )}`,
      { headers: { authorization: `Bearer ${token}` } }
    );
    return response.data;
  }

  async getInfoDriver(eventId, userId, categoryId) {
    let token = this.tokenService.getToken();
    const response = await axios.get(
      `${apiUrl}/drivers/format/${eventId}/${userId}/${categoryId}`,
      { headers: { authorization: `Bearer ${token}` } }
    );
    return response.data;
  }

  async deletePoiById(id) {
    let token = this.tokenService.getToken();
    return await axios.delete(`${apiUrl}/interestpoints/${id}`, {
      headers: { authorization: `Bearer ${token}` },
    });
  }

  async deleteCircuitById(circuitId, evenId) {
    let token = this.tokenService.getToken();
    return await axios.delete(
      `${apiUrl}/circuitRoutes/${evenId}/${circuitId}`,
      { evenId: evenId },
      { headers: { authorization: `Bearer ${token}` } }
    );
  }

  async SetMapByEventId(mapPosition) {
    return await axios.post(`${apiUrl}/events/map`, mapPosition);
  }

  async sendMessageToDrivers(alert) {
    return await axios.post(`${apiUrl}/flagRecords`, alert);
  }

  async cleanFlags(eveId) {
    return await axios.delete(`${apiUrl}/flagRecords/event/${eveId}`);
  }

  viewerSubscription = async (token) => {
    if ("serviceWorker" in navigator) {
      const register = await navigator.serviceWorker
        .register("/serviceWorker.js", {
          scope: "/",
        })
        .catch(function (error) {
          console.log("Service worker registration failed:", error);
        });

      console.log("Service worker is listening.");

      if (register) {
        const subscription = await register.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: this.urlBase64ToUint8Array(PUBLIC_VAPID_KEY),
        });

        await axios.post(
          `${apiUrl}/subscriptions/eventviewer`,
          JSON.stringify(subscription),
          {
            headers: {
              authorization: `Bearer ${token}`,
              "content-type": "application/json",
            },
          }
        );
        console.log("Service worker has been saved!.");
      }
    } else {
      console.log("Service workers are not supported.");
    }
  };

  urlBase64ToUint8Array(base64String) {
    const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding)
      .replace(/-/g, "+")
      .replace(/_/g, "/");

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }

  async importPolyline(file, eventId) {
    let formData = new FormData();
    //formData.append('DataWithHeader', "true");
    formData.append("EventId", eventId);
    formData.append("file", file);

    return await axios.post(
      `${apiUrl}/commonServices/uploadPolylines/`,
      formData
    );
  }

  // async uploadSectorPic(file, sectorId) {
  //     let formData = new FormData();
  //     formData.append('file', file);
  //     return await axios.post(`${apiUrl}/commonServices/uploadSectorPic/` + sectorId, formData);
  // }
}
