import React, { useState, useEffect, useRef } from "react";

//primereact
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";

//utils
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";

//services
import { TokenAuthService } from "../service/TokenAuthService";
import {
  ErrorDialog,
  ErrorDialogEmpty,
  ErrorDialogSet, //
  ErrorDialogNoAuthorizedSet,
} from "../dialogs/ErrorDialog";
import { ConfirmResetLapsDialog } from "../dialogs/ConfirmResetLapsDialog";

import { EventService } from "../service/EventService";

export const LiveNowEvent = (props) => {
  const emptyEvent = {
    EventId: 0,
    EventName: "Not current event",
    Competitors: 0,
    Inviteds: 0,
    CurrentlyRunning: [],
  };

  const history = useHistory();
  const params = useParams();

  const [loading, setLoading] = useState(true);
  const [stateErrorDialog, setStateErrorDialog] = useState(ErrorDialogEmpty);

  const [isDirector, setIsDirector] = useState(false);
  const [events, setEvents] = useState(null);
  const [event, setEvent] = useState(null);
  const [clubId, setClubId] = useState(0);
  const [isPlanner, setIsPlanner] = useState(false);
  const [resetLapsDialog, setResetLapsDialog] = useState(false);
  const [eventId, setEventId] = useState(null);
  const toast = useRef(null);

  useEffect(() => {
    try {
      const load = async () => {
        setLoading(true);

        let _clubId = 0;
        let tokenAuthService = new TokenAuthService();
        let isAdmin = tokenAuthService.isAdmin();
        if (!isAdmin) {
          let club = await tokenAuthService.getClub();
          _clubId = club.Id;
        }
        setClubId(_clubId);
        const eventService = new EventService();
        const resEvent = await eventService.getLiveNow(_clubId);
        //if (resEvent && resEvent.length > 0)
        setEvents(resEvent);

        let isDirector = tokenAuthService.isDirector();
        setIsDirector(isDirector);
        let isPlanner = tokenAuthService.isPlanner();
        setIsPlanner(isPlanner);

        setLoading(false);
      };

      load();
    } catch (error) {
      setStateErrorDialog(ErrorDialogNoAuthorizedSet(true, error));
    }
  }, []);

  if (loading)
    return (
      <div className="loading-container">
        <h3>Loading...</h3>
      </div>
    );

  const details = (id) => {
    if (id) {
      history.push("/event/" + id);
    }
  };

  const resetLapOpenDialog = (id) => {
    setEventId(id);
    setResetLapsDialog(true);
  };

  const showToast = (message, summary, type) => {
    toast.current.show({
      severity: type,
      summary: summary,
      detail: message,
      life: 10000,
    });
  };

  const resetLaps = async () => {
    const eventService = new EventService();
    await eventService.resetLaps(eventId);
    //alert(eventId.toString());
    showToast("Lap counter reseted", "Succeful", "success");
  };

  const liveRace = (id) => {
    if (id) {
      history.push("/map/" + id);
    }
  };

  if (loading)
    return (
      <div className="loading-container">
        <h3>Loading...</h3>
      </div>
    );

  return (
    <div>
      <div className="card">
        <h2 className="fs-x-normal">Live now</h2>
        {events.map((event, index) => (
          <div key={index}>
            <div className="p-formgrid p-grid p-ai-center livenow-panel">
              <div className=" p-col-7 p-d-flex p-jc-between">
                <p className="txt-bold">{event.EventName}</p>

                {clubId == 0 && (
                  <p className="txt-bold">{event.InstitutionName}</p>
                )}

                {clubId > 0 && (
                  <p className="txt-clrLight150">
                    {event.Competitors} competitors
                  </p>
                )}
                {clubId > 0 && (
                  <p className="txt-clrLight150">{event.Inviteds} invited</p>
                )}

                {event.EventId > 0 && (
                  <p className="p-d-flex p-ai-center">
                    {event.CurrentlyRunning.length > 0 &&
                      event.CurrentlyRunning.split(",").map((stage, index) => (
                        <span key={index} className="tag-running">
                          {stage}
                        </span>
                      ))}
                    {/* <span className="tag-running">SS01</span>
                                <span className="tag-running">SS04</span> */}
                  </p>
                )}
              </div>
              {event.EventId > 0 && (
                <div className=" p-col-5  p-d-flex p-jc-end">
                  {(!isDirector || isPlanner) && (
                    <Button
                      label={<u>View detail</u>}
                      className="p-button-text no-decoration txt-clrLight200 p-mr-4"
                      onClick={() => details(event.EventId)}
                    />
                  )}

                  {/* {isDirector && (
                    <Button
                      label={<u>Reset laps</u>}
                      className="p-button-text no-decoration txt-clrLight200 p-mr-4"
                      onClick={() => resetLapOpenDialog(event.EventId)}
                    />
                  )} */}

                  <Button
                    label="View live race"
                    icon="pi pi-circle-on"
                    className="btn-primary "
                    onClick={() => liveRace(event.EventId)}
                  />
                </div>
              )}
            </div>
            <br></br>
          </div>
        ))}
      </div>

      <ErrorDialog stateDialog={stateErrorDialog}></ErrorDialog>
      <Toast ref={toast} />

      <ConfirmResetLapsDialog
        visible={resetLapsDialog}
        hideDialog={() => setResetLapsDialog(false)}
        reset={resetLaps}
      ></ConfirmResetLapsDialog>
    </div>
  );
};
