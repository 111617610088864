import React from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { ErrorDialog, ErrorDialogEmpty, ErrorDialogNoAuthorizedSet, ErrorDialogSet, ErrorDialogSetResponse } from "../../dialogs/ErrorDialog";

export const DeleteDialog = (props) => {


    const hideDialog = () => {
        props.hideDialog();
    }


    const deleteItem = () => {
        hideDialog();

        props.deleteItem(props.item.id)
        .then(props.onDeleted) 
        .catch((res) => {
            props.setStateErrorDialog(ErrorDialogSetResponse(res));
        });;
    };

    const deleteDialogFooter = (
        <>
            <Button label="Cancel" className="btn-secondary" onClick={hideDialog} />
            <Button label="Delete" className="btn-primary" onClick={ deleteItem } />
        </>
    );

    return (
        <Dialog visible={props.visible} className="p-dialog-small" header={props.header} modal footer={deleteDialogFooter} onHide={hideDialog}>
            <div className="confirmation-content">
                {props.item && (
                    <span>
                        Are you sure you want to delete <b>{props.item.name}</b>?<br />
                        They will no longer be able to access the platform
                    </span>
                )}
            </div>
        </Dialog>
    )
}