import { useRef } from "react";
import { Marker, InfoWindow } from "@react-google-maps/api";
import { POI_VALUES, SVG } from "../consts/icons";
import { Draws } from "../core/Draws.js";
import { track_parts } from "../utilities/const";
import useDrawDriver from "./useDrawDriver";
import useTracker from "./useTracker";

const HIDE_TEXT = "Hide POIs";
const SHOW_TEXT = "Show POIs";

const useDraw = ({ eventId, cleckAPoiWindow }) => {
  const mapRef = useRef(null);
  const setMap = (map) => (mapRef.current = map);
  const getMap = () => mapRef.current;

  const { initializePois } = useTracker({ eventId });
  const {
    drawDriverTags,
    updateDriverTag,
    hidePositionByCategories,
    showPositionsByCategories,
    changeOfflineDrivers,
    processAlert,
    updateCounter,
  } = useDrawDriver({ eventId });

  const buildPois = (pois) => {
    const listItems = pois.map((poi, index) => {
      poi.index = index;

      let icon = {
        url: getSvgPath(poi.icon),
      };
      return (
        <Marker
          icon={icon}
          position={{ lat: poi.lat, lng: poi.lng }}
          clickable={true}
          key={index + 10}
          editable={false}
          draggable={false}
          onClick={() => {
            let isOpen = true;
            cleckAPoiWindow(poi, isOpen);
          }}
        >
          {poi.isOpen && (
            <InfoWindow 
              key={index}
              position={{ lat: poi.lat, lng: poi.lng }}
              onCloseClick={() => {
                let isOpen = false;
                cleckAPoiWindow(poi, isOpen);
              }}
            >
              <div className="info-window-body">
                {
                  <>
                    <div className="p-d-flex  p-jc-between p-ai-center p-mb-2">
                      <p className="fs-normal txt-bold"> {poi.description} </p>
                    </div>
                  </>
                }
              </div>
            </InfoWindow>
          )}
        </Marker>
      );
    });
    return listItems;
  };

  const buildTrack = (data, currentPoiTags, extraPois) => {
    const extraPoiTags = buildPois(extraPois);

    let _polylineTags = [];
    let draws = new Draws();

    let index = track_parts.POLYLINE;
    if (data[index] && data[index].coords) {
      _polylineTags.push(
        draws.drawPolyline2(data[index].coords, index, false, data[index].ref)
      );
    }

    //let _listItems = [...currentPoiTags];
    let _listItems = [null, null];
    index = track_parts.START;
    if (data[index] && data[index].coords) {
      const icon = getIcon(data[index].coords.type);
      let point = draws.drawPoint(
        data[index].coords,
        index,
        icon,
        data[index].ref,
        false
      );
      _listItems[0] = point;
    }

    index = track_parts.FINISH;
    if (data[index] && data[index].coords) {
      const icon = getIcon(data[index].coords.type);
      let point = draws.drawPoint(
        data[index].coords,
        index,
        icon,
        data[index].ref,
        false
      );
      _listItems[1] = point;
    }
    const newPoiTags = [..._listItems, ...extraPoiTags];

    index = track_parts.BOXES;
    if (data[index] && data[index].coords) {
      _polylineTags.push(
        draws.drawPolygon2(data[index].coords, index, false, data[index].ref)
      );
    }
    const polylineTags = _polylineTags;

    return {
      newPoiTags,
      polylineTags,
    };
  };

  const getIcon = (_stepDrawingTrack) => {
    let icon = null;
    if (_stepDrawingTrack === track_parts.START) {
      icon = {
        url: "pois/start.svg",
        fillColor: "green",
      };
    }

    if (_stepDrawingTrack === track_parts.FINISH)
      icon = { url: "pois/finish.svg" };

    return icon;
  };

  const getSvgPath = (icon) => {
    try {
      return SVG.find((poi) => poi.Description == icon).Path;
    } catch (error) {
      return SVG.find((poi) => poi.Description == POI_VALUES.LOCATION).Path;
    }
  };

  const drawDrivers = async (drivers, currentDrivers) => {
    const { newDrivers } = await drawDriverTags(
      drivers,
      currentDrivers,
      mapRef.current
    );
    return { newDrivers };
  };

  const updateDriverPosition = (position, drivers) => {
    if (!mapRef.current) return;
    let newDriver = updateDriverTag(position, drivers, mapRef.current);
    return newDriver;
  };

  const hideDriversByCategories = (drivers, selectedCategories) => {
    hidePositionByCategories(drivers, selectedCategories);
  };

  const showDriversByCategories = (drivers, selectedCategories) => {
    showPositionsByCategories(drivers, selectedCategories, mapRef.current);
  };

  const changeOfflineState = async (drives, offlineDrivers) => {
    await changeOfflineDrivers(drives, offlineDrivers, mapRef.current);
  };

  const setDriverAlert = async (alert, drivers) => {
    await processAlert(alert, drivers, mapRef.current);
  };

  const updateDriverCounter = async (counter, drivers) => {
    await updateCounter(counter, drivers);
  };

  const togglePois = async (allPoiTags, showToast) => {
    let newPoiTags = [allPoiTags[0], allPoiTags[1]];
    let poiBtnText = SHOW_TEXT;
    let extraPois =[];

    if (allPoiTags.length <= 2) {
      extraPois = await initializePois(showToast);

      const extraPoiTags = buildPois(extraPois);

      newPoiTags = [allPoiTags[0], allPoiTags[1], ...extraPoiTags];
      poiBtnText = HIDE_TEXT;
    }
    return {
      newPoiTags,
      poiBtnText,
      extraPois
    };
  };

  const toggleAPoiWindow = async (poi, poisContext, isOpen) => {
    const selectedPoi = poisContext.extraPois.find(p => p.id == poi.id);
    selectedPoi.isOpen = isOpen;
    const extraPoiTags = buildPois(poisContext.extraPois);
    let newPoiTags = [poisContext.newPoiTags[0], poisContext.newPoiTags[1], ...extraPoiTags];

    return {
      newPoiTags,
      extraPois:poisContext.extraPois
    };
  }

  return {
    setMap,
    buildTrack,
    drawDrivers,
    updateDriverPosition,
    hideDriversByCategories,
    showDriversByCategories,
    changeOfflineState,
    getMap,
    setDriverAlert,
    updateDriverCounter,
    togglePois,
    toggleAPoiWindow
  };
};

export default useDraw;
