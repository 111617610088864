// import { EditMap as EditMapOption } from "../core/EditMap";
import { ReferencePoint as ReferencePointMarker } from "../core/ReferencePoint";
import React, { useEffect, useState, createRef, useRef } from "react";
import { DrawingMenu } from "../components/menus/DrawingMenu";
import { NewPolylineMenu } from "../components/menus/NewPolylineMenu";
import { NewPoiMenu } from "../components/menus/NewPoiMenu";
import { NewMetaMenu } from "../components/menus/NewMetaMenu";
import { ClubService } from "../service/ClubService";
import { NewPoiModal } from "../components/modals/NewPoiModal";
import { NewpolylineModal } from "../components/modals/NewpolylineModal";
// import { SelectPolylineModal } from "../components/modals/SelectPolylineModal";
// import { SelectPolylineMenu } from "../components/menus/SelectPolylineMenu";
import { NewPoi } from "../core/NewPoi";
import { NewPolyline } from "../core/NewPolyline";
import CustomGoogleMap from "../components/CustomGoogleMap";
import { MapService } from "../service/MapService";
import { EventService } from "../service/EventService";
import { TracksService } from "../service/TracksService";
import { Toast } from "primereact/toast";
// import { Dialog } from "primereact/dialog";
import { track_parts } from "../utilities/const.js";
import { ConfirmMessageBox } from "../components/modals/ConfirmMessageBox.js";

const LIBRARIES = ["drawing", "geometry"];
const DEFAULT_ZOOM = parseInt(process.env.REACT_APP_DEFAULT_ZOOM);
const DEFAULT_MAP_POSITION = {
  lat: parseFloat(process.env.REACT_APP_DEFAULT_LAT),
  lng: parseFloat(process.env.REACT_APP_DEFAULT_LNG),
};

export const EditMap = (props) => {
  var mapService = new MapService();

  const drawingOptions = {
    drawingControl: false,
    polylineOptions: {
      editable: true,
      draggable: true,
      geodesic: true,
      clickable: true,
      strokeColor: "#d6ff22",
      strokeWeight: 4,
    },
  };

  //Map
  const [centerPositions, setCenterPositions] = useState(DEFAULT_MAP_POSITION);
  const [zoom, setZoom] = useState(DEFAULT_ZOOM);
  const [drawingMode, setDrawingMode] = useState(null);
  const toast = useRef(null);
  const [googleMapOptions, setGoogleMapOptions] = useState({
    scrollwheel: true,
    scaleControl: false,
    mapTypeControl: false,
    draggable: true,
    zoomControl: true,
    disableDefaultUI: true,
    mapId: "26e5544cff477d51",
    mapTypeId: "satellite",
    draggableCursor: null,
  });

  const [circuits, setCircuits] = useState([]);
  //const [polylineMetaTags, setPolylineMetaTags] = useState([]);
  const [infoWindowsTags, setInfoWindowsTags] = useState([]);
  const [poiTags, setPoiTags] = useState([]);
  const [poiValues, setPoiValues] = useState([]);
  // const [polylineModal, setPolylineModal] = useState(false);
  const [poiModalState, setPoiModalState] = useState(false);
  const [newPolylineModal, setNewPolylineModal] = useState(false);
  // const [selectedPolyline, setSelectedPolyline] = useState({});
  const [newImportPolyline, setNewImportPolyline] = useState(false);

  const [poiDetails, setPoiDetails] = useState({});
  const [infoIndex, setInfoIndex] = useState(0);
  // const [deleteCircuitDialog, setDeleteCircuitDialog] = useState(false);
  // const [deleteCircuit, setDeleteCircuit] = useState(0);
  const [trackStatusText, setTrackStatusText] = useState("Track");
  const [editTrackText, setEditTrackText] = useState("Edit");
  const [visibleNewButtons, setVisibleNewButtons] = useState(true);
  const [confirmDeletePolyline, setConfirmDeletePolyline] = useState(false);
  const [confirmDeleteStart, setConfirmDeleteStart] = useState(false);
  const [confirmDeleteFinish, setConfirmDeleteFinish] = useState(false);
  const [confirmDeleteBoxes, setConfirmDeleteBoxes] = useState(false);
  const [confirmSave, setConfirmSave] = useState(false);
  const [deletePoint, setDeletePoint] = useState(null);

  const [trackRef, setTrackRef] = useState([]);
  const [polylineTags, setPolylineTags] = useState([]); //almacena los objetos que se van a graficar
  const [stepDrawingTrack, setStepDrawingTrack] = useState(
    track_parts["POLYLINE"]
  );

  const [detailsPolyline, setDetailsPolyline] = useState({
    circuitName: "",
    inputPolylineName: createRef(),
    estimated: "",
    stages: [],
    flagPosition: "",
  });

  const [detailsNewPolyline, setDetailsNewPolyline] = useState({
    trackName: "",
    circuitId: null,
    inputTrackName: createRef(),
    inputCircuitId: createRef(),
    trackId: null,
  });

  const [referencePoint, setReferencePoint] = useState({
    latInput: createRef(),
    lngInput: createRef(),
    tags: [],
  });

  //Map
  const [map, setMap] = useState(null);

  //Menu
  const [enableDrawingMenu, setEnableDrawingMenu] = useState(true);
  const [enableNewPolylineMenu, setEnableNewPolylineMenu] = useState(false);
  const [enableNewMetaMenu, setEnableNewMetaMenu] = useState(false);
  const [enableNewPoiMenu, setEnableNewPoiMenu] = useState(false);

  //revisar si se pueden borrar
  const [showMenu, setShowMenu] = useState(false);
  const [shape, setShape] = useState(undefined);
  const [trackCoords, setTrackCoords] = useState({}); //almacena las coordenadas {lat,long} y las referencias
  const [shapesNewTrack, setShapesNewTrack] = useState([]);

  const [polylineErrorMessage, setPolylineErrorMessage] = useState("");
  const [selectErrorMessage, setSelectErrorMessage] = useState("");

  //Event y Track
  const [eventId, setEventId] = useState(props.match.params.id);
  const [trackId, setTrackId] = useState(null);
  const [trackName, setTrackName] = useState("");

  //ref
  const mapRef = useRef(map);
  const poiRef = useRef([]);
  const editablePoints = useRef(false);

  const setVisibleConfirmMessageBox = (c, r, p) => {
    setDeletePoint(null);
    if (p && p.vertex !== undefined) {
      setDeletePoint(p);
    }

    switch (c) {
      case 1:
        setConfirmDeletePolyline(r);
        break;
      case 2:
        setConfirmDeleteStart(r);
        break;
      case 3:
        setConfirmDeleteFinish(r);
        break;
      case 4:
        setConfirmDeleteBoxes(r);
        break;
      default:
      // code block
    }
  };

  var newPolyline = new NewPolyline(
    poiRef,
    setPoiTags,

    eventId,
    mapService,
    polylineTags,
    setPolylineTags,
    trackRef,
    setTrackRef,
    setCenterPositions,
    setZoom,

    setPoiValues,
    setNewPolylineModal,
    setDetailsNewPolyline,
    detailsNewPolyline,
    setDrawingMode,
    setShape,
    shape,
    setPolylineErrorMessage,
    stepDrawingTrack,
    setStepDrawingTrack,
    (title, message, type) => showToast(title, message, type),
    trackCoords,
    setTrackCoords,
    () => {
      back();
    },
    shapesNewTrack,
    setShapesNewTrack,
    setTrackStatusText,
    setVisibleConfirmMessageBox
  );

  const openPoiModal = (val) => {
    setPoiModalState(val);
  };

  var newPoi = new NewPoi(
    eventId,
    mapService,
    setPolylineTags,
    setInfoWindowsTags,
    setPoiTags,
    poiRef,
    setPoiValues,
    openPoiModal,
    poiValues,
    poiDetails,
    setInfoIndex,
    infoIndex,
    setPoiDetails,
    (title, message, type) => showToast(title, message, type),
    () => {
      back();
    },
    editablePoints
  );

  var ReferenceMarker = new ReferencePointMarker(
    referencePoint,
    setReferencePoint
  );

  useEffect(() => {
    mapRef.current = map;
  }, [map]);

  useEffect(() => {
    poiRef.current = poiTags;
  }, [poiTags]);

  const getTrack = async () => {
    let eventService = new EventService();
    let _event = await eventService.getEvent(eventId);
    let tracksService = new TracksService();
    let _detailsNewPolyline = { ...detailsNewPolyline };
    _detailsNewPolyline.trackName = "";
    _detailsNewPolyline.trackId = _event.traId;
    setTrackId(_event.traId);
    if (_event.traId) {
      let _track = await tracksService.getById(_event.traId);
      if (_track.traName) {
        setTrackName(_track.traName);
        _detailsNewPolyline.trackName = _track.traName;
      }
      await newPolyline.setTrack(_event.traId);
    } else {
      let refData = [
        { coords: [], ref: createRef() },
        { coords: null, ref: createRef() },
        { coords: null, ref: createRef() },
        { coords: [], ref: createRef() },
      ];
      newPolyline.drawTrack2(refData, false);
      setTrackRef(refData);

      let mapPosition = DEFAULT_MAP_POSITION;
      let clubService = new ClubService();
      let club = await clubService.getClubByEventId(eventId);
      if (club.cluLatitude && club.cluLongitude) {
        if (club.cluLatitude != "" && club.cluLongitude != "") {
          mapPosition = {
            lat: parseFloat(club.cluLatitude.replace(",", ".")),
            lng: parseFloat(club.cluLongitude.replace(",", ".")),
          };
        }
      }

      setCenterPositions(mapPosition);
      setZoom(DEFAULT_ZOOM);
    }
    newPoi.initializePois(false);
  };

  useEffect(() => {
    const load = async () => {
      await getTrack();

      //initialMap.initializePois();
      setDrawingMode(null);
    };
    load();
  }, []);

  useEffect(() => {
    window.addEventListener("keydown", (e) => {
      newPolyline.handleEscapeKeyDown(e);
    });
    return () => {
      window.removeEventListener("keydown", (e) => {
        newPolyline.handleEscapeKeyDown(e);
      });
    };
  }, []);

  const setMenu = (menu) => {
    setEditTrackText("Edit");
    setEnableDrawingMenu(menu === 1);
    setEnableNewPolylineMenu(menu === 2);
    setEnableNewMetaMenu(menu === 3);
    setEnableNewPoiMenu(menu === 4);
  };

  /* eventos del menú */
  const editTrack = async () => {
    if (editTrackText === "Save") {
      setNewPolylineModal(true);
    } else {
      closeAll();
      setMenu(1);
      setStepDrawingTrack(track_parts["POLYLINE"]);

      setPolylineTags([]);
      setInfoWindowsTags([]);
      setPoiTags([]);
      setShowMenu(false);

      newPolyline.drawTrack2(trackRef, true);
      newPoi.initializePois(true);

      setTrackStatusText("Edit track");
      setEditTrackText("Save");
      setDrawingMode(null);
      setVisibleNewButtons(false);
    }
  };

  const cancelEditTrack = async () => {
    await getTrack();
    setMenu(1);
    setDrawingMode(null);
    setVisibleNewButtons(true);
    setNewImportPolyline(false);
  };

  const back = async () => {
    closeAll();
    setMenu(1);
    //alert(trackId);
    await getTrack();
  };

  const newPolylineTrack = () => {
    setMenu(2);

    newPolyline.initializeNewPolylineTrack();
    newPolyline.setGoogle(window.google);
  };

  const newStart = () => {
    setMenu(3);

    //setEnableSelect(false);
    setDrawingMode(null);

    poiTags[0] = null;
    newPolyline.initializeNewStart();
    setGoogleMapOptions({ ...googleMapOptions, draggableCursor: "crosshair" });
    newPolyline.setGoogle(window.google);
  };

  const newFinish = () => {
    setMenu(3);

    //setEnableSelect(false);
    setDrawingMode(null);

    poiTags[1] = null;
    newPolyline.initializeNewFinish();
    setGoogleMapOptions({ ...googleMapOptions, draggableCursor: "crosshair" });
    newPolyline.setGoogle(window.google);
  };

  const newBoxes = () => {
    setMenu(2);
    newPolyline.initializeNewBoxes();
    newPolyline.setGoogle(window.google);
  };

  const newpoi = async () => {
    closeAll();
    setMenu(4);
    if (trackId) {
      await newPolyline.setTrack(trackId);
    }
    newPoi.initializePois(false);
    setGoogleMapOptions({ ...googleMapOptions, draggableCursor: "crosshair" });
  };

  const importpolyline = async (file) => {
    try {
      setTrackRef([]);
      let res = await newPolyline.getImportTrack(file, eventId);
      if (res) showToast(``, "Polyline was loaded", "success");

      newPolyline.drawTrack2(res.refData, true);
      setTrackRef(res.refData);

      setStepDrawingTrack(track_parts["POLYLINE"]);
      if (!trackId) {
        setNewImportPolyline(true);
        //setNewPolylineModal(true);
      }
      setCenterPositions(res.mapPosition);
      setZoom(res.zoom);
      setEditTrackText("Save");
      setVisibleNewButtons(false);
    } catch (res) {
      console.log(res);
      let message = "An error occurred trying to decode the file";
      if (
        res.response &&
        res.response.data &&
        res.response.data.statusCode === 418
      )
        message = res.response.data.message;
      showToast(``, message, "error");
    }
  };

  /*  CIERRES DE SHAPES --------------------------------------*/

  const drawingOverlayComplete = (e) => {
    if (e.type !== window.google.maps.drawing.OverlayType.POLYLINE) return;
    newPolyline.drawingOverlayComplete(e, stepDrawingTrack);
  };

  const handlerClickEvent = (e) => {
    if (enableNewPoiMenu) {
      newPoi.handlerClickEvent(e, circuits, null, null);
    }
    if (enableNewMetaMenu) {
      let coords = {
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
        type: stepDrawingTrack,
      };
      let newRef = { coords: coords, ref: createRef() };

      let _trackRef = [...trackRef];
      _trackRef[stepDrawingTrack] = newRef;

      newPolyline.drawNewMeta(_trackRef, stepDrawingTrack, true);
      setTrackRef(_trackRef);

      setDrawingMode(null);
      setGoogleMapOptions({ ...googleMapOptions, draggableCursor: null });
    }
  };

  /* FUNCIONES AUXILIARES -----------------------------------------------*/
  const saveMenuTrack = () => {
    if (trackId) {
      newPolyline.updateDeatilsPolyline(trackId, trackName);
    } else {
      setEnableNewPolylineMenu(true);
      setNewPolylineModal(true);
    }
  };

  const saveMenuMeta = () => {
    if (trackId) {
      newPolyline.updateDeatilsPolyline(trackId, trackName);
    } else {
      //setEnableNewPolylineMenu(true);
      setMenu(3);
      setNewPolylineModal(true);
    }
  };

  const mapPositionCurrent = () => {
    let zoom = mapRef.current.getZoom();
    let mapPosition = mapRef.current.getCenter();

    let position = {
      lat: mapPosition.lat(),
      lng: mapPosition.lng(),
      zoom: zoom,
    };

    return position;
  };

  const saveTrack = (_trackId, _trackName) => {
    setTrackName(_trackName);
    let mapPosition = mapPositionCurrent();
    newPolyline.updateDeatilsPolyline(_trackId, _trackName, mapPosition);
    newPoi.saveChanges();
    setEditTrackText("Edit");
    setVisibleNewButtons(true);
  };

  const closeAll = () => {
    //setEnableSelect(false);
    setDrawingMode(null);
    setGoogleMapOptions({ ...googleMapOptions, draggableCursor: null });
    setReferencePoint({ ...referencePoint, tags: [] });
  };

  const showToast = (message, summary, type) => {
    toast.current.show({
      severity: type,
      summary: summary,
      detail: message,
      life: 10000,
    });
  };

  const pendingChanges = async () => {
    let tm = await newPolyline.trackModified(trackId);
    let pm = await newPoi.poisModified(eventId);
    return tm || pm;
  };

  const backToPreviousPage = async () => {
    let r = await pendingChanges();
    if (r) {
      setConfirmSave(true);
    } else window.history.back();
  };

  const handleLoad = React.useCallback(function callback(newMap) {
    setMap(newMap);
  }, []);

  const setMapPosition = async () => {
    if (trackId) {
      let zoom = mapRef.current.getZoom();
      let mapPosition = mapRef.current.getCenter();

      let trackService = new TracksService();
      let centerPosition = {
        position: {
          lat: mapPosition.lat(),
          lng: mapPosition.lng(),
        },
        zoom: zoom,
      };

      await trackService.SetMapByTrackId(trackId, centerPosition);

      showToast(`Map Position Updated`, "", "success");
    }
  };

  const setPoiDetails2 = (details) => {
    setPoiDetails(details);
  };

  const findReferencePoint = () => {
    let lat = referencePoint.latInput.current.value;
    let lng = referencePoint.lngInput.current.value;

    if (validateDecimal(lat) && validateDecimal(lat)) {
      showToast(``, "The coordinates entered are incorrect", "error");
    } else {
      ReferenceMarker.drawPointOnMap(lat, lng);
      let mapPosition = { lat: parseFloat(lat), lng: parseFloat(lng) };
      setCenterPositions(mapPosition);
    }
  };

  function validateDecimal(valor) {
    var RE = /^\d*\.?\d*$/;
    if (RE.test(valor)) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <>
      {enableDrawingMenu && (
        <>
          <DrawingMenu
            selectText={editTrackText}
            select={editTrack}
            cancel={async () => {
              let r = await pendingChanges();
              if (r) setConfirmSave(true);
              else cancelEditTrack();
            }}
            newPoi={newpoi}
            newPolylineTrack={newPolylineTrack}
            newStart={newStart}
            newFinish={newFinish}
            newBoxes={newBoxes}
            importpolyline={importpolyline}
            backToPreviousPage={backToPreviousPage}
            setMapPosition={setMapPosition}
            trackId={trackId}
            newImportPolyline={newImportPolyline}
            visibleNewButtons={visibleNewButtons}
          />
        </>
      )}
      {enableNewPoiMenu && (
        <>
          <NewPoiMenu
            back={(e) => {
              if (pendingChanges()) setConfirmSave(true);
              else back();
            }}
            insert={(e) => {
              newPoi.insert();
            }}
            select={(e) => {
              newPoi.select();
            }}
            cancel={(e) => {
              if (pendingChanges()) setConfirmSave(true);
              else back();
            }}
            save={async (e) => {
              var response = await newPoi.saveChanges();
              if (response.status) {
                showToast(``, response.message, "success");
                back();
              } else {
                showToast(``, response.message, "error");
              }
            }}
          ></NewPoiMenu>
        </>
      )}
      {poiModalState && (
        <NewPoiModal
          poiModalState={poiModalState}
          newPoi={newPoi}
          setPoiModalState={setPoiModalState}
          poiDetails={poiDetails}
          setPoiDetails={setPoiDetails2}
        ></NewPoiModal>
      )}
      {enableNewMetaMenu && (
        <>
          <NewMetaMenu
            drawingMode={drawingMode}
            back={(e) => {
              if (pendingChanges()) setConfirmSave(true);
              else back();
            }}
            cancel={() => {
              if (pendingChanges()) setConfirmSave(true);
              else back();
            }}
            save={() => {
              saveMenuMeta();
            }}
            title={trackStatusText}
          ></NewMetaMenu>
        </>
      )}
      {enableNewPolylineMenu && (
        <>
          <NewPolylineMenu
            drawingMode={drawingMode}
            back={(e) => {
              if (pendingChanges()) setConfirmSave(true);
              else back();
            }}
            cancel={() => {
              if (pendingChanges()) setConfirmSave(true);
              else back();
            }}
            savePolyline={() => {
              saveMenuTrack();
            }}
            referencePoint={referencePoint}
            findPoint={findReferencePoint}
            trackStatusText={trackStatusText}
          ></NewPolylineMenu>
        </>
      )}
      {newPolylineModal && (
        <NewpolylineModal
          erroMessage={polylineErrorMessage}
          setErrorMessage={setPolylineErrorMessage}
          newPolylineModal={newPolylineModal}
          setNewPolylineModal={setNewPolylineModal}
          trackId={trackId}
          trackName={trackName}
          setTrackName={setTrackName}
          save={saveTrack}
        ></NewpolylineModal>
      )}
      <CustomGoogleMap
        googleMapId={"drawing-manager-example"}
        libraries={LIBRARIES}
        zoom={zoom}
        googleOptions={googleMapOptions}
        centerPositions={centerPositions}
        drawingOptions={drawingOptions}
        drawingMode={drawingMode}
        polylineTags={polylineTags}
        infoWindowsTags={infoWindowsTags}
        poiTags={[...poiTags, ...referencePoint.tags]}
        drawingOverlayComplete={drawingOverlayComplete}
        handlerClickEvent={handlerClickEvent}
        onLoad={handleLoad}
      ></CustomGoogleMap>
      <Toast ref={toast} position="bottom-right" />
      <ConfirmMessageBox
        visible={confirmDeletePolyline}
        header={deletePoint ? "Delete point?" : "Delete polyline?"}
        yesno={false}
        message={deletePoint ? "Delete point?" : "Delete polyline?"}
        close={(r) => {
          if (r)
            if (deletePoint)
              newPolyline.deletePoint(track_parts.POLYLINE, deletePoint);
            else newPolyline.deletePart(track_parts.POLYLINE);
          setConfirmDeletePolyline(false);
        }}
      ></ConfirmMessageBox>
      <ConfirmMessageBox
        visible={confirmDeleteStart}
        header=""
        yesno={false}
        message="Delete start point?"
        close={(r) => {
          if (r) newPolyline.deletePart(track_parts.START);
          setConfirmDeleteStart(false);
        }}
      ></ConfirmMessageBox>
      <ConfirmMessageBox
        visible={confirmDeleteFinish}
        header=""
        yesno={false}
        message="Delete finish point?"
        close={(r) => {
          if (r) newPolyline.deletePart(track_parts.FINISH);
          setConfirmDeleteFinish(false);
        }}
      ></ConfirmMessageBox>
      <ConfirmMessageBox
        visible={confirmDeleteBoxes}
        header=""
        yesno={false}
        message={deletePoint ? "Delete point?" : "Delete boxes?"}
        close={(r) => {
          if (r)
            if (deletePoint)
              newPolyline.deletePoint(track_parts.BOXES, deletePoint);
            else newPolyline.deletePart(track_parts.BOXES);
          setConfirmDeleteBoxes(false);
        }}
      ></ConfirmMessageBox>
      <ConfirmMessageBox
        visible={confirmSave}
        header=""
        yesno={true}
        message={"Save the changes?"}
        close={(r) => {
          setConfirmSave(false);
          if (r) {
            saveTrack(trackId, trackName);
          } else {
            cancelEditTrack();
          }
        }}
      ></ConfirmMessageBox>
    </>
  );
};
