import { useState } from "react";
import classNames from "classnames";
import { InputText } from "primereact/inputtext";

export const Password = (props) => {
  const [value, setValue] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const asterisked = () => {
    setShowPassword(!showPassword);
  };

  const changeValue = (value) => {
    setValue(value);
    props.onChange(value);
  };

  return (
    <>
      <div className="p-inputgroup p-field">
        <InputText
          type={showPassword ? "text" : "password"}
          //type="text"
          id="password"
          className="p-d-block"
          placeholder={props.placeholder}
          value={props.password}
          maxLength="20"
          onChange={(e) => changeValue(e.target.value)}
          className={classNames({ "p-invalid": props.required && !value })}
        />

        <span className="p-inputgroup-addon">
          <i className="pi pi-eye" onClick={asterisked}></i>
        </span>
      </div>
      {!value && props.required && (
        <small className="p-invalid">{props.messageRequired}</small>
      )}
    </>
  );
};
