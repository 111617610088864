import React, { useRef, useEffect, useState } from "react";
import {
  GoogleMap,
  LoadScript,
  DrawingManager,
  Marker,
  Polyline,
  InfoWindow,
} from "@react-google-maps/api";

const containerStyle = {
  // width: "100%",

  width: "calc(100% + 2rem)",
  height: "calc(100vh - 87px)",
  left: "-1rem",
};

const CustomGoogleMap = (props) => {
  const customStyle = props.containerStyle || containerStyle;

  const googleMapComponent = () => {
    return (
      <GoogleMap
        mapTypeId={"satellite"}
        id={props.googleMapId}
        onLoad={props.onLoad}
        mapContainerStyle={customStyle}
        center={props.centerPositions}
        zoom={2}
        onClick={props.handlerClickEvent}
        options={props.googleOptions}
        onZoomChanged={props.onZoomChanged}
      >
        <DrawingManager
          drawingMode={props.drawingMode}
          onOverlayComplete={props.drawingOverlayComplete}
          options={props.drawingOptions}
        />
        {props.polylineTags}
        {props.infoWindowsTags}
        {props.poiTags}
        {props.flagTags}
      </GoogleMap>
    );
  };

  if (!props.withoutLoadScript)
    return (
      <>
        {/* googleMapsApiKey="YOUR_API_KEY"  googleMapsApiKey="AIzaSyD9MzORO1NUALLUYVbLPeYsg2JQZX_XM2U" */}
        <LoadScript
          libraries={props.libraries}
          googleMapsApiKey={"AIzaSyDVcEEsTDL9NS9wuTHGC-4mL3homv_PTy4"}
        >
          {googleMapComponent()}
        </LoadScript>
      </>
    );

  if (props.withoutLoadScript) return <>{googleMapComponent()}</>;
};

export default CustomGoogleMap;
