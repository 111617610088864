import React, { useState, useEffect, useRef } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";

import classNames from "classnames";
import { $ } from "react-jquery-plugin";

import { SectorsService } from "../../service/SectorsService";
import { TracksService } from "../../service/TracksService";
import {
  ErrorDialog,
  ErrorDialogEmpty,
  ErrorDialogNoAuthorizedSet,
  ErrorDialogSet,
  ErrorDialogSetResponse,
} from "../../dialogs/ErrorDialog";

const apiUrl = process.env.REACT_APP_API_URL;

export const EditDialog = (props) => {
  const [submitted, setSubmitted] = useState(false);
  const [item, setItem] = useState(props.item);
  const [tracks, setTracks] = useState(null);
  const [spinning, setSpinning] = useState(false);
  const [pictureChanged, setPictureChanged] = useState("");

  const fileInputRef = useRef();

  useEffect(() => {
    const _item = { ...props.item };
    setItem(_item);
    setSubmitted(false);
    const load = async () => {
      const tracksService = new TracksService();
      const _tracks = await tracksService.getAll();
      setTracks(_tracks);
    };
    load();
  }, [props.visible]);

  const setField = (field) => (ev) =>
    setItem({ ...item, [field]: ev.target.value });

  const validateRequired = () => {
    //if (!user.isPlanner && !user.isViewer && !user.isDirector) return false;

    for (let k = 0; k < $("[Required]").length; k++) {
      if (!$("[Required]")[k].value) return false;
    }
    if (item.secNumber === 0) return false;
    if (item.secPicpath === "") return false;
    return true;
  };

  const save = () => {
    setSubmitted(true);

    try {
      if (validateRequired()) {
        let _sector = { ...item };
        if (_sector.secId) {
          const sectorsService = new SectorsService();
          sectorsService
            .updateSector(_sector, pictureChanged)
            .then((data) => {
              props.onChange(_sector);
              props.hideDialog();
              setPictureChanged("");
            })
            .catch((res) => {
              var message = "";
              if (
                res.response &&
                res.response.data &&
                res.response.data.statusCode === 418
              )
                message = res.response.data.message;

              props.setStateErrorDialog(ErrorDialogSet(true, message));
            });
        } else {
          const sectorsService = new SectorsService();
          sectorsService
            .createSector(_sector)
            .then((data) => {
              _sector.secId = data.secId;
              _sector.secNumber = data.secNumber;
              props.onChange(_sector);
              props.hideDialog();
              setPictureChanged("");
            })
            .catch((res) => {
              props.setStateErrorDialog(ErrorDialogSetResponse(res));
            });
        }
      }
    } catch (error) {
      props.setStateErrorDialog(ErrorDialogSet(true, ""));
      console.log(error.message);
    }
  };

  const dialogFooter = (
    <>
      <Button
        label="Cancel"
        className="btn-secondary"
        onClick={props.hideDialog}
      />
      <Button label="Save" className="btn-primary" onClick={save} />
    </>
  );

  const handleChange = async (e) => {
    try {
      if (!spinning) {
        const sectorsService = new SectorsService();
        setSpinning(true);

        const fileName = await sectorsService.updloadPicture(e.target.files[0]);

        setItem({ ...item, secPicpath: fileName });

        setSpinning(false);
        //window.location.reload();
      }
    } catch (error) {
      setSpinning(false);
      //toast.current.show({ severity: "error", detail: "An error has occurred trying to get the driver position.", life: 3000 });
    }
  };

  const getSectorPic = (secPicpath) => {
    if (!secPicpath) return "assets/rally/upload.png";

    return apiUrl + "/" + secPicpath;
  };

  return (
    <Dialog
      visible={props.visible}
      style={{ width: "250px" }}
      header="Sectors Details"
      modal
      className="p-fluid"
      footer={dialogFooter}
      onHide={props.hideDialog}
    >
      <div className="p-field">
        <label htmlFor="name">Number</label>
        <InputNumber
          id="name"
          value={item.secNumber}
          onValueChange={setField("secNumber")}
          required={true}
          autoFocus
          className={classNames({ "p-invalid": submitted && !item.secNumber })}
        />
        {submitted && !item.secNumber && (
          <small className="p-invalid">
            A number greater than 0 is required.
          </small>
        )}
      </div>

      <div className="p-field" style={{ "text-align": "center" }}>
        <img
          src={getSectorPic(item.secPicpath)}
          onClick={() => fileInputRef.current.click()}
          margin-left="auto"
          margin-right="auto"
          width="50%"
          height="50%"
        ></img>
      </div>
      <div>
        {submitted && item.secPicpath === "" && (
          <small className="p-invalid">A image is required.</small>
        )}
      </div>

      <input type="file" onChange={handleChange} ref={fileInputRef} hidden />
    </Dialog>
  );
};
