import axios from "../utilities/api-axios";

const apiUrl = process.env.REACT_APP_API_URL;

export class SectorsService {
  getById(id) {
    return axios.get(`${apiUrl}/sectors/` + id).then((res) => res.data);
  }

  async getAll() {
    return axios.get(`${apiUrl}/sectors/`).then((res) => res.data);
  }

  async getAllByTrack(trackId) {
    return axios
      .get(`${apiUrl}/sectors/byTrack/` + trackId)
      .then((res) => res.data);
  }

  async updloadPicture(file) {
    let formData = new FormData();
    formData.append("file", file);
    const res = await axios.post(
      `${apiUrl}/commonServices/uploadSectorPic/`,
      formData
    );
    return res.data.fileName;
  }

  async updateSector(sector) {
    const updItem = {
      Number: sector.secNumber,
      Picpath: sector.secPicpath,
      Kms: sector.Kms,
      TraId: sector.traId,
    };
    return axios
      .put(`${apiUrl}/sectors/` + sector.secId, updItem)
      .then((res) => res.data);
  }

  createSector(sector) {
    const newSector = {
      Number: sector.secNumber,
      Picpath: sector.secPicpath,
      Kms: sector.Kms,
      TraId: sector.traId,
    };
    return axios.post(`${apiUrl}/sectors/`, newSector).then((res) => {
      return res.data;
    });
  }

  deleteSector(id) {
    return axios
      .delete(`${apiUrl}/sectors/` + id, { _method: "DELETE" })
      .then((res) => {
        return res.data;
      });
  }
}
