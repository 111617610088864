import React, { useState, useEffect, useRef } from "react";

//primereac
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { TabPanel, TabView } from "primereact/tabview";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";

import { useHistory } from "react-router-dom";
import { Utils } from "../utilities/Utils";
//Components
import { LiveNowEvent } from "../components/LiveNowEvent";
import { HeaderPageDetail } from "../components/headers/HeaderPageDetail";

//serrvice
import { TokenAuthService } from "../service/TokenAuthService";
import { ClubService } from "../service/ClubService";
import { CountryService } from "../service/CountryService";
import { EventService } from "../service/EventService";
import { EventDialog } from "../dialogs/EventDialog";

import {
  ErrorDialog,
  ErrorDialogEmpty,
  ErrorDialogSet,
  ErrorDialogSetResponse,
  ErrorDialogNoAuthorizedSet,
} from "../dialogs/ErrorDialog";

export const Events = () => {
  let emptyEvent = {
    EventId: null,
    Name: "",
    Start: null,
    Finish: null,
    ClubId: null,
    ClubName: "",
    Localization: "",
    Country: "",
    Invited: 0,
    Authorized: 0,
    Schedules: "",
    CircuitName: "",
  };

  const [loading, setLoading] = useState(true);
  const [stateErrorDialog, setStateErrorDialog] = useState(ErrorDialogEmpty);

  const [events, setEvents] = useState(null);
  const [pastEvents, setPastEvents] = useState(null);
  const [upEvents, setUpEvents] = useState(null);

  const [eventDialog, setEventDialog] = useState(false);
  const [deleteEventDialog, setDeleteEventDialog] = useState(false);
  //const [deleteEventsDialog, setDeleteEventsDialog] = useState(false);
  const [enabledNewEvent, setEnabledNewEvent] = useState(false);
  const [event, setEvent] = useState(emptyEvent);
  const [selectedProducts, setSelectedEvents] = useState(null);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [clubs, setClubs] = useState(null);
  const [clubId, setClubId] = useState(null);
  const [country, setCountry] = useState(null);
  const [typeEvents, setTypeEvents] = useState(0);
  const [isAdmin, setIsAdmin] = useState(false);
  const toast = useRef(null);
  const dt = useRef(null);
  const history = useHistory();

  const PUBLISHED_EVENT = 402;

  // Se ejecuta luego del render
  useEffect(() => {
    try {
      const load = async () => {
        setLoading(true);

        const clubService = new ClubService();
        const resClubs = await clubService.getClubs();

        setClubs(resClubs);

        const countryService = new CountryService();
        const resCountry = await countryService.getCountries();
        setCountry(resCountry);

        let clubId = 0;
        let tokenAuthService = new TokenAuthService();
        let isAdmin = tokenAuthService.isAdmin();
        let isPlanner = tokenAuthService.isPlanner();

        setIsAdmin(isAdmin);

        if (!isAdmin) {
          let _club = await tokenAuthService.getClub();
          clubId = _club.Id;
        }

        setEnabledNewEvent(isAdmin || isPlanner);

        const eventService = new EventService();
        const resEvents = await eventService.getByClub(clubId);
        setClubId(clubId);

        for (let i = 0; i < resEvents.length; i++) {
          var start = new Date(resEvents[i].Start);
          var finish = new Date(resEvents[i].Finish);

          let startDate = new Date(
            start.getUTCFullYear(),
            start.getUTCMonth(),
            start.getUTCDate()
          );
          let finishDate = new Date(
            finish.getUTCFullYear(),
            finish.getUTCMonth(),
            finish.getUTCDate()
          );

          resEvents[i].Start = startDate;
          resEvents[i].Finish = finishDate;
        }

        //console.log(resEvents);
        setPastEvents(resEvents.filter((el) => el.Schedules === "past"));
        let inicialEvents = resEvents.filter(
          (el) =>
            el.Schedules === "up" ||
            (el.Schedules === "curr" && parseInt(el.State) !== PUBLISHED_EVENT)
        );

        // let currentEvents = resEvents.filter((el) => el.Schedules === "curr");
        setUpEvents(inicialEvents);
        setEvents(inicialEvents);

        setLoading(false);
      };
      setTimeout(load, 1000);
    } catch (error) {
      setStateErrorDialog(ErrorDialogNoAuthorizedSet(true, error));
    }
  }, []);

  if (loading)
    return (
      <div className="loading-container">
        <h3>Loading...</h3>
      </div>
    );

  const deleteEvent = () => {
    hideDeleteEventDialog();
    try {
      let _events = [];
      if (typeEvents == 0) {
        _events = upEvents.filter((val) => val.EventId !== event.EventId);
        setUpEvents(_events);
      } else {
        _events = pastEvents.filter((val) => val.EventId !== event.EventId);
        setPastEvents(_events);
      }

      let eventService = new EventService();
      eventService
        .deleteEvent(event.EventId)
        .then((data) => {
          setEvents(_events);

          setDeleteEventDialog(false);
          setEvent(emptyEvent);
          toast.current.show({
            severity: "success",
            summary: "Successful",
            detail: "Event Deleted",
            life: 3000,
          });
        })
        .catch((res) => {
          setStateErrorDialog(ErrorDialogSetResponse(res));
        });
    } catch (error) {
      setStateErrorDialog(ErrorDialogSet(true, ""));
    }
  };

  const hideDialog = (_event) => {
    if (_event != null) {
      //supongo que nunca cargo eventos pasados, esta valido igual!!
      let couId = onErrorFind(
        clubs.filter((e) => e.cluId == _event.cluId),
        "couId"
      );
      let newEvent = {
        EventId: _event.eveId,
        Name: _event.eveName,
        Start: _event.eveStart,
        Finish: _event.eveFinish,
        ClubId: _event.cluId,
        ClubName: onErrorFind(
          clubs.filter((e) => e.cluId == _event.cluId),
          "cluName"
        ),
        Localization: onErrorFind(
          clubs.filter((e) => e.cluId == _event.cluId),
          "cluAddress"
        ),
        Country: onErrorFind(
          country.filter((e) => e.couId == couId),
          "couName"
        ),
        Invited: 0,
        Authorized: 0,
        Schedules: "up",
        CircuitName: _event.eveCircuitName,
      };
      upEvents.push(newEvent);
      if (typeEvents == 0) {
        setEvents(upEvents);
      }
      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "Event Created",
        life: 3000,
      });
    }
    setEventDialog(false);
    setEvent(emptyEvent);
  };

  const openNew = () => {
    setEvent(emptyEvent);
    setEventDialog(true);
  };

  const hideDeleteEventDialog = () => {
    setDeleteEventDialog(false);
  };

  // const hideDeleteEventsDialog = () => {
  //     setDeleteEventsDialog(false);
  // };

  const editEvent = (event) => {
    history.push("/event/" + event.EventId);
  };

  const confirmDeleteEvent = (event) => {
    setEvent(event);
    setDeleteEventDialog(true);
  };

  const findIndexById = (id) => {
    let index = -1;
    for (let i = 0; i < events.length; i++) {
      if (events[i].EventId === id) {
        index = i;
        break;
      }
    }

    return index;
  };

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  // #region Templatedatatable
  const onErrorFind = (e, name) => {
    if (e) return e[0][`${name}`];
    else return "[error]";
  };

  const nameBodyTemplate = (rowData) => {
    return <span className="p-column-title">{rowData.Name}</span>;
  };
  const clubBodyTemplate = (rowData) => {
    return <span className="p-column-title">{rowData.ClubName}</span>;
  };

  const startFinishBodyTemplate = (rowData) => {
    return (
      <span className="p-column-title">
        {Utils.dateDDMMYY(rowData.Start) +
          " | " +
          Utils.dateDDMMYY(rowData.Finish)}
      </span>
    );
  };

  const localizationBodyTemplate = (rowData) => {
    return <span className="p-column-title">{rowData.Localization}</span>;
  };

  const countryBodyTemplate = (rowData) => {
    return <span className="p-column-title">{rowData.Country}</span>;
  };

  const invitedBodyTemplate = (rowData) => {
    return <span className="p-column-title">{rowData.Invited}</span>;
  };

  const authorizedBodyTemplate = (rowData) => {
    return <span className="p-column-title">{rowData.Authorized}</span>;
  };
  const circuitNameBodyTemplate = (rowData) => {
    return <span className="p-column-title">{rowData.CircuitName}</span>;
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-angle-right"
          className="p-mr-1"
          onClick={() => editEvent(rowData)}
        />
        {/*  <Button icon="pi pi-trash" className="btn-alert" onClick={() => confirmDeleteEvent(rowData)} /> */}
      </div>
    );
  };

  const stateBodyTemplate = (rowData) => {
    let published = "No";
    if (parseInt(rowData.State) === PUBLISHED_EVENT) {
      published = "Yes";
    }
    return <span className="p-column-title">{published}</span>;
  };
  // #endregion

  const header = (
    <div className="table-header">
      <h2 className="p-m-0 fs-x-normal">
        {typeEvents == 0 ? "Upcoming" : "Past"} events
      </h2>
      <span className="p-input-icon-right">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
        />
      </span>
    </div>
  );

  const deleteEventDialogFooter = (
    <>
      <Button
        label="Cancel"
        className="btn-seconadary"
        onClick={hideDeleteEventDialog}
      />
      <Button label="Delete" className="btn-primary" onClick={deleteEvent} />
    </>
  );

  // const formatDate = (d) => {
  //     if (d) d = new Date(d);
  //     return d;
  // };

  const setActiveIndex = (i) => {
    if (i == 0) {
      setTypeEvents(0);
      setEvents(upEvents);
    } else {
      setEvents(pastEvents);
      setTypeEvents(1);
    }

    //console.log(i);
  };

  //Header Pages Templates Parts
  const leftColumnTemplate = () => {
    return (
      <div className="p-m-0 p-col-6">
        <h1 className="p-m-0 fs-big">Events</h1>
      </div>
    );
  };

  const rightColumnTemplate = () => {
    return (
      <div className="p-m-0 p-col-6 p-d-flex p-jc-end">
        {enabledNewEvent && (
          <Button
            label="New event"
            icon="pi pi-plus"
            className="btn-primary "
            onClick={openNew}
          />
        )}
      </div>
    );
  };

  return (
    <div className="p-grid crud-demo">
      <div className="p-col-12">
        <div className="inner-container">
          <Toast ref={toast} />

          <HeaderPageDetail
            headerStyles="header-events"
            leftColumn={leftColumnTemplate()}
            rightColumn={rightColumnTemplate()}
          />

          <div className="container-tabs-events">
            <TabView
              activeIndex={typeEvents}
              onTabChange={(e) => setActiveIndex(e.index)}
            >
              <TabPanel
                header="Current events"
                headerClassName={typeEvents === 0 ? "p-highlight" : ""}
              />
              <TabPanel
                header="Past events"
                headerClassName={typeEvents === 1 ? "p-highlight" : ""}
              />
            </TabView>

            {/* Live Now Panel */}
            <LiveNowEvent></LiveNowEvent>

            <div className="card">
              {/* Currents Events Table */}
              <DataTable
                ref={dt}
                value={events}
                selection={selectedProducts}
                onSelectionChange={(e) => setSelectedEvents(e.value)}
                dataKey="EventId"
                paginator
                rows={10}
                rowsPerPageOptions={[5, 10, 25]}
                className="datatable-responsive table-layout-auto"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} events"
                globalFilter={globalFilter}
                emptyMessage="No events found."
                header={header}
              >
                <Column
                  field="Name"
                  header="Name"
                  sortable
                  body={nameBodyTemplate}
                />
                {isAdmin && (
                  <Column
                    field="ClubName"
                    header="Institution"
                    body={clubBodyTemplate}
                    sortable
                  />
                )}
                <Column
                  field="Start"
                  header="Start and Finish Date"
                  body={startFinishBodyTemplate}
                  headerClassName="txt-center"
                  bodyClassName="txt-center"
                  sortable
                />
                <Column
                  field="Localization"
                  header="Localization"
                  body={localizationBodyTemplate}
                  sortable
                />
                <Column
                  field="Country"
                  header="Country"
                  body={countryBodyTemplate}
                  headerClassName="txt-center"
                  bodyClassName="txt-center"
                  sortable
                />
                <Column
                  field="Invited"
                  header="Competitors"
                  headerClassName="txt-center"
                  bodyClassName="txt-right"
                  body={invitedBodyTemplate}
                  sortable
                />
                <Column
                  field="Authorized"
                  header="Authorized"
                  body={authorizedBodyTemplate}
                  headerClassName="txt-center"
                  bodyClassName="txt-right"
                  sortable
                />
                <Column
                  field="State"
                  header="Published"
                  body={stateBodyTemplate}
                  headerClassName="txt-center"
                  bodyClassName="txt-right"
                  sortable
                />
                <Column
                  field="CircuitName"
                  header="CircuitName"
                  body={circuitNameBodyTemplate}
                  headerClassName="txt-center"
                  bodyClassName="txt-right"
                  sortable
                />
                <Column body={actionBodyTemplate} className="is-last" />
              </DataTable>
            </div>

            {eventDialog && (
              <EventDialog
                visibleDialog={eventDialog}
                event={event}
                clubId={clubId}
                onClose={hideDialog}
              ></EventDialog>
            )}

            <Dialog
              visible={deleteEventDialog}
              className="p-dialog-small"
              header="Delete event"
              modal
              footer={deleteEventDialogFooter}
              onHide={hideDeleteEventDialog}
            >
              <div className="confirmation-content">
                {event && (
                  <span>
                    Are you sure you want to delete <b>{event.name}</b>? <br />
                    They will no longer be able to access the platform
                  </span>
                )}
              </div>
            </Dialog>

            <ErrorDialog stateDialog={stateErrorDialog}></ErrorDialog>
          </div>
        </div>
      </div>
    </div>
  );
};
