import React, { createRef } from "react";
import { KEY_ESCAPE } from "keycode-js";
import { Button } from "primereact/button";
import { TracksService } from "../service/TracksService";
import { EventService } from "../service/EventService";
import { Draws } from "./Draws";
import { track_parts } from "../utilities/const";
import { GeometricTrack } from "./Geometric";

const POLYLINE = "polyline";
const POLYGON = "polygon";
const DEFAULT_ZOOM = parseInt(process.env.REACT_APP_DEFAULT_ZOOM);
const DEFAULT_MAP_POSITION = {
  lat: parseFloat(process.env.REACT_APP_DEFAULT_LAT),
  lng: parseFloat(process.env.REACT_APP_DEFAULT_LNG),
};

export class NewPolyline {
  poiRef;
  setPoiTags;

  eventId;
  mapService;
  polylineTags;
  setPolylineTags;
  trackRef;
  setTrackRef;
  setInfoWindowsTags;
  setCenterPositions;
  setZoom;

  setPoiValues;
  setNewPolylineModal;
  setDetailsNewPolyline;
  detailsNewPolyline;
  setDrawingMode;
  back;
  setShape;
  shape;
  setErroMessage;
  google;
  showToast;
  trackCoords;
  setTrackCoords;
  shapesNewTrack;
  setShapesNewTrack;
  setVisibleConfirmMessageBox;

  constructor(
    poiRef,
    setPoiTags,

    eventId,
    mapService,
    polylineTags,
    setPolylineTags,
    trackRef,
    setTrackRef,
    setCenterPositions,
    setZoom,

    setPoiValues,
    setNewPolylineModal,
    setDetailsNewPolyline,
    detailsNewPolyline,
    setDrawingMode,
    setShape,
    shape,
    setErroMessage,
    stepDrawingTrack,
    setStepDrawingTrack,
    showToast,
    trackCoords,
    setTrackCoords,
    back,
    shapesNewTrack,
    setShapesNewTrack,
    setTrackStatusText,
    setVisibleConfirmMessageBox
  ) {
    this.eventId = eventId;
    this.mapService = mapService;
    this.polylineTags = polylineTags;
    this.setPolylineTags = setPolylineTags;
    this.trackRef = trackRef;
    this.setTrackRef = setTrackRef;
    this.setCenterPositions = setCenterPositions;
    this.setZoom = setZoom;
    this.poiRef = poiRef;
    this.setPoiTags = setPoiTags;
    this.setPoiValues = setPoiValues;
    this.setNewPolylineModal = setNewPolylineModal;
    this.setDetailsNewPolyline = setDetailsNewPolyline;
    this.detailsNewPolyline = detailsNewPolyline;
    this.setDrawingMode = setDrawingMode;
    this.back = back;
    this.setShape = setShape;
    this.shape = shape;
    this.setErroMessage = setErroMessage;
    this.stepDrawingTrack = stepDrawingTrack;
    this.setStepDrawingTrack = setStepDrawingTrack;
    this.showToast = showToast;

    this.trackCoords = trackCoords;
    this.setTrackCoords = setTrackCoords;
    this.shapesNewTrack = shapesNewTrack;
    this.setShapesNewTrack = setShapesNewTrack;
    this.setTrackStatusText = setTrackStatusText;
    this.setVisibleConfirmMessageBox = setVisibleConfirmMessageBox;
  }

  /* ------------- funciones auxiliares ----------------*/
  setGoogle(google) {
    this.google = google;
  }

  mapPointToCoord(coordinates, type) {
    return coordinates.map((item) => {
      let point = item.split(",");
      return {
        lat: parseFloat(point[1]),
        lng: parseFloat(point[0]),
        type: type,
      };
    });
  }

  mapCoordinatesToPosition(coordinates) {
    let positions = coordinates.map((position, i) => {
      return new window.google.maps.LatLng(
        parseFloat(position.lat),
        parseFloat(position.lng)
      );
    });
    return positions;
  }

  mapPath(paths) {
    return paths.map((latLng) => {
      return { lat: latLng.lat(), lng: latLng.lng() };
    });
  }

  setMapLocation(data) {
    let zoom = data.mapPosition.zoom || DEFAULT_ZOOM;
    let mapPosition = DEFAULT_MAP_POSITION;
    if (data.mapPosition.lat) {
      mapPosition = {
        lat: parseFloat(data.mapPosition.lat),
        lng: parseFloat(data.mapPosition.long),
      };
    }
    this.setCenterPositions(mapPosition);
    this.setZoom(zoom);
  }

  /* ------------- funciones get ----------------*/
  async setTrack(trackId) {
    try {
      this.setErroMessage("");
      this.setDrawingMode(null);

      let trackService = new TracksService();
      let res = await trackService.getTrackPointsById(trackId);

      if (!res) return;

      let data = {
        mapPosition: res.mapPosition,
      };
      this.setMapLocation(data);

      let pointsMetaStart =
        res.shapes.PointsMetaStart.length > 0
          ? res.shapes.PointsMetaStart[0]
          : null;
      let pointsMetaFinish =
        res.shapes.PointsMetaFinish.length > 0
          ? res.shapes.PointsMetaFinish[0]
          : null;

      let refData = [
        { coords: res.shapes.PointsTrack, ref: createRef() },
        { coords: pointsMetaStart, ref: createRef() },
        { coords: pointsMetaFinish, ref: createRef() },
        { coords: res.shapes.PointsBoxes, ref: createRef() },
        // { pois: pois },
      ];
      this.drawTrack2(refData, false);
      this.setTrackRef(refData);

      return data;
    } catch (error) {
      this.showToast(``, "An error has occurred trying to get Track", "error");
      console.log(error);
    }
  }

  async getImportTrack(file, eventId) {
    try {
      this.setErroMessage("");
      this.setDrawingMode(null);

      const eventService = new EventService();
      let res = await eventService.updloadPolyline(file, eventId);

      let mapPosition = DEFAULT_MAP_POSITION;
      let firstpositionPoint = null;

      if (res.data.pointsMetaStart && res.data.pointsMetaStart[0])
        firstpositionPoint = res.data.pointsMetaStart[0].split(",");
      else if (res.data.pointsTrack && res.data.pointsTrack[0])
        firstpositionPoint = res.data.pointsTrack[0].split(",");

      if (firstpositionPoint) {
        mapPosition = {
          lat: parseFloat(firstpositionPoint[1]),
          lng: parseFloat(firstpositionPoint[0]),
        };
      }

      let coordsStart = res.data.pointsMetaStart
        ? this.mapPointToCoord(res.data.pointsMetaStart, 1)[0]
        : {};
      let coordsFinish = res.data.pointsMetaFinish
        ? this.mapPointToCoord(res.data.pointsMetaFinish, 2)[0]
        : {};

      let zoom = DEFAULT_ZOOM;

      let data = {
        mapPosition: mapPosition,
        zoom: zoom,
        refData: [
          {
            coords: this.mapPointToCoord(res.data.pointsTrack, 0),
            ref: createRef(),
          },
          {
            coords: coordsStart,
            ref: createRef(),
          },
          {
            coords: coordsFinish,
            ref: createRef(),
          },
          {
            coords: this.mapPointToCoord(res.data.pointsBoxes, 3),
            ref: createRef(),
          },
        ],
      };

      return data;
    } catch (error) {
      this.showToast(``, "An error has occurred trying to get Track", "error");
      console.log(error);
    }
  }

  async initializePois() {
    try {
      var response = await this.mapService.getPoisByEventId(this.eventId);
      var pois = [];
      response.data.pois.forEach((item, index) => {
        var data = {
          lat: parseFloat(item.Lat),
          lng: parseFloat(item.Long),
          index: index + 2,
          isOpen: false,
          input: React.createRef(),
          description: item.Description,
          id: item.Id,
          icon: item.Icon,
        };
        pois.push(data);
      });
      return pois;
    } catch (error) {
      console.log(error);
      this.showToast(
        ``,
        "An error has occurred trying to get the pois",
        "error"
      );
    }
  }

  comparePoints(uno, dos) {
    for (let i = 0; i < uno.length; i++) {
      if (uno[i].lat() != dos[i].lat || uno[i].lng() != dos[i].lng)
        return false;
    }
    return true;
  }

  async trackModified(trackId) {
    try {
      let NewTrack = {
        pointsTrack: [],
        pointsMetaStart: [],
        pointsMetaFinish: [],
        pointsBoxes: [],
      };

      if (
        this.trackRef[track_parts.POLYLINE] &&
        this.trackRef[track_parts.POLYLINE].ref.current
      ) {
        NewTrack.pointsTrack = this.trackRef[
          track_parts.POLYLINE
        ].ref.current.state.polyline
          .getPath()
          .getArray();
      }

      if (
        this.trackRef[track_parts.START] &&
        this.trackRef[track_parts.START].ref.current
      ) {
        let point =
          this.trackRef[track_parts.START].ref.current.marker.position;

        NewTrack.pointsMetaStart = [point];
      }

      if (
        this.trackRef[track_parts.FINISH] &&
        this.trackRef[track_parts.FINISH].ref.current
      ) {
        let point =
          this.trackRef[track_parts.FINISH].ref.current.marker.position;
        NewTrack.pointsMetaFinish = [point];
      }

      if (
        this.trackRef[track_parts.BOXES] &&
        this.trackRef[track_parts.BOXES].ref.current
      ) {
        NewTrack.pointsBoxes = this.trackRef[
          track_parts.BOXES
        ].ref.current.state.polygon
          .getPath()
          .getArray();
      }

      if (!trackId) {
        if (
          NewTrack.pointsTrack.length > 0 ||
          NewTrack.pointsMetaStart.length > 0 ||
          NewTrack.pointsMetaFinish.length > 0 ||
          NewTrack.pointsBoxes.length > 0
        )
          return true;
      }

      let trackService = new TracksService();
      let res = await trackService.getTrackPointsById(trackId);

      if (res) {
        let data = {
          mapPosition: res.mapPosition,
        };
        this.setMapLocation(data);

        let pointsMetaStart =
          res.shapes.PointsMetaStart.length > 0
            ? res.shapes.PointsMetaStart[0]
            : null;
        let pointsMetaFinish =
          res.shapes.PointsMetaFinish.length > 0
            ? res.shapes.PointsMetaFinish[0]
            : null;

        let OriginalTrack = {
          pointsTrack: res.shapes.PointsTrack,
          pointsMetaStart: pointsMetaStart === null ? [] : [pointsMetaStart],
          pointsMetaFinish: pointsMetaFinish === null ? [] : [pointsMetaFinish],
          pointsBoxes: res.shapes.PointsBoxes,
        };

        console.log(OriginalTrack);

        if (
          NewTrack.pointsTrack.length != OriginalTrack.pointsTrack.length ||
          NewTrack.pointsMetaStart.length !=
            OriginalTrack.pointsMetaStart.length ||
          NewTrack.pointsMetaFinish.length !=
            OriginalTrack.pointsMetaFinish.length ||
          NewTrack.pointsBoxes.length != OriginalTrack.pointsBoxes.length
        )
          return true;

        if (
          !this.comparePoints(
            NewTrack.pointsTrack,
            OriginalTrack.pointsTrack
          ) ||
          !this.comparePoints(
            NewTrack.pointsMetaStart,
            OriginalTrack.pointsMetaStart
          ) ||
          !this.comparePoints(
            NewTrack.pointsMetaFinish,
            OriginalTrack.pointsMetaFinish
          ) ||
          !this.comparePoints(NewTrack.pointsBoxes, OriginalTrack.pointsBoxes)
        )
          return true;
      }

      return false;
    } catch (error) {
      var message = error.response?.data?.message || "A error has occured";
      this.setErroMessage(message);
      this.showToast(``, message, "error");
    }
  }

  /* ------------- funciones put ----------------*/
  async updateDeatilsPolyline(trackId, trackName, mapPosition) {
    try {
      let track = {
        trackId: trackId,
        trackName: trackName,
        eventId: this.eventId,
        pointsTrack: [],
        pointsMetaStart: [],
        pointsMetaFinish: [],
        pointsBoxes: [],
        zoom: DEFAULT_ZOOM,
      };

      if (
        this.trackRef[track_parts.POLYLINE] &&
        this.trackRef[track_parts.POLYLINE].ref.current
      ) {
        track.pointsTrack = this.trackRef[
          track_parts.POLYLINE
        ].ref.current.state.polyline
          .getPath()
          .getArray();
      }
      let sizeTrackPoint = track.pointsTrack.length - 1;
      if (
        track.pointsTrack[0].lat() !==
          track.pointsTrack[sizeTrackPoint].lat() ||
        track.pointsTrack[0].lng() !== track.pointsTrack[sizeTrackPoint].lng()
      ) {
        track.pointsTrack.push(track.pointsTrack[0]);
      }

      if (
        this.trackRef[track_parts.START] &&
        this.trackRef[track_parts.START].ref.current
      ) {
        let point =
          this.trackRef[track_parts.START].ref.current.marker.position;

        track.pointsMetaStart = [point];
      }

      if (
        this.trackRef[track_parts.FINISH] &&
        this.trackRef[track_parts.FINISH].ref.current
      ) {
        let point =
          this.trackRef[track_parts.FINISH].ref.current.marker.position;
        track.pointsMetaFinish = [point];
      }

      if (
        this.trackRef[track_parts.BOXES] &&
        this.trackRef[track_parts.BOXES].ref.current
      ) {
        track.pointsBoxes = this.trackRef[
          track_parts.BOXES
        ].ref.current.state.polygon
          .getPath()
          .getArray();
      }

      this.setErroMessage("");

      const tracksService = new TracksService();
      await tracksService.createTrack(track, mapPosition);

      this.setNewPolylineModal(false);
      this.setShapesNewTrack([]);
      this.back();
      this.showToast(`The Track has been saved`, "", "success");
    } catch (error) {
      var message = error.response?.data?.message || "A error has occured";
      this.setErroMessage(message);
      this.setNewPolylineModal(true);
      this.showToast(``, message, "error");
    }
  }

  /* ------------- funciones dibujo ----------------*/

  drawTrack2(data, edit, editPart) {
    let _editPart = false;

    let _polylineTags = [];
    let draws = new Draws(this.setVisibleConfirmMessageBox, this.drawTrack);

    let index = track_parts.POLYLINE;
    _editPart = editPart !== undefined && editPart === index ? true : false;
    if (data[index] && data[index].coords) {
      _polylineTags.push(
        draws.drawPolyline2(
          data[index].coords,
          index,
          edit || _editPart,
          data[index].ref
        )
      );
    }

    let _listItems = [];
    if (this.poiRef.length > 0) _listItems = [...this.poiRef.current];
    index = track_parts.START;
    _listItems[0] = null;
    if (data[index] && data[index].coords) {
      const icon = this.getIcon(data[index].coords.type);
      let point = draws.drawPoint(
        data[index].coords,
        index,
        icon,
        data[index].ref,
        edit
      );
      _listItems[0] = point;
    }

    index = track_parts.FINISH;
    _listItems[1] = null;
    if (data[index] && data[index].coords) {
      const icon = this.getIcon(data[index].coords.type);
      let point = draws.drawPoint(
        data[index].coords,
        index,
        icon,
        data[index].ref,
        edit
      );
      _listItems[1] = point;
    }

    index = track_parts.BOXES;
    _editPart = editPart !== undefined && editPart === index ? true : false;
    if (data[index] && data[index].coords) {
      _polylineTags.push(
        draws.drawPolygon2(
          data[index].coords,
          index,
          edit || _editPart,
          data[index].ref
        )
      );
    }

    this.setPoiTags(_listItems);
    this.setPolylineTags(_polylineTags);
  }

  drawNewMeta(data, stepDrawingTrack, edit) {
    let draws = new Draws();
    let _listItems = [...this.poiRef.current];
    let index = stepDrawingTrack;
    if (data[index] && data[index].coords) {
      const icon = this.getIcon(data[index].coords.type);
      let point = draws.drawPoint(
        data[index].coords,
        index,
        icon,
        data[index].ref,
        edit
      );
      _listItems[index - 1] = point;
    }
    this.setPoiTags(_listItems);
  }

  drawTrack(edit) {
    this.drawTrack2(this.trackRef, edit);
  }

  deletePart(part) {
    let _trackRef = { ...this.trackRef };
    _trackRef[part] = null;
    this.setTrackRef(_trackRef);
    this.drawTrack2(_trackRef, true);
  }

  deletePoint(part, point) {
    let _trackRef = { ...this.trackRef };

    if (_trackRef[part].coords.length > 2)
      _trackRef[part].coords.splice(point.vertex, 1);

    this.setTrackRef(_trackRef);
    this.drawTrack2(_trackRef, true);
  }

  initializeNewPolylineTrack() {
    this.setStepDrawingTrack(track_parts.POLYLINE);

    let _trackRef = { ...this.trackRef };
    _trackRef[track_parts.POLYLINE] = null;
    this.drawTrack2(_trackRef, false);

    this.setErroMessage("");
    this.insert(POLYLINE);
    this.setTrackStatusText("New track: Draw track points");
  }
  initializeNewStart() {
    this.setStepDrawingTrack(track_parts.START);
    this.setTrackStatusText("New Start");
  }
  initializeNewFinish() {
    this.setStepDrawingTrack(track_parts.FINISH);
    this.setTrackStatusText("New Finish");
  }
  initializeNewBoxes() {
    this.setStepDrawingTrack(track_parts.BOXES);

    let _trackRef = { ...this.trackRef };
    _trackRef[track_parts.BOXES] = null;
    this.drawTrack2(_trackRef, false);

    this.setErroMessage("");
    this.insert(POLYLINE);

    this.setTrackStatusText("New Boxes");
  }

  drawingOverlayComplete(e, type) {
    let coords = e.overlay
      .getPath()
      .getArray()
      .map((latLng) => {
        return { lat: latLng.lat(), lng: latLng.lng() };
      });

    coords.push({ lat: coords[0].lat, lng: coords[0].lng });
    let newRef = { coords: coords, ref: createRef() };

    let _trackRef = [...this.trackRef];
    _trackRef[this.stepDrawingTrack] = newRef;

    e.overlay.setMap(null);

    this.drawTrack2(_trackRef, false, type);
    this.setTrackRef(_trackRef);

    this.setDrawingMode(null);
  }

  select() {
    this.setDrawingMode(null);
  }

  insert(type) {
    this.setDrawingMode(type);
  }

  handlerClickEvent(e) {}

  handleEscapeKeyDown(e) {
    if (e.keyCode === KEY_ESCAPE) {
      this.setDrawingMode(null);
    }
  }

  getIcon(_stepDrawingTrack) {
    let icon = null;
    if (_stepDrawingTrack === track_parts.START) {
      icon = {
        url: "pois/start.svg",
        fillColor: "green",
      };
    }

    if (_stepDrawingTrack === track_parts.FINISH)
      icon = { url: "pois/finish.svg" };

    return icon;
  }
}
