import React, { useState, useEffect, useRef } from "react";

import { Toast } from "primereact/toast";


import { ErrorDialog, ErrorDialogEmpty, ErrorDialogNoAuthorizedSet, ErrorDialogSet, ErrorDialogSetResponse } from "../../dialogs/ErrorDialog";
import { CircuitsService } from "../../service/CircuitsService";

//Components
import { DeleteDialog } from "./DeleteDialog"
import { CircuitDataTable } from "./CircuitDataTable";
import { EditDialog } from "./EditDialog";

const EMPTY_CIRCUIT = {
    cirId:null,
    cirName: "",
}

export const Circuits = () => {

    const [stateErrorDialog, setStateErrorDialog] = useState(ErrorDialogEmpty);
    const [loading, setLoading] = useState(true);

    const [circuit, setCircuit] = useState(EMPTY_CIRCUIT);
    const [circuits, setCircuits] = useState(null);

    const [editDialogVisible, setEditDialogVisible] = useState(false);
    const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);

    const toast = useRef(null);

    useEffect(() => {
        try {
            const load = async () => {
                setLoading(true);

                const circuits = new CircuitsService();
                const res = await circuits.getAll();

                setCircuit(EMPTY_CIRCUIT);
                setCircuits(res);

                setLoading(false);
            };
            load();
        } catch (error) {
            setStateErrorDialog(ErrorDialogNoAuthorizedSet(true, error));
            console.log(error);
        }
    }, []);
    if (loading)
        return (
            <div className="loading-container">
                <h3>Loading...</h3>
            </div>
        );


    const deleteItem =() => {
        const circuitsService = new CircuitsService();            
        return circuitsService.deleteCircuits(circuit.cirId);
    }


    const findIndexById = (id,items) => {
        let index = -1;
        for (let i = 0; i < items.length; i++) {
            if (items[i].cirId === id) {
                index = i;
                break;
            }
        }
        return index;
    };
    const changeItems = (item) => {
        let _circuits = [...circuits];
        let index = findIndexById(item.cirId, _circuits);
        if (index > -1) {
            _circuits[index] = item;
            toast.current.show({ severity: "success", summary: "Successful", detail: "Circuit Updated", life: 3000 });
        } else {
            _circuits.push(item);
            toast.current.show({ severity: "success", summary: "Successful", detail: "Circuit Created", life: 3000 });
        }
        setCircuits(_circuits);
    }
    const onDeleted = ()=>{
        let _items = circuits.filter((val) => val.cirId !== circuit.cirId);
        setCircuits(_items);
        setCircuit(EMPTY_CIRCUIT);
        toast.current.show({ severity: "success", summary: "Successful", detail: "Circuit Deleted", life: 3000 });
    }
    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card ">
                    <Toast ref={toast} />

                    <CircuitDataTable
                        items={ circuits }
                        emptyItem={ EMPTY_CIRCUIT }
                        openDialog={(row) => {setCircuit({ ...row }); setEditDialogVisible(true);}}
                        openDeleteDialog={(rowData) => {setCircuit(rowData); setDeleteDialogVisible(true);}}
                    ></CircuitDataTable>

                    <EditDialog 
                        visible={editDialogVisible} 
                        item={circuit}
                        onChange={changeItems}
                        hideDialog={() => setEditDialogVisible(false)}
                        setStateErrorDialog={setStateErrorDialog}                        
                    />

                    <DeleteDialog 
                    visible={deleteDialogVisible} 
                        item={{id: circuit.cirId, name: circuit.cirName}} 
                        header="Delete circuit" 

                        hideDialog={() => setDeleteDialogVisible(false)}
                        deleteItem={deleteItem}
                        onDeleted={onDeleted}
                        setStateErrorDialog={setStateErrorDialog}
                    />

                    <ErrorDialog stateDialog={stateErrorDialog}></ErrorDialog>
                </div>
            </div>
        </div>
    );
};
