import React, { useState, useEffect, useRef } from "react";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";

import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Utils } from "../utilities/Utils";

import { ClubService } from "../service/ClubService";

import { ClubDialog } from "../dialogs/ClubDialog";
import {
  ErrorDialog,
  ErrorDialogEmpty,
  ErrorDialogSet,
  ErrorDialogNoAuthorizedSet,
} from "../dialogs/ErrorDialog";

export const Clubs = () => {
  let emptyClub = {
    cluId: null,
    couId: null,
    cluName: "",
    cluAddress: "",
    cluContactName: "",
    cluContactPhone: "",
    cluMail: "",
    cluWeb: "",
    cluSocialNetwork: "",
    usersCount: 0,
    licLicensesGiven: 0,
    licLicenseFrom: null,
    licLicenseTo: null,
    eventsCount: 0,
  };

  const [stateErrorDialog, setStateErrorDialog] = useState(ErrorDialogEmpty);
  const [loading, setLoading] = useState(true);

  const [clubs, setClubs] = useState(null);
  const [club, setClub] = useState(emptyClub);
  const [clubDialog, setClubDialog] = useState(false);

  const [deleteClubDialog, setDeleteClubDialog] = useState(false);
  const [selectedClubs, setSelectedClubs] = useState(null);
  const [globalFilter, setGlobalFilter] = useState(null);
  // const [eventPlanners, setEventPlanners] = useState(null);

  const toast = useRef(null);
  const dt = useRef(null);
  const history = useHistory();
  const params = useParams();

  // Se ejecuta luego del render
  useEffect(() => {
    try {
      const load = async () => {
        setLoading(true);
        // const eventPlannersService = new EventPlannersService();
        // const resPlanners = await eventPlannersService.getEventPlannersIdDescription();
        // setEventPlanners(resPlanners);

        const clubService = new ClubService();
        const resClubs = await clubService.getClubs();

        setClubs(resClubs);
        setLoading(false);

        if (params.message)
          toast.current.show({
            severity: "success",
            summary: "Successful",
            detail: params.message,
            life: 3000,
          });
      };
      load();
    } catch (error) {
      console.log("Club Error");
      setLoading(false);
      setStateErrorDialog(ErrorDialogNoAuthorizedSet(true, error));
    }
  }, []);

  if (loading)
    return (
      <div className="loading-container">
        <h3>Loading...</h3>
      </div>
    );

  const openNew = () => {
    setClub(emptyClub);
    setClubDialog(true);
  };

  const hideDialog = (_club) => {
    if (_club != null) {
      let index = findIndexById(_club.cluId);
      if (index > -1) {
        clubs[index] = _club;
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Institution Updated",
          life: 3000,
        });
      } else {
        clubs.push(_club);
        _club.eventsCount = 0;
        _club.usersCount = 1;
        _club.licLicensesGiven = 0;
        setClubs(clubs);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Institution Created",
          life: 3000,
        });
      }
    }

    setClubDialog(false);
    setClub(emptyClub);
  };

  const hideDeleteClubDialog = () => {
    setDeleteClubDialog(false);
  };

  const editClub = (club) => {
    history.push("/club/" + club.cluId);
  };

  const confirmDeleteClub = (club) => {
    setClub(club);
    setDeleteClubDialog(true);
  };

  const deleteClub = () => {
    hideDeleteClubDialog();
    try {
      let _clubs = clubs.filter((val) => val.cluId !== club.cluId);

      let clubService = new ClubService();
      clubService
        .deleteClub(club.cluId)
        .then((data) => {
          setClubs(_clubs);
          setDeleteClubDialog(false);
          setClub(emptyClub);
          toast.current.show({
            severity: "success",
            summary: "Successful",
            detail: "Club Deleted",
            life: 3000,
          });
        })
        .catch((res) => {
          var message = "";
          if (res.response.data && res.response.data.statusCode == 418)
            message = res.response.data.message;

          setStateErrorDialog(ErrorDialogSet(true, message));
        });
    } catch (error) {
      setStateErrorDialog(ErrorDialogSet(true, ""));
      console.log(error.message);
    }
  };

  const findIndexById = (id) => {
    let index = -1;
    for (let i = 0; i < clubs.length; i++) {
      if (clubs[i].cluId === id) {
        index = i;
        break;
      }
    }

    return index;
  };

  // const leftToolbarTemplate = () => {
  //     return (
  //         <React.Fragment>
  //             {/*
  //             <Button label="Login" className="p-button-success p-mr-2" onClick={handleLoginClick}></Button>
  //             <Button label="Logout" className="p-button-success p-mr-2" onClick={handleLogoutClick}></Button> */}
  //             {/* <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedClubs || !selectedClubs.length} /> */}
  //         </React.Fragment>
  //     );
  // };

  // #region Templatedatatable
  const onErrorFind = (e) => {
    if (e) return e.Planner;
    else return "error";
  };

  const nameBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title txt-bold">{rowData.cluName}</span>
      </>
    );
  };

  const plannerBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">{rowData.Planners}</span>
        {/* <span className="p-column-title">{ onErrorFind(rowData) }</span> */}
      </>
    );
  };

  const mailBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">{rowData.cluMail}</span>
      </>
    );
  };

  const usersCountBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title txt-center">{rowData.usersCount}</span>
      </>
    );
  };

  const licensesGivenBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">{rowData.licLicensesGiven}</span>
      </>
    );
  };

  const licensePeriodBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">
          {Utils.dateDDMMYY(rowData.licLicenseFrom) +
            " to " +
            Utils.dateDDMMYY(rowData.licLicenseTo)}
        </span>
      </>
    );
  };

  const eventsInPeriodBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">{rowData.eventsCount}</span>
      </>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-angle-right"
          className="p-mr-1"
          onClick={() => editClub(rowData)}
        />
        {/* <Button icon="pi pi-trash" className="btn-alert" onClick={() => confirmDeleteClub(rowData)} /> */}
      </div>
    );
  };
  // #endregion

  const header = (
    <div className="table-header p-mb-5">
      <h1 className="p-m-0 fs-x-normal">Institutions</h1>
      <div>
        <span className="p-input-icon-right p-mr-4">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Search institution"
          />
        </span>
        <Button
          label="New Institution"
          icon="pi pi-plus"
          className="btn-primary"
          onClick={openNew}
        />
      </div>
    </div>
  );

  const deleteClubDialogFooter = (
    <>
      <Button
        label="Cancel"
        className="btn-secondary"
        onClick={hideDeleteClubDialog}
      />
      <Button label="Delete" className="btn-primary" onClick={deleteClub} />
    </>
  );

  return (
    <div className="p-grid crud-demo">
      <div className="p-col-12">
        <div className="card ">
          <Toast ref={toast} />

          <DataTable
            ref={dt}
            value={clubs}
            selection={selectedClubs}
            onSelectionChange={(e) => setSelectedClubs(e.value)}
            dataKey="cluId"
            paginator
            rows={10}
            rowsPerPageOptions={[5, 10, 25]}
            className="table-layout-auto "
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} clubs"
            globalFilter={globalFilter}
            emptyMessage="No Institutions found."
            header={header}
          >
            <Column
              field="cluName"
              header="NAME"
              sortable
              body={nameBodyTemplate}
            />
            <Column
              field="Planners"
              header="EVENT ORGANIZER"
              sortable
              body={plannerBodyTemplate}
            />
            <Column
              field="cluMail"
              header="ACOUNT MAIL"
              body={mailBodyTemplate}
              sortable
            />
            <Column
              field="usersCount"
              header="USERS"
              body={usersCountBodyTemplate}
              headerClassName="txt-center"
              bodyClassName="txt-right"
              sortable
            />
            <Column
              field="licLicensesGiven"
              header="ASSIGNED"
              body={licensesGivenBodyTemplate}
              headerClassName="txt-center"
              bodyClassName="txt-right"
              sortable
            />
            <Column
              field="licLicenseFrom"
              header="LICENSENCING PERIOD"
              body={licensePeriodBodyTemplate}
              headerClassName="txt-center"
              bodyClassName="txt-center"
              sortable
            />
            <Column
              field="eventsCount"
              header="EVENTS IN THIS PERIOD"
              body={eventsInPeriodBodyTemplate}
              headerClassName="txt-center"
              bodyClassName="txt-right"
              sortable
            />
            <Column body={actionBodyTemplate} className="is-last" />
          </DataTable>

          <ClubDialog
            visibleDialog={clubDialog}
            club={club}
            onClose={hideDialog}
          ></ClubDialog>

          <ErrorDialog stateDialog={stateErrorDialog}></ErrorDialog>

          <Dialog
            visible={deleteClubDialog}
            className="p-dialog-small"
            header="Delete institution"
            modal
            footer={deleteClubDialogFooter}
            onHide={hideDeleteClubDialog}
          >
            <div className="confirmation-content">
              {club && (
                <span>
                  Are you sure you want to delete <b>{club.name}</b>? <br />
                  They will no longer be able to access the platform
                </span>
              )}
            </div>
          </Dialog>
        </div>
      </div>
    </div>
  );
};
