import React from "react";
import { Marker, InfoWindow } from "@react-google-maps/api";
import { Button } from "primereact/button";
import { POI_VALUES, SVG } from "../consts/icons";
import { GoogleMapTool } from "./GoogleMapTool";

export class NewPoi {
  eventId;
  mapService;
  setPolylineTags;
  setInfoWindowsTags;
  setPoiTags;
  poiRef;
  setPoiValues;
  openPoiModal;
  poiValues;
  poiDetails;
  setInfoIndex;
  infoIndex;
  setPoiDetails;
  funBack;
  showToast;
  mapTools;
  editablePoints;

  constructor(
    eventId,
    mapService,
    setPolylineTags,
    setInfoWindowsTags,
    setPoiTags,
    poiRef,
    setPoiValues,
    openPoiModal,
    poiValues,
    poiDetails,
    setInfoIndex,
    infoIndex,
    setPoiDetails,
    showToast,
    back,
    editablePoints
  ) {
    this.eventId = eventId;
    this.mapService = mapService;
    this.setPolylineTags = setPolylineTags;
    this.setInfoWindowsTags = setInfoWindowsTags;
    this.setPoiTags = setPoiTags;
    this.poiRef = poiRef;
    this.setPoiValues = setPoiValues;
    this.openPoiModal = openPoiModal;
    this.poiValues = poiValues;
    this.poiDetails = poiDetails;
    this.setInfoIndex = setInfoIndex;
    this.infoIndex = infoIndex;
    this.setPoiDetails = setPoiDetails;
    this.funBack = back;
    this.showToast = showToast;
    this.mapTools = new GoogleMapTool();
    this.editablePoints = editablePoints;
  }

  poiFooter = (
    <div>
      <Button
        label="Cancel"
        className="btn-secondary "
        onClick={
          () => this.openPoiModal(false) //this.setPoiModalState(false)
        }
      />
      <Button
        label="Ok"
        className="btn-primary"
        onClick={(e) => this.updatePoiData(e)}
      />
    </div>
  );

  async initializePois(edit) {
    try {
      this.editablePoints.current = edit;
      var response = await this.mapService.getPoisByEventId(this.eventId);
      var pois = [];
      response.data.pois.forEach((item, index) => {
        var data = {
          lat: parseFloat(item.Lat),
          lng: parseFloat(item.Long),
          index,
          isOpen: false,
          input: React.createRef(),
          description: item.Description,
          id: item.Id,
          icon: item.Icon,
        };
        pois.push(data);
      });
      this.buildPois(pois, edit);
    } catch (error) {
      this.showToast(
        ``,
        "An error has occurred trying to get the pois",
        "error"
      );
      console.log(error);
    }
  }

  comparePoints(uno, dos) {
    for (let i = 0; i < uno.length; i++) {
      if (
        uno[i].lat != dos[i].lat ||
        uno[i].lng != dos[i].lng ||
        uno[i].description != dos[i].description ||
        uno[i].icon != dos[i].icon
      )
        return false;
    }
    return true;
  }

  async poisModified(eventId) {
    //Compara pois
    var response = await this.mapService.getPoisByEventId(eventId);
    var _pois = [];
    response.data.pois.forEach((item, index) => {
      var data = {
        lat: parseFloat(item.Lat),
        lng: parseFloat(item.Long),
        description: item.Description,
        id: item.Id,
        icon: item.Icon,
      };
      _pois.push(data);
    });

    console.log(_pois);

    if (this.poiValues.length != _pois.length) return true;

    if (!this.comparePoints(this.poiValues, _pois)) return true;

    return false;
  }

  buildPois(pois, parEditable) {
    const listItems = pois.map((poi, index) => {
      poi.index = index;

      let icon = {
        url: this.getSvgPath(poi.icon),
      };

      let editable = parEditable || poi.new !== undefined;

      return (
        <Marker
          icon={icon}
          position={{ lat: poi.lat, lng: poi.lng }}
          clickable={true}
          key={index + 10}
          editable={editable}
          draggable={editable}
          onClick={() => {
            poi.isOpen = !poi.isOpen;
            this.buildPois(pois, parEditable);
          }}
          onDblClick={() => {}}
          onDragEnd={(e) => {
            poi.lat = e.latLng.lat();
            poi.lng = e.latLng.lng();
            this.buildPois(pois, parEditable);
          }}
        >
          {poi.isOpen && (
            <InfoWindow
              key={index + 10}
              position={{ lat: poi.lat, lng: poi.lng }}
              onCloseClick={() => {
                poi.isOpen = !poi.isOpen;
                this.buildPois(pois, parEditable);
              }}
            >
              <div className="info-window-body">
                {
                  <>
                    <div className="p-d-flex  p-jc-between p-ai-center p-mb-2">
                      <p className="fs-normal txt-bold"> {poi.description} </p>

                      {editable && (
                        <div>
                          <Button
                            icon="pi pi-pencil"
                            className="info-window-edit-btn"
                            onClick={() => this.openModal(poi)}
                          />
                          <Button
                            icon="pi pi-trash"
                            className="info-window-edit-btn"
                            onClick={() => this.deletePoi(pois, poi)}
                          />
                        </div>
                      )}
                    </div>
                    {/* <p className="fs-small">
                      {parseFloat(poi.lat).toFixed(5)},{" "}
                      {parseFloat(poi.lng).toFixed(5)}
                    </p> */}
                  </>
                }
              </div>
            </InfoWindow>
          )}
        </Marker>
      );
    });

    let _points = [...this.poiRef.current];
    this.setPoiTags([_points[0], _points[1], ...listItems]);
    this.setPoiValues(pois);
  }

  updatePoiData(e) {
    //this.setPoiModalState(true);
    this.openPoiModal(true);
    var poi = this.poiValues.find(
      (item) => item.index == this.poiDetails.index
    );
    poi.lat = parseFloat(this.poiDetails.latInput.current.value);
    poi.lng = parseFloat(this.poiDetails.lngInput.current.value);
    poi.description = this.poiDetails.descriptionInput.current.value;
    poi.icon = this.poiDetails.icon;
    poi.new = true;
    this.buildPois(this.poiValues, this.editablePoints.current);
    this.openPoiModal(false);
  }

  handlerClickEvent(e, circuits, icon, type) {
    var index = this.infoIndex + 1;
    let retVal = null;
    if (true) {
      if (icon === null)
        icon = SVG.find((poi) => poi.Description == POI_VALUES.LOCATION).Path;

      let points = this.getTheCorrectPoints(circuits, e.latLng);

      let newPoi = {
        lat: points.lat,
        lng: points.lng,
        index,
        isOpen: false,
        input: React.createRef(),
        description: "New POI",
        icon: icon,
        type: type,
        new: true,
      };

      let indexType = this.poiValues.findIndex((p) => p.type === type);
      if (indexType > 0) this.poiValues.splice(indexType, 1);

      var data = [...this.poiValues, newPoi];
      this.editablePoints.current = false;
      this.buildPois(data, false);
      this.setInfoIndex(this.infoIndex + 1);
      retVal = newPoi;
    }
    return retVal;
  }

  getTheCorrectPoints(circuits, latLng) {
    let points = { lat: latLng.lat(), lng: latLng.lng() };
    circuits.forEach((circuit) => {
      let positions = circuit.Positions;
      let firstPoint = {
        lat: parseFloat(positions[0].lat),
        lng: parseFloat(positions[0].lng),
      };
      let lastPoint = {
        lat: parseFloat(positions[positions.length - 1].lat),
        lng: parseFloat(positions[positions.length - 1].lng),
      };

      if (this.mapTools.isSamePosition(points, firstPoint)) {
        points = {
          lat: parseFloat(positions[0].lat),
          lng: parseFloat(positions[0].lng),
        };
      } else if (this.mapTools.isSamePosition(points, lastPoint)) {
        points = lastPoint;
      }
    });

    return points;
  }

  openModal(poi) {
    this.setPoiDetails({
      index: poi.index,
      lat: poi.lat,
      lng: poi.lng,
      description: poi.description,
      icon: poi.icon,

      latInput: React.createRef(),
      lngInput: React.createRef(),
      descriptionInput: React.createRef(),
      //iconInput: React.createRef(),
    });
    //this.setPoiModalState(true);
    this.openPoiModal(true);
  }

  async deletePoi(pois, poi) {
    var positions = [...pois];
    if (poi.id) {
      try {
        //await this.mapService.deletePoiById(poi.id);
        //this.showToast(`DELETED POI`, "The poi has been deleted", "success");

        positions.splice(poi.index, 1);
        this.buildPois(positions, false);
      } catch (error) {
        this.showToast(
          ``,
          "An error has occurred trying to delete the poi",
          "error"
        );
      }
    } else {
      positions.splice(poi.index, 1);
      this.buildPois(positions, false);
    }
  }

  insert() {
    this.buildPois([...this.poiValues], false);
  }

  select() {
    this.buildPois([...this.poiValues], false);
  }

  exit() {}

  async saveChanges() {
    var pois = [];
    this.poiValues.forEach((item) => {
      pois.push({
        lat: item.lat,
        lng: item.lng,
        id: item.id || null,
        description: item.description,
        icon: item.icon,
      });
    });

    try {
      var response = await this.mapService.savePois({
        eventId: this.eventId,
        pois: pois,
      });
      return { status: true, message: "Pois were saved" };
    } catch (error) {
      console.log(error);
      return {
        status: false,
        message: "An error has occurred, please try again",
      };
    }
  }

  getSvgPath(icon) {
    try {
      return SVG.find((poi) => poi.Description == icon).Path;
    } catch (error) {
      return SVG.find((poi) => poi.Description == POI_VALUES.LOCATION).Path;
    }
  }
}
