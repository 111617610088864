export const FLAGS = {
  CUSTOM: 800,
  RED: 801,
  SAFETYCAR: 802,
  YELLOW: 806,
  DOUBLEYELLOW: 807,
  GREEN: 808,
  WHITE: 809,
  LOWADHESION: 810,
  PENALTY: 805,
  BLACK: 803,
  BLACKANDWHITE: 804,
};
