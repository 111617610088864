import React, { createRef } from "react";
import { Marker, InfoWindow } from "@react-google-maps/api";
import { POI_VALUES, SVG } from "../consts/icons";
import moment from "moment";
import { Utils } from "../utilities/Utils";
import { Draws } from "./Draws";
import { TracksService } from "../service/TracksService";

export class EditMap {
  eventId;
  mapService;
  setPolylineTags;
  setInfoWindowsTags;
  poiTags;
  setPoiTags;
  setPoiValues;
  funcClick;
  showToast;
  block_click;
  format;
  setTrackShape;

  polylineOptions = {
    strokeColor: "#d6ff22",
    strokeWeight: 4,
  };

  constructor(
    block_click,
    eventId,
    mapService,
    setPolylineTags,
    poiTags,
    setPoiTags,
    setPoiValues,
    showToast,
    funcClick = null,
    setTrackShape
  ) {
    this.eventId = eventId;
    this.mapService = mapService;
    this.setPolylineTags = setPolylineTags;
    //this.setInfoWindowsTags = setInfoWindowsTags;
    this.poiTags = poiTags;
    this.setPoiTags = setPoiTags;
    this.setPoiValues = setPoiValues;
    this.funcClick = funcClick;
    this.showToast = showToast;
    this.block_click = block_click;
    this.format = Utils.getFormDate();
    this.setTrackShape = setTrackShape;
  }

  showWindow(routes, route, state) {
    route.openInfoWindow = state;
    this.buildInfoWindows(routes);
  }

  async initializeTrack(map) {
    try {
      var response = await this.mapService.getRoutesByEventId(this.eventId);
      let draws = new Draws();
      let track = draws.buildTrack(
        {
          ...response.data.track,
          openInfoWindow: false,
          ref: createRef(),
          showRoute: true,
        },
        map
      );
      this.setPolylineTags(track);
      this.setTrackShape(track);

      response = {
        gmt: response.data.gmt,
        routes: response.data.routes,
        mapPosition: response.data.mapPosition,
      };

      return response;
    } catch (error) {
      console.log(error);
      alert("error en initialize track");
      this.showToast(
        ``,
        "An error has occurred trying to get circuits",
        "error"
      );
      return [];
    }
  }

  // async initializePois() {
  //   try {
  //     var response = await this.mapService.getPoisByEventId(this.eventId);
  //     var pois = [];
  //     response.data.pois.forEach((item, index) => {
  //       var data = {
  //         lat: parseFloat(item.Lat),
  //         lng: parseFloat(item.Long),
  //         index: index + 2,
  //         isOpen: false,
  //         input: React.createRef(),
  //         description: item.Description,
  //         id: item.Id,
  //         icon: item.Icon,
  //       };
  //       pois.push(data);
  //     });
  //     this.buildPois(pois);
  //   } catch (error) {
  //     console.log(error);
  //     this.showToast(
  //       ``,
  //       "An error has occurred trying to get the pois",
  //       "error"
  //     );
  //   }
  // }

  buildInfoWindows(routes) {
    const listItems = routes.map((route, index) => {
      var position = {
        lat: parseFloat(route.Positions[0].lat),
        lng: parseFloat(route.Positions[0].lng),
      };

      var fecha = "";

      if (route.Stages.length > 0) {
        if (route.Stages[0].StartDate) {
          var date = new Date(route.Stages[0].StartDate);
          var utcDate = new Date(
            date.getUTCFullYear(),
            date.getUTCMonth(),
            date.getUTCDate()
          );
          fecha = moment(utcDate).format(this.format);
        }
      }

      if (!route.Kms) {
        var positions = route.Positions.map((pos, i) => {
          return new window.google.maps.LatLng(
            parseFloat(pos.lat),
            parseFloat(pos.lng)
          );
        });

        const poly = new window.google.maps.Polyline({
          path: positions,
        });
        var lengthInKm =
          window.google.maps.geometry.spherical.computeLength(poly.getPath()) /
          1000;
        route.Kms = Math.round(lengthInKm * 100) / 100;
      }
      return (
        route.openInfoWindow && (
          <InfoWindow
            key={index}
            position={position}
            onCloseClick={() => {
              this.showWindow(routes, route, false);
            }}
          >
            {
              <div className="info-window-body">
                <div className="p-d-flex">
                  <div className="p-d-flex fs-small p-mr-2">
                    SS {this.stagesList(route.Stages)}
                  </div>
                </div>
                <div className="p-d-flex  p-jc-between p-ai-center p-mb-2">
                  <p className="fs-normal txt-bold"> {route.CircuitName} </p>
                </div>

                <div className="p-d-flex  p-ai-center p-mb-2">
                  <div className="p-d-flex fs-small p-mr-2">
                    {Math.round(route.Kms * 100) / 100} km (Approx)
                  </div>
                  <div className="p-d-flex fs-small">{fecha}</div>
                </div>
              </div>
            }
          </InfoWindow>
        )
      );
    });
    ///this.setInfoWindowsTags(listItems);
  }

  getSvgPath(icon) {
    try {
      return SVG.find((poi) => poi.Description == icon).Path;
    } catch (error) {
      return SVG.find((poi) => poi.Description == POI_VALUES.LOCATION).Path;
    }
  }

  // buildPois(pois) {
  //   const listItems = pois.map((poi, index) => {
  //     poi.index = index;

  //     const icon = {
  //       url: this.getSvgPath(poi.icon),
  //       //fillOpacity: 1,
  //       //strokeWeight: 0,
  //       //rotation: 180,
  //       //scale: 0.02,
  //     };

  //     return (
  //       <Marker
  //         icon={icon}
  //         position={{ lat: poi.lat, lng: poi.lng }}
  //         clickable={true}
  //         draggable={false}
  //         key={index}
  //         editable={false}
  //         onMouseOver={() => {
  //           if (!this.block_click) {
  //             poi.isOpen = true;
  //             this.buildPois(pois);
  //           }
  //         }}
  //         onMouseOut={() => {
  //           if (!this.block_click) {
  //             poi.isOpen = false;
  //             this.buildPois(pois);
  //           }
  //         }}
  //         onClick={() => {
  //           if (!this.block_click) {
  //             poi.isOpen = !poi.isOpen;
  //             this.buildPois(pois);
  //           }
  //         }}
  //         onDblClick={() => {}}
  //         onDragEnd={(e) => {
  //           if (!this.block_click) {
  //             poi.lat = e.latLng.lat();
  //             poi.lng = e.latLng.lng();
  //             this.buildPois(pois);
  //           }
  //         }}
  //       >
  //         {poi.isOpen && (
  //           <InfoWindow
  //             key={index}
  //             position={{ lat: poi.lat, lng: poi.lng }}
  //             onCloseClick={() => {
  //               if (!this.block_click) {
  //                 poi.isOpen = !poi.isOpen;
  //                 this.buildPois(pois);
  //               }
  //             }}
  //           >
  //             <div className="info-window-body">
  //               {<p className="fs-normal txt-bold">{poi.description} </p>}
  //             </div>
  //           </InfoWindow>
  //         )}
  //       </Marker>
  //     );
  //   });

  //   //let metasPoiTags = [null, null];

  //   // let _poiTags = [metasPoiTags, ...listItems];
  //   // this.setPoiTags(_poiTags);
  //   this.setPoiTags(listItems);
  //   this.setPoiValues(pois);
  // }
}
