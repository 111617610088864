import axios from "../utilities/api-axios";

const apiUrl = process.env.REACT_APP_API_URL;

export class TracksService {
  getById(id) {
    return axios.get(`${apiUrl}/tracks/` + id).then((res) => res.data);
  }

  async getAll() {
    return axios.get(`${apiUrl}/tracks/`).then((res) => res.data);
  }

  updateTrack(track) {
    const updItem = {
      Name: track.catName,
    };
    return axios
      .put(`${apiUrl}/tracks/` + track.traId, updItem)
      .then((res) => res.data);
  }

  createTrack(track, mapPosition) {
    const newTrack = {
      Name: track.trackName,
      PointsTrack: track.pointsTrack,
      PointsMetaStart: track.pointsMetaStart,
      PointsMetaFinish: track.pointsMetaFinish,
      PointsBoxes: track.pointsBoxes,
      CircuitId: track.circuitId,
      Id: track.trackId,
      EventId: track.eventId,
    };

    if (mapPosition) {
      newTrack.Zoom = mapPosition.zoom;
      newTrack.Lat = mapPosition.lat;
      newTrack.Lng = mapPosition.lng;
    }

    return axios.post(`${apiUrl}/tracks/`, newTrack).then((res) => {
      return res.data;
    });
  }

  deleteTrack(id) {
    return axios
      .delete(`${apiUrl}/tracks/` + id, { _method: "DELETE" })
      .then((res) => {
        return res.data;
      });
  }

  async getTrackPointsById(trackId) {
    return axios
      .get(`${apiUrl}/tracks/getTrackPointsById/` + trackId)
      .then((res) => res.data);
  }

  async getTrackByEventId(eventId) {
    //let token = this.tokenService.getToken();
    //return axios.get(`${apiUrl}/circuitRoutes/${eventId}`, { headers: { authorization: `Bearer ${token}` } });
    return axios
      .get(`${apiUrl}/circuitRoutes/${eventId}`)
      .then((res) => res.data);
  }

  async SetMapByTrackId(trackId, mapPosition) {
    return await axios.put(
      `${apiUrl}/tracks/setCenter/` + trackId,
      mapPosition
    );
  }
}
