export const type_roles = {
  DRIVER: "Driver",
  ADMIN: "SUPERADMIN",
  VIEWER: "Event official",
  DIRECTOR: "Clerk of course",
  EVENT_PLANNER: "Event organizer",
};

export const Flag_Codes = {
  CUSTOM_FLAG: "800",
  RED_FLAG: "801",
};

export const InterestPoints_Icons = {
  hotel: "icon-hotel",
  fire: "icon-fire-ext",
  asterisk: "icon-asterisk",
  location: "icon-location-on",
  gasstatiom: "icon-gas-station",
  time: "icon-time",
  startFlag: "icon-start",
  finish: "icon-finish",
};

export const track_parts = {
  POLYLINE: 0,
  START: 1,
  FINISH: 2,
  BOXES: 3,
};

export const flag_types = {
  CUTOMMESSAGE: "800",
  REDFLAG: "801",
  SAFETYCAR: "802",
  BLACK: "803",
  BLACKANDWHITE: "804",
  PENALTY: "805",
  YELLOW: "806",
  DOUBLEYELLOW: "807",
  GREEN: "808",
  WHITE: "809",
  LOWADHESION: "810",
  CHECKERED: "811",
};

export const flag_names = {
  800: "Message",
  801: "Red",
  802: "S/C",
  803: "Black",
  804: "Warning",
  805: "Penalty",
  806: "Yellow",
  807: "D Yellow",
  808: "Green",
  809: "White",
  810: "Surface",
  811: "Checkered",
};

export const flag_destination = {
  ALLDRIVERS: 0,
  SELECTED_DRIVERS: 1,
  ALLDRIVERS_AND_ONE: 2,
  ONE_DRIVER: 3,
};
