import axios from "../utilities/api-axios";

const apiUrl = process.env.REACT_APP_API_URL;

export class CategoriesService {
  getById(id) {
    return axios
      .get(`${apiUrl}/driverCategories/` + id)
      .then((res) => res.data);
  }

  async getAll(cluId) {
    return axios
      .get(`${apiUrl}/driverCategories/` + cluId)
      .then((res) => res.data);
  }

  updateCategory(category) {
    const updItem = {
      Name: category.catName,
      ClubId: category.cluId,
    };
    return axios
      .put(`${apiUrl}/driverCategories/` + category.catId, updItem)
      .then((res) => res.data);
  }

  createCategory(category) {
    const newCategory = {
      Name: category.catName,
      ClubId: category.cluId,
    };
    return axios
      .post(`${apiUrl}/driverCategories/`, newCategory)
      .then((res) => {
        return res.data;
      });
  }

  deleteCategory(id) {
    return axios
      .delete(`${apiUrl}/driverCategories/` + id, { _method: "DELETE" })
      .then((res) => {
        return res.data;
      });
  }

  async getCategoriesByClub(cluId) {
    const res = await axios.get(
      `${apiUrl}/driverCategories/IdDescriptionByClub/` + cluId
    );
    return res.data;
  }

  async getCategoriesByEvent(eveId) {
    const res = await axios.get(
      `${apiUrl}/driverCategories/IdDescriptionByEvent/` + eveId
    );
    return res.data;
  }
}
