import { ALERT_TYPES } from "../consts/alertTypes";
import { FLAGS } from "../consts/flags";
import { GoogleMapTool } from "./GoogleMapTool";
import moment from "moment";

const SELECTED_ICON = "selectedIcon.svg";

const LOWADHESION = "circle-lowadhesion.svg";
const WHITE_CIRCLE = "circle-white.svg";
const GREEN_CIRCLE = "circle-green.svg";
const YELLOW_CIRCLE = "circle-yellow-flag.svg";
const DOUBLE_YELLOW_CIRCLE = "circle-double-yellow.svg";

const WARNING_CIRCLE = "circle-warning.svg";
const PENALTY_CIRCLE = "circle-penalty.svg";
const BLACK_CIRCLE = "circle-black.svg";

const SAFETYCAR_ICON_URL = "circle-sc.svg";
const RED_CIRCLE = "circle-red.svg";

const GRAY_CIRCLE = WHITE_CIRCLE;
const OFFLINE_CAR = "offline-circle.svg";
const STOPPED_CAR = "stopped-circle.svg";
const TOLERANCE_LEVEL = 20e-5;
const RED_CIRCLE_SOS = "circle-red-black.svg";

const WAIT_TIME = 12;
const INTERVAL_TIME_TO_SHOW_SOS = 6000;

const ALL_CATEGORIES = 999;
export class DriverPositionTracker {
  mapService;
  eveId;
  setDriverPositions;
  setPositionTags;
  selectedIcon;
  iconAnimation;
  selectedRoute;
  mapTools;
  positionTags;
  positionTagsRef;
  driverPositionsRef;
  circuitsRef;
  setInfoDriverWindowsTags;
  infoDriverWindowsTagsRef;

  polylineOptions = {
    strokeColor: "#d6ff22",
    strokeWeight: 4,
  };

  constructor(
    mapService,
    eveId,
    setDriverPositions,
    setPositionTags,
    positionTags,
    selectedIcon,
    iconAnimation,
    positionTagsRef,
    driverPositionsRef,
    circuitsRef,
    setInfoDriverWindowsTags,
    infoDriverWindowsTagsRef
  ) {
    this.mapService = mapService;
    this.eveId = eveId;
    this.setDriverPositions = setDriverPositions;
    this.setPositionTags = setPositionTags;
    this.selectedIcon = selectedIcon;
    this.iconAnimation = iconAnimation;
    this.mapTools = new GoogleMapTool();
    this.positionTags = positionTags;
    this.positionTagsRef = positionTagsRef;
    this.driverPositionsRef = driverPositionsRef;
    this.circuitsRef = circuitsRef;
    this.setInfoDriverWindowsTags = setInfoDriverWindowsTags;
    this.infoDriverWindowsTagsRef = infoDriverWindowsTagsRef;
  }

  setSelectedRoute(route) {
    this.selectedRoute = route;
  }

  async initializeDriverTrakerByCategories(map, selectedCategories) {
    try {
      var positions = [];
      var driverPositions =
        await this.mapService.getLastDriverPositionsByEventId(
          this.eveId,
          selectedCategories
        );
      driverPositions.forEach(async (item) => {
        let position = {
          lat: parseFloat(item.Lat),
          lng: parseFloat(item.Lng),
          speed: item.Speed,
        };
        let stoped = false;

        let oldPosition = null;
        let lastUpdate = null;
        if (item.LatPP) {
          oldPosition = {
            lat: parseFloat(item.LatPP),
            lng: parseFloat(item.LngPP),
            speed: 0,
          };
          lastUpdate = moment(new Date(item.lastUpdate)).local();

          if (
            this.mapTools.checkTimeHasPassed(
              lastUpdate.toISOString(),
              WAIT_TIME
            )
          ) {
            var isSamePosition = this.mapTools.isSamePosition(
              position,
              oldPosition
            );
            if (isSamePosition) {
              stoped = true;
            }
            // if (
            //   position.lat === oldPosition.lat &&
            //   position.lng === oldPosition.lng
            // )
            //   stoped = true;
          }
        }

        positions.push({
          invId: item.InvitationId,
          userId: item.UserId,
          categoryId: item.CategoryId,
          category: item.Category,

          name: item.Name,
          surname: item.Surname,

          carnumber: item.CarNumber,
          counter: item.Counter || 0,
          positionId: item.PositionId,
          position: {
            lat: parseFloat(item.Lat),
            lng: parseFloat(item.Lng),
            speed: item.Speed,
          },

          offline: false,
          lastAlertType: item.LastAlertType,
          lastFlagStatus: item.LastFlagStatus,
          //driverAlertIds: item.driverAlertIds,

          oldPosition: oldPosition,
          lastUpdate: lastUpdate,

          stoped: stoped,
          icon: null,
          animation: null,
        });
      });
      await this.buildPositionTags(
        positions,
        map,
        "initializeDriverTrakerByCategories"
      );
    } catch (error) {
      console.log(error);
      console.log("An error has occurred trying to get the driver position.");
    }
  }

  async setDriverTraker(
    newDriverPosition,
    driverPositions,
    map,
    selectedCategories
  ) {
    var positions = [...driverPositions];
    var oldDriver = positions.find(
      (x) =>
        x.userId == newDriverPosition.userId &&
        x.categoryId == newDriverPosition.categoryId
    );
    if (oldDriver) {
      var oldIndex = positions.indexOf(oldDriver);
      var position = {
        lat: parseFloat(newDriverPosition.position.lat),
        lng: parseFloat(newDriverPosition.position.lng),
        speed: newDriverPosition.position.speed,
      };

      var stoped = false;
      var lastUpdate = oldDriver.lastUpdate;
      var oldPosition = oldDriver.oldPosition;

      if (oldDriver.oldPosition != null) {
        if (this.mapTools.checkTimeHasPassed(oldDriver.lastUpdate, WAIT_TIME)) {
          var isSamePosition = this.mapTools.isSamePosition(
            position,
            oldDriver.oldPosition
          );
          if (!isSamePosition) {
            lastUpdate = new Date();
            oldPosition = position;
            stoped = false;
          } else {
            stoped = true;
          }
        }
      }

      var newDriver = {
        ...oldDriver,
        position: position,
        positionId: newDriverPosition.position.id,
        oldPosition: oldPosition || position,
        lastUpdate: lastUpdate || new Date(),
        stoped: stoped,
        lastAlertType: newDriverPosition.lastAlertType,
        lastFlagStatus: newDriverPosition.lastFlagStatus,
      };

      // positions.splice(oldIndex, 1);
      // positions.push(newDriver);

      positions[oldIndex] = newDriver;

      await this.buildPositionTags(positions, map, "setDriverTraker 1");
    } else {
      //Cuando arranca un auto y el mapa ya estaba cargado
      const lastPosition = await this.mapService.getLastPositionDriver(
        this.eveId,
        newDriverPosition.userId,
        [newDriverPosition.categoryId]
      );

      if (lastPosition && lastPosition[0]) {
        let item = lastPosition[0];
        positions.push({
          invId: item.InvitationId,
          userId: item.UserId,
          categoryId: item.CategoryId,
          category: item.Category,

          name: item.Name,
          surname: item.Surname,

          carnumber: item.CarNumber,
          counter: item.Counter || 0,
          positionId: item.PositionId,
          position: {
            lat: parseFloat(item.Lat),
            lng: parseFloat(item.Lng),
            speed: item.Speed,
          },

          offline: false,
          lastAlertType: item.LastAlertType,
          lastFlagStatus: item.LastFlagStatus,
          //driverAlertIds: item.driverAlertIds,

          oldPosition: null,
          lastUpdate: null,
          stoped: false,
          icon: null,
          animation: null,
        });
        await this.buildPositionTags(positions, map, "setDriverTraker 2");
      }
    }
  }

  async setDriverAlert(
    newDriverPosition,
    positions,
    selectedCircuit,
    driverAlertId,
    map
  ) {
    this.setSelectedRoute(selectedCircuit);
    var oldDriver = positions.find(
      (x) =>
        x.userId == newDriverPosition.driver.User.Id &&
        x.categoryId == newDriverPosition.categoryId
    );

    if (oldDriver) {
      var oldIndex = positions.indexOf(oldDriver);

      var newDriver = {
        ...oldDriver,
        lastAlertType: driverAlertId,
        offline: false,
      };

      positions.splice(oldIndex, 1);

      positions.push(newDriver);
      await this.buildPositionTags(positions, map, "setDriverAlert");

      //Iniciar timer que despinta!!
      // setTimeout(async () => {
      //   newDriver.LastAlertType = null;
      //   //positions.push(newDriver);
      //   await this.buildPositionTags(positions, map, "setDriverAlert");
      // }, INTERVAL_TIME_TO_SHOW_SOS);
    }
  }

  async setOfflineColor(map, offlineDrivers, driverPositions) {
    driverPositions = driverPositions.map((p) => {
      let offlineValue = offlineDrivers.find(
        (x) => x.userId == p.userId && x.categoryId == p.categoryId
      );
      // let lastAlertType = null;
      // if (offlineValue) lastAlertType = offlineValue.lastAlertType;

      return {
        ...p,
        offline: offlineValue ? true : false,
        // lastAlertType: lastAlertType,
      };
    });
    await this.buildPositionTags(driverPositions, map, "setOfflineColor");
  }

  async showRedCircleOnDrivers(
    driverPositions,
    selectedCircuit,
    selectedDrivers,
    messageTypeId
  ) {
    driverPositions.forEach(async (item) => {
      if (messageTypeId == FLAGS.BLACKANDWHITE) {
        let exists = selectedDrivers.find((invId) => invId == item.invId);
        if (exists) item.lastFlagStatus = messageTypeId;
      } else {
        item.lastFlagStatus = messageTypeId;
      }
      item.animation = null;
      item.icon = null;
    });
    await this.buildPositionTags(
      driverPositions,
      null,
      "showRedCircleOnDrivers"
    );
    //}
  }

  async updateCounter(counter, infoDriverWindowsTagsRef, driverPositions) {
    const driverWindow = infoDriverWindowsTagsRef.current.find(
      (x) =>
        x.user.userId == counter.userId &&
        x.user.categoryId == counter.categoryId
    );

    const driverPosition = driverPositions.find(
      (x) => x.userId == counter.userId && x.categoryId == counter.categoryId
    );

    if (driverWindow) {
      driverWindow.counter = counter.counter;
      driverWindow.speed = driverPosition.position?.speed;
      driverWindow.user.counter = counter.counter;
      driverWindow.category = counter.category;
      driverWindow.categoryId = counter.categoryId;

      this.updateInfoTags(
        driverWindow,
        parseFloat(driverPosition.position?.lat).toFixed(5),
        parseFloat(driverPosition.position?.lng).toFixed(5)
      );
    }
  }

  updateInfoTags(driverWindow, lat, lng) {
    let counter =
      driverWindow.counter === 0 || driverWindow.counter
        ? driverWindow.counter
        : "Not informed";
    let content = `<div className="info-window-body" style="color:black">

      <div>${driverWindow.user.pilot || "Not informed"}</div>
      <div>Copilot: ${driverWindow.user.copilot || "Not informed"}</div>
      <div>Category: ${driverWindow.user.category}</div>
      <div>Lap: ${counter}</div>
      <div>Speed: ${driverWindow.speed}</div>
      </div>`;

    driverWindow.tag.setContent(content);
  }

  async removeDrivesFromMap(map, positionTags) {
    positionTags.forEach((tag, index) => {
      tag.marker.setMap(null);
    });

    this.setPositionTags([]);
    await this.buildPositionTags([], map, "removeDrivesFromMap");
  }

  async clearSelectedDrivers(driverPositions, selectedCircuit) {
    this.setSelectedRoute(selectedCircuit);
    driverPositions.forEach(async (driver) => {
      driver.animation = null;
      driver.icon = null;
    });
    await this.buildPositionTags(driverPositions, null, "clearSelectedDrivers");
  }

  clearDriverTraker() {
    this.positionTagsRef.current.forEach((tag) => {
      tag.marker.setMap(null);
    });
    //this.positionTagsRef.current = [];
    this.setDriverPositions([]);
    this.setPositionTags([]);
  }

  setContentOfInfoDriverWindows(driver, infoDriverWindowsTagsRef) {
    const driverWindow = infoDriverWindowsTagsRef.current.find(
      (x) => x.user.userId == driver.userId
    );

    if (driverWindow) {
      let lng = parseFloat(driver.position.lng).toFixed(5);
      let lat = parseFloat(driver.position.lat).toFixed(5);
      driverWindow.counter = driverWindow.user?.counter;
      driverWindow.categoryId = driver.categoryId;
      driverWindow.category = driver.category;
      driverWindow.speed = driver?.position?.speed
        ? parseInt(driver?.position?.speed)
        : 0;
      this.updateInfoTags(driverWindow, lat, lng);
    }
  }

  async toggleBounce(user, positions, onlySelected = false) {
    var itemList = positions.map((pos) => {
      if (pos.userId === user.userId) {
        if (pos.animation !== this.iconAnimation || onlySelected) {
          pos.animation = this.iconAnimation;
          pos.icon = { url: this.selectedIcon };
          pos.driverInfo = user.driverInfo;
          pos.isOpen = user.isOpen;
        } else {
          pos.animation = null;
          pos.icon = null;
        }
      }
      return { ...pos };
    });
    await this.buildPositionTags(itemList, null, "toggleBounce");
  }

  async getInfoDriver(userId, categoryId) {
    try {
      var driver = await this.mapService.getInfoDriver(
        this.eveId,
        userId,
        categoryId
      );
      return driver[0];
    } catch (error) {
      console.log(error);
      console.log("An error has occurred trying to get the driver position.");
    }
  }

  getIconUrl(pos, lockedColors = null) {
    let iconUrl = pos.icon;
    let color = "#FFFFFF";
    const shouldLockFlag = lockedColors[pos.userId]
    let flagStatus = shouldLockFlag ? lockedColors[pos.userId] : pos.lastFlagStatus;

    if (pos.lastAlertType === ALERT_TYPES.SOS) {
      iconUrl = RED_CIRCLE_SOS;
    } else {
      if (pos.offline) {
        iconUrl = OFFLINE_CAR;
      } else {
        if (!shouldLockFlag && pos.stoped && parseInt(flagStatus) != FLAGS.RED) {
          iconUrl = STOPPED_CAR;
        } else {
          if (flagStatus || shouldLockFlag) {
            switch (parseInt(flagStatus)) {
              case FLAGS.RED:
                iconUrl = RED_CIRCLE;
                break;
              case FLAGS.SAFETYCAR:
                iconUrl = SAFETYCAR_ICON_URL;
                break;
              case FLAGS.YELLOW:
                iconUrl = YELLOW_CIRCLE;
                color = "#000000";
                break;
              case FLAGS.DOUBLEYELLOW:
                iconUrl = DOUBLE_YELLOW_CIRCLE;
                break;
              case FLAGS.GREEN:
                iconUrl = GREEN_CIRCLE;
                color = "#000000";
                break;
              case FLAGS.WHITE:
                iconUrl = WHITE_CIRCLE;
                color = "#000000";
                break;
              case FLAGS.LOWADHESION:
                iconUrl = LOWADHESION;
                color = "#000000";
                break;
              case FLAGS.PENALTY:
                iconUrl = PENALTY_CIRCLE;
                break;
              case FLAGS.BLACK:
                iconUrl = BLACK_CIRCLE;
                break;
              case FLAGS.BLACKANDWHITE:
                iconUrl = WARNING_CIRCLE;
                break;
              default:
                break;
            }
          }
        }
      }
    }

    if (iconUrl == null) {
      iconUrl = GREEN_CIRCLE;
    }

    return { iconUrl, color };
  }

  async cleanFlags(driverPositions) {
    await this.mapService.cleanFlags(this.eveId);

    driverPositions.forEach(async (item) => {
      item.lastAlertType = null;
    });
    await this.buildPositionTags(driverPositions, null, true);
  }

  async buildPositionTags(positions, map, desde = null) {
    var newPositionTags = [];
    for await (let pos of positions) {
      var tag = this.positionTagsRef.current.find(
        (x) => x.id == pos.userId + "_" + pos.categoryId
      );

      if (pos.lastAlertType === undefined || pos.lastFlagStatus === undefined) {
        console.log("undefineds");
      }

      const data = this.getIconUrl(pos);
      let iconUrl = data.iconUrl;
      let color = data.color;

      var label = {
        text: `${pos.carnumber}`,
        color: color,
      };

      if (tag) {
        this.updateMarker(tag, pos, iconUrl, false, label);
      } else {
        var newMarker = await this.createMarker(pos, map, iconUrl, label);
        newPositionTags.push(newMarker);
      }
    }

    if (newPositionTags.length > 0) {
      const _postags = [...this.positionTagsRef.current, ...newPositionTags];
      //const tags = [...newPositionTags];
      this.setPositionTags(_postags);
    }

    this.setDriverPositions(positions);
  }

  updateMarker = (tag, pos, iconUrl, notPosition, label) => {
    if (!notPosition) tag.marker.setPosition(pos.position);

    tag.marker.setLabel(label);
    tag.marker.setIcon(iconUrl);

    if (pos.animation == this.iconAnimation) {
      tag.marker.setAnimation(this.iconAnimation);
    } else {
      tag.marker.setAnimation(null);
    }
  };

  clearDrivesFromMap(offlineDrivers, positionTags) {
    let newPositionTags = [];
    positionTags.forEach((tag, index) => {
      let driver = offlineDrivers.find((item) => item.userId == tag.id);
      if (driver) {
        tag.marker.setMap(null);
      } else {
        newPositionTags.push(tag);
      }
    });

    this.setPositionTags(newPositionTags);
  }
  createMarker = async (pos, map, iconUrl, label) => {
    let marker = new window.google.maps.Marker({
      position: pos.position,
      map: map,
      id: pos.userId + "_" + pos.categoryId,
      animation: pos.animation,
      icon: iconUrl,
      label: label,
      categoryId: pos.categoryId,
    });

    var driverInfo = await this.getInfoDriver(pos.userId, pos.categoryId);
    pos.driverInfo = { ...driverInfo, LastPosition: pos.position };
    pos.isOpen = true;

    let lng = pos.driverInfo?.LastPosition?.lng
      ? parseFloat(pos.driverInfo.LastPosition.lng).toFixed(5)
      : "Not informed";
    let lat = pos.driverInfo?.LastPosition?.lat
      ? parseFloat(pos.driverInfo.LastPosition.lat).toFixed(5)
      : "Not informed";
    let speed =
      pos.driverInfo?.LastPosition?.speed ||
      pos.driverInfo?.LastPosition?.speed == 0
        ? parseFloat(pos.driverInfo.LastPosition.speed).toFixed(0)
        : "Not informed";

    let counter =
      driverInfo.Counter === 0 || driverInfo.Counter
        ? driverInfo.Counter
        : "Not informed";

    const infowindow = new window.google.maps.InfoWindow({
      content: `<div className="info-window-body" style="color:black">

            <div>${pos.driverInfo?.Pilot || "Not informed"}</div>
            <div>Copilot: ${pos.driverInfo?.Copilot || "Not informed"}</div>
            <div>Category: ${pos.driverInfo?.Category}</div>

            <div>LAP ${counter}</div>
            <div>Speed: ${speed}</div>

            </div>`,

      //<div>LAST KNOWN LOCATION ${lat};${lng}</div>
      //<div>CAR ${pos.driverInfo?.CarNumber || "Not informed"}</div>
    });

    this.setInfoDriverWindowsTags([
      ...this.infoDriverWindowsTagsRef.current,
      {
        tag: infowindow,
        user: {
          userId: pos.userId,
          carNumber: pos.driverInfo?.CarNumber,
          pilot: pos.driverInfo?.Pilot,
          copilot: pos.driverInfo?.Copilot,
          counter: pos.counter,
          categoryId: pos.categoryId,
          category: pos.driverInfo?.Category,
        },
      },
    ]);

    marker.addListener("click", async () => {
      //this.toggleBounce(pos, this.driverPositionsRef.current);

      infowindow.open({
        anchor: marker,
        map,
        title: "Racer",
      });
    });

    return { marker, id: pos.userId + "_" + pos.categoryId };
  };
}
