import React, { useState, useEffect } from "react";

import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";

import { Utils } from "../utilities/Utils";
import {
  ErrorDialog,
  ErrorDialogEmpty,
  ErrorDialogSet,
  ErrorDialogSetResponse,
  ErrorDialogNoAuthorizedSet,
} from "../dialogs/ErrorDialog";

import { LicenseService } from "../service/LicenseService";

export const LicensingHistoryDialog = (props) => {
  const [history, setHistory] = useState(null);
  const [visibleDialog, setVisibleDialog] = useState(false);

  useEffect(() => {
    const load = async () => {
      const licenseService = new LicenseService();
      const resLicenses = await licenseService.getByClub(props.clubId);

      resLicenses.map((lic) => {
        let from = new Date(lic.From);
        let start = new Date(
          from.getUTCFullYear(),
          from.getUTCMonth(),
          from.getUTCDate()
        );
        let to = new Date(lic.To);
        let end = new Date(
          to.getUTCFullYear(),
          to.getUTCMonth(),
          to.getUTCDate()
        );
        lic.To = end;

        lic.From = start;
        return lic;
      });

      setHistory(resLicenses);

      setVisibleDialog(props.visible);
    };
    load();
  }, [props]);

  const hideDialog = () => {
    setVisibleDialog(false);
    props.onClose(null);
  };
  const periodBodyTemplate = (rowData) => {
    return (
      <span className="p-column-title">
        {Utils.dateDDMMYY(rowData.From) + " - " + Utils.dateDDMMYY(rowData.To)}
      </span>
    );
  };
  const givenBodyTemplate = (rowData) => {
    return <span className="p-column-title">{rowData.Total}</span>;
  };
  const usedBodyTemplate = (rowData) => {
    return <span className="p-column-title">{rowData.Given}</span>;
  };
  const eventsBodyTemplate = (rowData) => {
    return <span className="p-column-title">{rowData.Events}</span>;
  };

  const editBodyTemplate = (rowData) => {
    var to = new Date(rowData.To);
    var today = new Date();

    let formatToday = new Date(
      today.getUTCFullYear(),
      today.getUTCMonth(),
      today.getUTCDate()
    );
    let formatTo = new Date(
      to.getUTCFullYear(),
      to.getUTCMonth(),
      to.getUTCDate()
    );

    if (formatToday > formatTo) return <></>;

    return (
      <div className="actions">
        <Button
          icon="pi pi-pencil"
          className="p-mr-1"
          onClick={() => editLicense(rowData)}
        />
      </div>
    );
  };

  const editLicense = (_item) => {
    props.setLicense(_item);
  };

  return (
    <Dialog
      visible={visibleDialog}
      modal
      onHide={hideDialog}
      header="Licensing history"
      className="p-dialog-large"
    >
      <DataTable
        //ref={dt}
        value={history}
        // selection={selectedClubs}
        // onSelectionChange={(e) => setSelectedClubs(e.value)}
        // dataKey="cluId"
        // paginator
        // rows={10}
        // rowsPerPageOptions={[5, 10, 25]}
        className="table-layout-auto"
        // paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        // currentPageReportTemplate="Showing {first} to {last} of {totalRecords} clubs"
        // globalFilter={globalFilter}
        emptyMessage="No licenses found."
        //header={header}
      >
        <Column
          field="From"
          header="LICENSE PERIOD"
          bodyClassName="txt-center"
          sortable
          body={periodBodyTemplate}
        />
        <Column
          field="Given"
          header="TOTAL LICENSES ASSIGNED"
          headerClassName="txt-center"
          bodyClassName="txt-right"
          sortable
          body={givenBodyTemplate}
        />
        <Column
          field="Used"
          header="LICENSES USED"
          headerClassName="txt-center"
          bodyClassName="txt-right"
          body={usedBodyTemplate}
          sortable
        />
        <Column
          field="Total"
          header="TOTAL EVENTS"
          headerClassName="txt-center"
          bodyClassName="txt-right"
          className="is-last"
          body={eventsBodyTemplate}
          sortable
        />
        {props.isAdmin && (
          <Column
            field="Edit"
            header="EDIT"
            headerClassName="txt-center"
            bodyClassName="txt-right"
            className="is-last"
            body={editBodyTemplate}
          />
        )}
      </DataTable>
    </Dialog>
  );
};
