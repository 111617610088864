import React, { createRef, useRef, useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import Select from "react-select";
import { flag_types, flag_destination } from "../../../utilities/const";

export const FlagConfirmModal = (props) => {
  var footer = (
    <div>
      <Button
        label="Cancel"
        className="btn-secondary"
        onClick={() => props.setModalState(false)}
      />
      <Button
        label="Send"
        className="btn-primary "
        onClick={(e) => sendMessage()}
      />
    </div>
  );

  const sendMessage = () => {
    props.sendMessage(
      props.flagCode,
      props.flagmessage,
      0,
      flag_destination.ALLDRIVERS
    );
    props.setModalState(false);
  };

  return (
    <Dialog
      header={props.flagMessage + " Confirm"}
      className="p-fluid"
      visible={props.modalState}
      contentClassName="overflow-y-visible"
      footer={footer}
      onHide={() => props.setModalState(false)}
    >
      <span>{props.askMessageConfirmation}</span>
    </Dialog>
  );
};
