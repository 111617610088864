import moment from "moment";

export class Utils {
  static getFormDate() {
    let language = navigator.language || navigator.userLanguage;
    let dateFormat = "MM/DD/YYYY";

    if (language.toLowerCase().indexOf("es") != -1) {
      dateFormat = "DD/MM/YYYY";
    }

    return dateFormat;
  }

  static dateMMMMDoYYYY(val) {
    return val != null ? moment(val).format("MMMM Do, YYYY") : "-";
  }
  static dateDDMMYY(val) {
    return val != null ? moment(val).format(this.getFormDate()) : "/";
  }
  static formatDate(d) {
    if (d) d = new Date(d);
    return d;
  }
  static now() {
    return new Date();
  }
  static validPassword(password) {
    const validPassword = password.match(/^(?=.*[A-Z].*)(?=.*[0-9].*)/);

    if (validPassword) return true;
    return false;
  }
  static getCurrentTimeUTC() {
    var tmLoc = new Date();
    return new Date(tmLoc.getTime() + tmLoc.getTimezoneOffset() * 60000);
  }
  // static setLocalTimeFromUTC(date)
  // {
  //     var tmLoc = new Date();
  //     let offs = tmLoc.getTimezoneOffset();
  //     offs += offs * 60000;
  //     return new Date(date - offs);
  // }
  static setLocalTimeFromUTC(date) {
    var tmLoc = new Date();
    let offs = tmLoc.getTimezoneOffset();

    const d = date;
    let h = d.split(":");
    h[0] = "0" + (h[0] - offs);
    h[0] = h[0].substr(h[0].length - 2, 2);
    let t = h.join(":");
    return t;
  }
  static setOutGMT(date) {
    let res = new Date(date);
    res.setHours(res.getHours() - res.getTimezoneOffset() / 60);
    return res.toISOString();
  }

  static exportJsonToCSV = async (JSONData, ReportTitle, ShowLabel) => {
    //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
    var arrData =
      typeof JSONData !== "object" ? JSON.parse(JSONData) : JSONData;

    var CSV = "";

    //This condition will generate the Label/Header
    if (ShowLabel) {
      var row = "";

      //This loop will extract the label from 1st index of on array
      for (var index in arrData[0]) {
        //Now convert each value to string and comma-seprated
        row += index + ",";
      }

      row = row.slice(0, -1);

      //append Label row with line break
      CSV += row + "\r\n";
    }

    //1st loop is to extract each row
    for (var i = 0; i < arrData.length; i++) {
      var row = "";

      //2nd loop will extract each column and convert it in string comma-seprated
      for (var index in arrData[i]) {
        row += '"' + arrData[i][index] + '",';
      }

      row.slice(0, row.length - 1);

      //add a line break after each row
      CSV += row + "\r\n";
    }

    if (CSV === "") {
      alert("Invalid data");
      return;
    }

    //Generate a file name
    var fileName = "MyReport_";
    //this will remove the blank-spaces from the title and replace it with an underscore
    fileName += ReportTitle.replace(/ /g, "_");

    //Initialize file format you want csv or xls
    var uri = "data:text/csv;charset=utf-8," + escape(CSV);

    // Now the little tricky part.
    // you can use either>> window.open(uri);
    // but this will not work in some browsers
    // or you will not get the correct file extension

    //this trick will generate a temp <a /> tag
    var link = document.createElement("a");
    link.href = uri;

    //set the visibility hidden so it will not effect on your web-layout
    link.style = "visibility:hidden";
    link.download = fileName + ".csv";

    //this part will append the anchor tag and remove it after automatic click
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  static downloadJson = async (objectData, ReportTitle) => {
    let filename = ReportTitle + ".json";
    let contentType = "application/json;charset=utf-8;";
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      var blob = new Blob(
        [decodeURIComponent(encodeURI(JSON.stringify(objectData)))],
        { type: contentType }
      );
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      var a = document.createElement("a");
      a.download = filename;
      a.href =
        "data:" +
        contentType +
        "," +
        encodeURIComponent(JSON.stringify(objectData));
      a.target = "_blank";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  static headerKml = `
<?xml version="1.0" encoding="UTF-8"?>
<kml xmlns="http://www.opengis.net/kml/2.2">
  <Document>
    <name>Track</name>
    <Style id="icon-1661-000000-nodesc-normal">
      <IconStyle>
        <color>ff000000</color>
        <scale>1</scale>
        <Icon>
          <href>https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png</href>
        </Icon>
      </IconStyle>
      <LabelStyle>
        <scale>0</scale>
      </LabelStyle>
      <BalloonStyle>
        <text><![CDATA[<h3>$[name]</h3>]]></text>
      </BalloonStyle>
    </Style>
    <Style id="icon-1661-000000-nodesc-highlight">
      <IconStyle>
        <color>ff000000</color>
        <scale>1</scale>
        <Icon>
          <href>https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png</href>
        </Icon>
      </IconStyle>
      <LabelStyle>
        <scale>1</scale>
      </LabelStyle>
      <BalloonStyle>
        <text><![CDATA[<h3>$[name]</h3>]]></text>
      </BalloonStyle>
    </Style>
    <StyleMap id="icon-1661-000000-nodesc">
      <Pair>
        <key>normal</key>
        <styleUrl>#icon-1661-000000-nodesc-normal</styleUrl>
      </Pair>
      <Pair>
        <key>highlight</key>
        <styleUrl>#icon-1661-000000-nodesc-highlight</styleUrl>
      </Pair>
    </StyleMap>
    <Style id="icon-1661-0F9D58-nodesc-normal">
      <IconStyle>
        <color>ff589d0f</color>
        <scale>1</scale>
        <Icon>
          <href>https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png</href>
        </Icon>
      </IconStyle>
      <LabelStyle>
        <scale>0</scale>
      </LabelStyle>
      <BalloonStyle>
        <text><![CDATA[<h3>$[name]</h3>]]></text>
      </BalloonStyle>
    </Style>
    <Style id="icon-1661-0F9D58-nodesc-highlight">
      <IconStyle>
        <color>ff589d0f</color>
        <scale>1</scale>
        <Icon>
          <href>https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png</href>
        </Icon>
      </IconStyle>
      <LabelStyle>
        <scale>1</scale>
      </LabelStyle>
      <BalloonStyle>
        <text><![CDATA[<h3>$[name]</h3>]]></text>
      </BalloonStyle>
    </Style>
    <StyleMap id="icon-1661-0F9D58-nodesc">
      <Pair>
        <key>normal</key>
        <styleUrl>#icon-1661-0F9D58-nodesc-normal</styleUrl>
      </Pair>
      <Pair>
        <key>highlight</key>
        <styleUrl>#icon-1661-0F9D58-nodesc-highlight</styleUrl>
      </Pair>
    </StyleMap>
    <Style id="line-000000-1200-nodesc-normal">
      <LineStyle>
        <color>ff000000</color>
        <width>1.2</width>
      </LineStyle>
      <BalloonStyle>
        <text><![CDATA[<h3>$[name]</h3>]]></text>
      </BalloonStyle>
    </Style>
    <Style id="line-000000-1200-nodesc-highlight">
      <LineStyle>
        <color>ff000000</color>
        <width>1.8</width>
      </LineStyle>
      <BalloonStyle>
        <text><![CDATA[<h3>$[name]</h3>]]></text>
      </BalloonStyle>
    </Style>
    <StyleMap id="line-000000-1200-nodesc">
      <Pair>
        <key>normal</key>
        <styleUrl>#line-000000-1200-nodesc-normal</styleUrl>
      </Pair>
      <Pair>
        <key>highlight</key>
        <styleUrl>#line-000000-1200-nodesc-highlight</styleUrl>
      </Pair>
    </StyleMap>
    <Style id="poly-000000-1200-77-nodesc-normal">
      <LineStyle>
        <color>ff000000</color>
        <width>1.2</width>
      </LineStyle>
      <PolyStyle>
        <color>4d000000</color>
        <fill>1</fill>
        <outline>1</outline>
      </PolyStyle>
      <BalloonStyle>
        <text><![CDATA[<h3>$[name]</h3>]]></text>
      </BalloonStyle>
    </Style>
    <Style id="poly-000000-1200-77-nodesc-highlight">
      <LineStyle>
        <color>ff000000</color>
        <width>1.8</width>
      </LineStyle>
      <PolyStyle>
        <color>4d000000</color>
        <fill>1</fill>
        <outline>1</outline>
      </PolyStyle>
      <BalloonStyle>
        <text><![CDATA[<h3>$[name]</h3>]]></text>
      </BalloonStyle>
    </Style>
    <StyleMap id="poly-000000-1200-77-nodesc">
      <Pair>
        <key>normal</key>
        <styleUrl>#poly-000000-1200-77-nodesc-normal</styleUrl>
      </Pair>
      <Pair>
        <key>highlight</key>
        <styleUrl>#poly-000000-1200-77-nodesc-highlight</styleUrl>
      </Pair>
    </StyleMap>
  `;

  static footerKml = `  
  </Document>
</kml>`;

  static polylineKml = (points) => {
    if (points.length === 0) return "";
    let coordinates = points
      .map((coord) => coord.lng + "," + coord.lat + ",0")
      .join(`\r\n`);

    return (
      `
    <Placemark>
      <name>Track</name>
      <styleUrl>#line-000000-1200-nodesc</styleUrl>
      <LineString>
        <tessellate>1</tessellate>
        <coordinates>` +
      coordinates +
      `</coordinates>
      </LineString>
    </Placemark>
`
    );
  };

  static StartKml = (points) => {
    if (points.length === 0) return "";
    let coordinates = points
      .map((coord) => coord.lng + "," + coord.lat + ",0")
      .join(`\r\n`);

    return (
      `    
    <Placemark>
      <name>Start</name>
      <styleUrl>#icon-1661-0F9D58-nodesc</styleUrl>
      <Point>
        <coordinates>
        ` +
      coordinates +
      `
        </coordinates>
      </Point>
</Placemark>`
    );
  };

  static FinishKml = (points) => {
    if (points.length === 0) return "";
    let coordinates = points
      .map((coord) => coord.lng + "," + coord.lat + ",0")
      .join(`\r\n`);

    return (
      `    
    <Placemark>
      <name>Finish</name>
      <styleUrl>#icon-1661-000000-nodesc</styleUrl>
      <Point>
        <coordinates>
        ` +
      coordinates +
      `
        </coordinates>
      </Point>
    </Placemark>`
    );
  };

  static BoxesKml = (points) => {
    if (points.length === 0) return "";
    let coordinates = points
      .map((coord) => coord.lng + "," + coord.lat + ",0")
      .join(`\r\n`);

    return (
      `    
    <Placemark>
      <name>Boxes</name>
      <styleUrl>#poly-000000-1200-77-nodesc</styleUrl>
      <Polygon>
        <outerBoundaryIs>
          <LinearRing>
            <tessellate>1</tessellate>
            <coordinates>
            ` +
      coordinates +
      `
            </coordinates>
          </LinearRing>
        </outerBoundaryIs>
      </Polygon>
    </Placemark>`
    );
  };

  static downloadKml = async (track, ReportTitle) => {
    let filename = track.trackName + `.kml`;

    let contentType = "application/xml;";
    let text =
      this.headerKml +
      this.polylineKml(track.shapes.PointsTrack) +
      this.StartKml(track.shapes.PointsMetaStart) +
      this.FinishKml(track.shapes.PointsMetaFinish) +
      this.BoxesKml(track.shapes.PointsBoxes) +
      this.footerKml;

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      var blob = new Blob(
        [decodeURIComponent(encodeURI(JSON.stringify(text)))],
        { type: contentType }
      );
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      let element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:text/xml;charset=utf-8," + encodeURIComponent(text)
      );
      element.setAttribute("download", filename);

      element.style.display = "none";
      document.body.appendChild(element);

      setTimeout(() => {
        element.click();
        document.body.removeChild(element);
      }, 500);
    }
  };
}
