import { Button } from "primereact/button";

export const NewMetaMenu = (props) => {
  return (
    <div className="menu-edit-map p-jc-between">
      <div className="p-d-flex p-ai-center">
        <Button
          icon="pi pi-chevron-left"
          className="p-button-rounded  p-button-outlined p-button-icon-only p-mr-3"
          onClick={props.back}
        />
        <p className="fs-x-normal  p-mr-3"> {props.title} </p>
        <p className="fs-medium txt-clrLight150 p-mr-5">
          {props.coordatesText || "No coordinates available"}
        </p>
      </div>

      <div className="p-d-flex p-ai-center">
        <Button
          label="Cancel"
          className="btn-outline-invert  p-mr-2"
          onClick={props.cancel}
        />
        {props.drawingMode === null && (
          <Button
            label="Save"
            className="btn-primary-invert"
            //onClick={props.continuePolyline}
            onClick={props.save}
          />
        )}
      </div>
    </div>
  );
};
