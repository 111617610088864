import React, { useState, useEffect, useRef } from "react";

export const ShortMessageBox = ({seconds, message, className}) => {

    const [waitSeconds, setWaitSeconds] = useState((seconds));
    const intervalId = useRef(0);

    useEffect(() => {
       
      if (seconds > 0) {
        intervalId.current = setInterval(() => {
            setWaitSeconds((s) => { 
                const newSeconds = s - 1; 
                if (newSeconds < 2) {
                    clearInterval(intervalId.current);
                }
                return newSeconds;
                }
            );
        }, 1000);
      }

      return () => {
        if (intervalId.current > 0) {
            clearInterval(intervalId.current);
            intervalId.current = 0;
        }
      }
    }, [])
        

    return <>
            <div className={className} > {message} {waitSeconds}</div>
        </>
}

