import React, {useState, useEffect} from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";

import classNames from "classnames";
import { $ } from "react-jquery-plugin";

import { TracksService } from "../../service/TracksService";
import { ErrorDialog, ErrorDialogEmpty, ErrorDialogNoAuthorizedSet, ErrorDialogSet, ErrorDialogSetResponse } from "../../dialogs/ErrorDialog";

export const EditDialog = (props) => {

    const [submitted, setSubmitted] = useState(false);
    const [item, setItem] = useState(props.item);

    useEffect(() => {
        const _item = {...props.item};
        setItem(_item);

    }, [props.visible]);

    const setField = (field) => (ev) => setItem({ ...item, [field]: ev.target.value});

    const validateRequired = () => {
        //if (!user.isPlanner && !user.isViewer && !user.isDirector) return false;

        for (let k = 0; k < $("[Required]").length; k++) {
            if (!$("[Required]")[k].value) return false;
        }
        return true;
    };
 

    const save = () => {
        setSubmitted(true);

        try {
            if (validateRequired()) {
                let _track = { ...item };
                if (_track.traId) {
                    const tracksService = new TracksService();
                    tracksService
                        .updateTrack(_track)
                        .then((data) => {
                            props.onChange(_track);
                            props.hideDialog();
                        })
                        .catch((res) => {
                            var message = "";
                            if (res.response && res.response.data && res.response.data.statusCode === 418) message = res.response.data.message;

                            props.setStateErrorDialog(ErrorDialogSet(true, message));
                        });
                } else {
                    const tracksService = new TracksService();
                    tracksService
                        .createTrack(_track)
                        .then((data) => {
                            _track.traId = data.traId;
                            _track.traName = data.traName;
                            props.onChange(_track);
                            props.hideDialog();
                        })
                        .catch((res) => {
                            props.setStateErrorDialog(ErrorDialogSetResponse(res));
                        });
                }
            }
        } catch (error) {
            props.setStateErrorDialog(ErrorDialogSet(true, ""));
            console.log(error.message);
        }
    };

    const dialogFooter = (
        <>
            <Button label="Cancel" className="btn-secondary" onClick={props.hideDialog} />
            <Button label="Save" className="btn-primary" onClick={save} />
        </>
    );

    return (
        <Dialog visible={props.visible} style={{ width: "450px" }} header="Tracks Details" modal className="p-fluid" footer={dialogFooter} onHide={props.hideDialog}>
            <div className="p-field">
                <label htmlFor="name">Name</label>
                <InputText id="name" maxLength="100" value={item.traName} 
                onChange={setField("traName")} 
                required={true} autoFocus className={classNames({ "p-invalid": submitted && !item.traName })} 
                />
                {submitted && !item.traName && <small className="p-invalid">Name is required.</small>}
            </div>
        </Dialog>
    )
}