export const POI_VALUES = {
  LOCATION: "location",
  AMBULANCIA: "ambulancia",
  BANDERILLERO: "banderillero",
  BOMBEROAPIE: "bomberoapie",
  BOMBEROMOTORIZADO: "bomberomotorizado",
  RESCATE: "rescate",
  SAFETYCAR: "safetycar",
};

export const SVG = [
  {
    Description: POI_VALUES.LOCATION,
    Path: "pois/common.svg",
  },
  {
    Description: POI_VALUES.AMBULANCIA,
    Path: "pois/racer-icon-ambulancia.svg",
  },
  {
    Description: POI_VALUES.BANDERILLERO,
    Path: "pois/racer-icon-banderillero.svg",
  },
  {
    Description: POI_VALUES.BOMBEROAPIE,
    Path: "pois/racer-icon-bomberoapie.svg",
  },
  {
    Description: POI_VALUES.BOMBEROMOTORIZADO,
    Path: "pois/racer-icon-bomberomotorizado.svg",
  },
  {
    Description: POI_VALUES.RESCATE,
    Path: "pois/racer-icon-rescate.svg",
  },
  {
    Description: POI_VALUES.SAFETYCAR,
    Path: "pois/racer-icon-safetycar.svg",
  },
];
