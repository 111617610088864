import React, { useState, useEffect } from "react";

import classNames from "classnames";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { InputNumber } from "primereact/inputnumber";

import { $ } from "react-jquery-plugin";

import { Utils } from "../utilities/Utils";
import {
  ErrorDialog,
  ErrorDialogEmpty,
  ErrorDialogSet,
  ErrorDialogSetResponse,
  ErrorDialogNoAuthorizedSet,
} from "../dialogs/ErrorDialog";

import { LicenseService } from "../service/LicenseService";

export const LicenseDialog = (props) => {
  let emptyLicense = {
    LicId: null,
    Total: 0,
    Given: 0,
    From: null,
    To: null,
    Events: 0,
  };

  var language = navigator.language || navigator.userLanguage;
  let dateFormat = "mm/dd/yy";

  if (language.toLowerCase().indexOf("es") != -1) {
    dateFormat = "dd/mm/yy";
  }

  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(null);
  const [submitted, setSubmitted] = useState(false);

  const [license, setLicense] = useState(emptyLicense);
  const [newLicenseView, setNewLicenseView] = useState(false);
  const [newLic, setNewLic] = useState(0);

  const [stateErrorDialog, setStateErrorDialog] = useState(ErrorDialogEmpty);

  useEffect(() => {
    const load = async () => {
      setLoading(true);
      setSubmitted(false);

      setVisible(props.visibleDialog);

      let _license = { ...props.license };
      setLicense(_license);
      if (_license.LicId) setNewLicenseView(false);
      else setNewLicenseView(true);

      setLoading(false);
    };
    load();
  }, [props.visibleDialog, props.license]);

  if (loading)
    return (
      <div className="loading-container">
        <h3>Loading...</h3>
      </div>
    );

  const onInputChange = (e, name) => {
    const val = (e && e.value) || "";
    let _item = { ...license };
    _item[`${name}`] = val;

    setLicense(_item);
  };
  const onInputCalendarChange = async (e, name) => {
    const val = (e && e.value) || "";
    let _item = { ...license };
    _item[`${name}`] = val;

    const licenseInfo = await getlicenseByDate(_item);
    if (licenseInfo) {
      _item = licenseInfo;
    }

    setLicense(_item);
  };
  const onInputChangeNewLic = (e) => {
    const val = (e && e.value) || "";
    if (val === "" || val === "-") setNewLic(0);
    else setNewLic(val);
  };

  const getlicenseByDate = async (license) => {
    let linceseInfo = null;
    if (license.From && license.To) {
      let licenseService = new LicenseService();
      let info = await licenseService.getLicenseByDate(license);
      if (info.length > 0) {
        linceseInfo = info[0];
        linceseInfo.Total = parseInt(linceseInfo.Total);
      }
    }
    return linceseInfo;
  };

  const validateRequired = () => {
    // if ((!newLicenseView) &&(newLic == 0))
    //     return false;

    for (let k = 0; k < $("[Required]").length; k++) {
      if (!$("[Required]")[k].value) return false;
    }
    return true;
  };

  const closeCancel = () => {
    props.onClose(null);
  };
  const closeOk = (_license) => {
    setSubmitted(false);
    props.onClose(_license);
  };
  const cancel = () => {
    closeCancel();
  };

  const saveLicense = async () => {
    setSubmitted(true);

    try {
      if (validateRequired()) {
        setSubmitted(false);
        let _license = { ...license };
        _license.Total = parseInt(_license.Total) + parseInt(newLic);
        setNewLic(0);

        if (_license.LicId) {
          let licenseService = new LicenseService();
          licenseService
            .updateLicense(_license)
            .then(() => {
              closeOk(_license);
            })
            .catch((res) => {
              setStateErrorDialog(ErrorDialogSetResponse(res));
            });
        } else {
          let licenseService = new LicenseService();
          licenseService
            .createLicense(_license)
            .then((data) => {
              _license["LicId"] = data.licId;
              //setLicense(_license);
              closeOk(_license);
            })
            .catch((res) => {
              setStateErrorDialog(ErrorDialogSetResponse(res));
            });
        }
      }
    } catch (error) {
      setStateErrorDialog(ErrorDialogSet(true, ""));
    }
  };

  const eventDialogFooter = (
    <>
      <Button label="Cancel" className="btn-secondary" onClick={cancel} />
      <Button label="Save" className="btn-primary" onClick={saveLicense} />
    </>
  );
  return (
    <>
      <Dialog
        visible={visible}
        style={{ width: "650px" }}
        header="Edit license details"
        modal
        className="p-fluid"
        footer={eventDialogFooter}
        contentClassName="overflow-y-visible"
        onHide={closeCancel}
      >
        {newLicenseView && (
          <div className="p-field">
            <label htmlFor="total">total licenses: </label>
            <InputNumber
              integeronly
              id="total"
              value={license.Total}
              onChange={(e) => onInputChange(e, "Total")}
              className={classNames({
                "p-invalid": submitted && !license.Total,
              })}
            />
            {submitted && !license.Total && (
              <small className="p-invalid">Total is required.</small>
            )}
          </div>
        )}
        {!newLicenseView && (
          <div className="p-field p-mb-4">
            <p className="fs-medium p-mb-2">Total licenses: </p>
            <p className="fs-x-normal">{license.Total} </p>
          </div>
        )}
        <div className="p-grid">
          <div className="p-field p-col-6 p-mb-0">
            <label htmlFor="from">From</label>
            <Calendar
              id="from"
              value={Utils.formatDate(license.From)}
              dateFormat={dateFormat}
              onChange={(e) => onInputCalendarChange(e.target, "From")}
              required
              showIcon={true}
              className={classNames({
                "p-invalid": submitted && !license.From,
              })}
            />
            {submitted && !license.From && (
              <small className="p-invalid">From is required.</small>
            )}
          </div>
          <div className="p-field p-col-6 p-mb-0">
            <label htmlFor="to">To</label>
            <Calendar
              id="to"
              value={Utils.formatDate(license.To)}
              dateFormat={dateFormat}
              onChange={(e) => onInputCalendarChange(e.target, "To")}
              required
              showIcon={true}
              className={classNames({ "p-invalid": submitted && !license.To })}
            />
            {submitted && !license.To && (
              <small className="p-invalid">To is required.</small>
            )}
          </div>
        </div>

        <hr />
        {!newLicenseView && (
          <div className="p-grid">
            <div className="p-field p-col p-mb-0">
              <label htmlFor="number">Number or new licenses</label>
              <InputNumber
                integeronly
                id="number"
                value={newLic}
                maxLength="100"
                onChange={(e) => onInputChangeNewLic(e)}
                required
                showIcon={true}
                className={classNames({
                  "p-invalid": submitted && !newLic && newLic != "0",
                })}
              />
              {submitted && !newLic && newLic != "0" && (
                <small className="p-invalid">Number is required.</small>
              )}
            </div>

            <div className="p-field p-col p-mb-0">
              <p className="fs-medium p-mb-2">New total licenses </p>
              <p className="fs-x-normal">
                {parseInt(license.Total) + parseInt(newLic)}
              </p>
            </div>
          </div>
        )}
      </Dialog>

      <ErrorDialog stateDialog={stateErrorDialog}></ErrorDialog>
    </>
  );
};
