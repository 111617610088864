import axios from "../utilities/api-axios";

const apiUrl = process.env.REACT_APP_API_URL;

export class InvitationService {
  async getById(id) {
    const res = await axios.get(`${apiUrl}/invitations/` + id); //.then((res) => res.data);
    return res.data;
  }

  async authorized(auhtVal, invitations) {
    var request = {
      Invitations: invitations,
      Authorized: auhtVal,
    };
    const res = await axios.patch(`${apiUrl}/invitations/authorized/`, request);
    return res;
  }

  async send(invitations) {
    return await axios.patch(`${apiUrl}/invitations/send/`, invitations);
  }

  async deleteSelected(invitations) {
    return await axios.patch(
      `${apiUrl}/invitations/deleteSelected/`,
      invitations
    );
  }

  async removeAll(eveId) {
    return await axios.patch(`${apiUrl}/invitations/removeAll/`, eveId);
  }

  async getByDriverEvent(DriverId, EventId, CategoryId) {
    var request = {
      DriverId: DriverId,
      EventId: EventId,
      CategoryId: CategoryId,
    };

    const res = await axios.get(`${apiUrl}/invitations/byDriverEvent/`, {
      params: request,
    });
    return res;
  }

  async updateInvitationDriver(driver) {
    var request = {
      User: {
        Id: driver.UserId,
        Name: driver.FirstName,
        Surname: driver.LastName,
        Phone: driver.Phone,
        Email: driver.Email,
      },
      Driver: {
        Id: driver.DriverId,
        GrupoSanguineo: driver.DriverGrupoSanguineo,
      },
      Invitation: {
        Id: driver.InvitationId,
        EveId: driver.EventId,
        Number: driver.Number,
        Brand: driver.Brand,
        Model: driver.Model,
        CatId: driver.CategoryId,
        CodriverName: driver.CodriverName,
        CodriverSurname: driver.CodriverSurname,
        CodriverGrupoSang: driver.CodriverGrupoSanguineo,
      },
    };
    const res = await axios.put(
      `${apiUrl}/invitations/updateInvitationDriver/`,
      request
    );
    return res;
  }

  async createInvitationDriver(driver) {
    var request = {
      User: {
        Id: driver.UserId,
        Name: driver.FirstName,
        Surname: driver.LastName,
        Phone: driver.Phone,
        Email: driver.Email,
      },
      Driver: {
        Id: driver.DriverId,
        GrupoSanguineo: driver.DriverGrupoSanguineo,
      },
      Invitation: {
        Id: driver.InvitationId,
        EveId: driver.EventId,
        Number: driver.Number,
        Brand: driver.Brand,
        Model: driver.Model,
        CatId: driver.CategoryId,
        CodriverName: driver.CodriverName,
        CodriverSurname: driver.CodriverSurname,
        CodriverGrupoSang: driver.CodriverGrupoSanguineo,
      },
    };
    const res = await axios.post(
      `${apiUrl}/invitations/createInvitationDriver/`,
      request
    );
    return res;
  }
}
