import React, {useState} from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";

export const CircuitDataTable = (props) => {

    const [globalFilter, setGlobalFilter] = useState(null);
    
    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title txt-bold">{rowData.cirName}</span>
            </>
        );
    };   
    
    const visibilityBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title txt-bold">{rowData.cirVisibility ? "SI" : "NO"}</span>
            </>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-mr-1" onClick={() => props.openDialog(rowData)} />
                <Button icon="pi pi-trash" className="btn-alert" onClick={() => props.openDeleteDialog(rowData)} />
            </div>
        );
    };

    const header = (
        <div className="table-header p-mb-5">
            <h1 className="p-m-0 fs-x-normal">Circuits</h1>
            <div>
                <span className="p-input-icon-right p-mr-4">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search Circuit" />
                </span>
                <Button label="New Circuit" icon="pi pi-plus" className="btn-primary" onClick={() => props.openDialog(props.emptyItem)} />
            </div>
        </div>
    );

    return (
        <DataTable
        value={props.items}
        dataKey="cirId"
        paginator
        rows={10}
        rowsPerPageOptions={[5, 10, 25]}
        className="datatable-responsive table-layout-auto"
        globalFilter={globalFilter}
        emptyMessage="No Circuit found."
        header={header}
    >
        <Column field="cirName" header="NAME" sortable body={nameBodyTemplate} />
        <Column field="cirVisibility" header="Visibility" sortable body={visibilityBodyTemplate} />
        <Column body={actionBodyTemplate} className="is-last" />

    </DataTable>        
    );
}