import { useState, useEffect, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
// import { Checkbox } from "primereact/checkbox";
// import { Dialog } from "primereact/dialog";
// import {Password} from 'primereact/password';

import { TokenAuthService } from "../service/TokenAuthService";
// import { UserService } from "../service/UserService";

import { LoginModel } from "../model/LoginModel";
import {
  ErrorDialog,
  ErrorDialogEmpty,
  ErrorDialogSet,
  ErrorDialogSetResponse,
} from "../dialogs/ErrorDialog";

import { ForgotPassword } from "../components/login/ForgotPassword";
import { PasswordRecovery } from "../components/login/PasswordRecovery";
import { CreateNewPassword } from "../components/login/CreateNewPassword";
import { Password } from "../components/login/Password";
import { Link } from "react-router-dom";

export const LoginScreen = (props) => {
  const [userId, setUserId] = useState(null);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  // const [newPassword, setNewPassword] = useState("");
  // const [confirmPassword, setConfirmPassword] = useState("");

  // const [checked, setChecked] = useState(false);

  const [stateErrorDialog, setStateErrorDialog] = useState(ErrorDialogEmpty);

  const toast = useRef(null);
  const [loading, setLoading] = useState(true);

  // const [visibleLogin, setVisibleLogin] = useState(true);
  // const [visibleChangePassword, setVisibleChangePassword] = useState(false);

  //Forgot Password Path
  const [forgotPassword, setForgotPassword] = useState(false);
  const [codePass, setCodePass] = useState(false);
  const [createNewPass, setCreateNewPass] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [emailRecoveryPass, setEmailRecoveryPass] = useState("");
  const [firstAccess, setFirstAccess] = useState(false);

  const [passworUpdated, setPassworUpdated] = useState(false);

  useEffect(() => {
    try {
      const load = async () => {
        setLoading(true);

        setLoading(false);
      };
      load();
    } catch (error) {
      alert(error);
    }
  }, [props]);

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();

        // 👇️ call submit function here
        handleLoginClick();
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  });

  if (loading)
    return (
      <div className="loading-container">
        <h3>Loading...</h3>
      </div>
    );

  if (passworUpdated) {
    toast.current.show({
      severity: "success",
      summary: "Successful",
      detail: "Password updated",
      life: 3000,
    });
    setPassworUpdated(false);
  }

  const isValid = () => {
    if (
      username.length < 8 ||
      username.length > 50 ||
      password.length < 8 ||
      password.length > 20
    ) {
      return false;
    }
    return true;
  };

  function handleLoginClick() {
    setSubmitted(true);

    if (!isValid()) {
      return;
    }

    let tokenAuthService = new TokenAuthService();
    let loginModel = new LoginModel(username, password);

    tokenAuthService
      .authenticate(loginModel)
      .then((data) => {
        if (data.changepassword === true) {
          setEmailRecoveryPass(username);
          setFirstAccess(true);
          setCreateNewPass(true);

          setUserId(data.userId);
          //setVisibleChangePassword(true);
        } else {
          props.onLoggedIn();
          // history.push("/dashboard");
        }
      })
      .catch((error) => {
        let errormessage = "The email or password are wrong. Try again! ";
        if (error.response) {
          errormessage = error.response.data.message;
        }

        if (error.message == "Rol not allowed") {
          errormessage = "Rol not allowed, you cannot be a driver";
        }
        setStateErrorDialog(ErrorDialogSet(true, errormessage));
      });
  }

  //Funtion Forgot Password Path
  const handleBackToLogin = () => {
    setForgotPassword(false);
  };

  const handleForgotPassword = () => {
    setForgotPassword(true);
  };

  const handleCodePass = async (email) => {
    setEmailRecoveryPass(email);
    setForgotPassword(false);
    setCodePass(true);
  };

  const handleBackToForgotPassword = () => {
    setCodePass(false);
    setForgotPassword(true);
  };

  const handleCreateNewPass = () => {
    setCodePass(false);
    setFirstAccess(false);
    setCreateNewPass(true);
  };

  const handleUpdatePass = () => {
    setCreateNewPass(false);
    setCodePass(false);
    setForgotPassword(false);

    setPassword("");
    setSubmitted(false);
    setPassworUpdated(true);
  };

  const handleBackToCodePassOrLogin = () => {
    setCreateNewPass(false);
    if (!firstAccess) setCodePass(true);
    else {
      setCodePass(false);
    }
    setPassword("");
    setSubmitted(false);
  };

  //Forgot Password
  if (forgotPassword) {
    return (
      <ForgotPassword
        onHandleCodePass={handleCodePass}
        onHandleBackToLogin={handleBackToLogin}
      />
    );
  }

  if (codePass) {
    return (
      <PasswordRecovery
        mail={emailRecoveryPass}
        onHandleCreateNewPass={handleCreateNewPass}
        onHandleBackToForgotPassword={handleBackToForgotPassword}
      />
    );
  }

  if (createNewPass) {
    return (
      <CreateNewPassword
        mail={emailRecoveryPass}
        password={password}
        onHandleBackToCodePass={handleBackToCodePassOrLogin}
        onHandleUpdatePass={handleUpdatePass}
      />
    );
  }

  return (
    <>
      <div className="login-page">
        <div className="p-m-0 p-col-12 p-md-6 p-d-flex p-jc-center p-ai-center bg-clrDark800">
          {/* {!visibleChangePassword && ( */}
          <div className="form-login">
            <Toast ref={toast} />
            <img
              src="assets/rally/logo-rally-login.png"
              className="logo"
              alt="Racer"
            />
            <h2 className="fs-big p-mb-5">Welcome to Racer</h2>
            <div className="p-field">
              <label htmlFor="inputgroup" className="p-d-block">
                Email
              </label>
              <InputText
                type="text"
                id="inputgroup"
                className="p-d-block"
                placeholder="Email"
                value={username}
                maxLength="50"
                onChange={(e) => setUsername(e.target.value)}
              />
              {submitted && !username && (
                <small className="p-invalid">Email is required.</small>
              )}
            </div>

            <div className="p-field">
              <label htmlFor="password" className="p-d-block">
                Password
              </label>

              <Password
                value={password}
                onChange={setPassword}
                placeholder="***********"
                required={submitted}
                messageRequired="Password is required."
              ></Password>
            </div>
            {/* {submitted && !password && <small className="p-invalid">Password is required. </small>} */}
            {submitted && !isValid() && username && (
              <small className="p-invalid">
                Your email and password must have at least 8 characters.
              </small>
            )}

            <div className="p-d-flex p-jc-between p-ai-center p-mt-5 p-mb-3">
              <div className="p-field-checkbox p-mb-0">
                {/* <Checkbox inputId="remember" checked={checked} onChange={(e) => setChecked(e.checked)} />
                                    <label htmlFor="remember" className="fs-small">
                                        Remember Me
                                    </label> */}
              </div>

              <Button
                label="Forgot Password?"
                className="p-button-link forgot-btn"
                onClick={handleForgotPassword}
              />
            </div>

            <Button
              label="Login"
              className="btn-secondary  login-btn"
              onClick={handleLoginClick}
              type="submit"
            />
            <div className="p-mt-5 forgot-btn">
              <a
                target="_blank"
                rel="noreferrer"
                href={
                  "https://alder-alibi-ada.notion.site/Instructivos-de-uso-User-manual-49ba26bec74d4c1ba442e9ac7f334e05"
                }
              >
                <span className="p-c">Help/Online Manual</span>
              </a>
            </div>
          </div>
          {/* )} */}
        </div>
        <div className="p-m-0 p-col-12 p-md-6 bg-clrDark800 bg-login">
          <img
            src="assets/rally/login-bg.png"
            className="bg-login-img"
            alt="Racer"
          />
        </div>
      </div>
      <ErrorDialog stateDialog={stateErrorDialog}></ErrorDialog>
    </>
  );
};
