import React, { useState, useEffect, useRef } from "react";
import { Panel } from "primereact/panel";
import { Checkbox } from "primereact/checkbox";

export const CategoryBox = (props) => {
  const [selectedCategories, setSelectedCategories] = useState([]);

  useEffect(() => {
    setSelectedCategories(
      props.categories.map((cat) => parseInt(cat.Id)).concat([999])
    );
  }, [props.categories]);

  const onCategoryChange = (e) => {
    let newSelected = [...selectedCategories];

    if (e.value === 999) {
      newSelected = [];
      if (e.checked) {
        newSelected.push(parseInt(e.value));
        props.categories.forEach((cat) => newSelected.push(parseInt(cat.Id)));
      }
    } else {
      if (e.checked) {
        newSelected.push(parseInt(e.value));
        if (newSelected.length === props.categories.length)
          newSelected.push(999);
      } else {
        newSelected.splice(newSelected.indexOf(parseInt(e.value)), 1);
        if (newSelected.indexOf(999) > -1)
          newSelected.splice(newSelected.indexOf(999), 1);
      }
    }

    setSelectedCategories(newSelected);
    props.filterByCategory(newSelected);
  };

  const isCheckedCategory = (categoryId) => {
    const category = selectedCategories.find(
      (cat) => parseInt(cat) == parseInt(categoryId)
    );
    let existCategory = false;

    if (category) existCategory = true;

    return existCategory;
  };

  return (
    <>
      {props.categories.length > 0 && (
        <div className="box" data-html2canvas-ignore>
          <Panel
            header={`Categories`}
            toggleable
            collapsed={props.categoryBoxCollapsed}
            onToggle={(e) => props.setOfflineCollapsed(e.value)}
          >
            <div className="col-12" key={999}>
              <Checkbox
                inputId="999"
                value={999}
                onChange={onCategoryChange}
                checked={isCheckedCategory(999)}
              ></Checkbox>
              <label htmlFor="999" className="p-checkbox-label">
                all
              </label>
            </div>
            {props.categories.map((cat, index) => {
              return (
                <div className="col-12" key={index}>
                  <Checkbox
                    inputId={cat.Id}
                    value={cat.Id}
                    onChange={onCategoryChange}
                    checked={isCheckedCategory(cat.Id)}
                  ></Checkbox>
                  <label htmlFor={cat.catId} className="p-checkbox-label">
                    {cat.Description}
                  </label>
                </div>
              );
            })}
          </Panel>
        </div>
      )}
    </>
  );
};
