import moment from "moment";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import audio from "../audios/alarm.redflag.mp3";
import CustomGoogleMap from "../components/CustomGoogleMap";
import { TrackerMenu } from "../components/menus/TrackerMenu";
import { FlagConfirmModal } from "../components/modals/Flags/FlagConfirmModal";
import { SendMessageCustomModal } from "../components/modals/Flags/SendMessageCustomModal";
import { SendMessageOneSelectCarModal } from "../components/modals/Flags/SendMessageOneSelectCarModal";
import { SendSectorFlagModal } from "../components/modals/Flags/SendSectorFlagModal";
import { SnapshotModal } from "../components/modals/SnapshotModal";
import useDraw from "../hooks/useDraw";
import useDriver from "../hooks/useDriver";
import useEvent from "../hooks/useEvent";
import useFlag from "../hooks/useFlag";
import useTracker from "../hooks/useTracker";
import { EventService } from "../service/EventService";

import { CarBox } from "../components/CarBox";
import { CategoryBox } from "../components/CategoryBox";
import { ShortMessageBox } from "../components/ShortMessageBox";
import { AlertMessageBox } from "../components/modals/AlertMessageBox";
import { ALERT_TYPES } from "../consts/alertTypes";
import { Utils } from "../utilities/Utils";
import { OfflineDrivers } from "./OfflineDrivers";

import { ConfirmResetLapsDialog } from "../dialogs/ConfirmResetLapsDialog";
import { Timers } from "./Timers";

const LIBRARIES = ["drawing", "geometry"];
const google = (window.google = window.google ? window.google : {});
const HIDE_TEXT = "Hide POIs";
const DEFAULT_ZOOM = parseInt(process.env.REACT_APP_DEFAULT_ZOOM);
const DEFAULT_MAP_POSITION = {
  lat: parseFloat(process.env.REACT_APP_DEFAULT_LAT),
  lng: parseFloat(process.env.REACT_APP_DEFAULT_LNG),
};
const googleMapOptions = {
  scrollwheel: true,
  scaleControl: false,
  mapTypeControl: false,
  draggable: true,
  zoomControl: true,
  disableDefaultUI: true,
  mapId: "42e6b7328c70c8d1",
  mapTypeId: "satellite",
};

const drawingOptions = {
  drawingControl: false,
  polylineOptions: {
    editable: false,
    draggable: false,
    geodesic: false,
    strokeColor: "#d6ff22",
    clickable: false,
  },
};
export const DriverTracker = (props) => {
  let format = Utils.getFormDate();
  const eventId = props.match.params.id;

  const [zoom, setZoom] = useState(DEFAULT_ZOOM);
  const [centerPositions, setCenterPositions] = useState(DEFAULT_MAP_POSITION);
  const [drawingMode, setDrawingMode] = useState(null);
  const [trackId, setTrackId] = useState(null);

  const [modalCustomMessage, setModalCustomMessage] = useState(false);
  const [drivesBox, setDriversBox] = useState([]);

  ///POI
  const [poiTags, setPoiTags] = useState([]);
  const [polylineTags, setPolylineTags] = useState([]);
  const [flagTags, setFlagTags] = useState([]);
  const [infoWindowsTags, setInfoWindowsTags] = useState([]);

  //Category box
  const [categoryBoxCollapsed, setCategoryBoxCollapsed] = useState(true);
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [carBoxCollapsed, setCarBoxCollapsed] = useState(false);

  const [offlineCollapsed, setOfflineCollapsed] = useState(true);
  const [timerCollapsed, setTimerCollapsed] = useState(true);
  const [offlineCars, setOfflineCars] = useState([]);
  const [carsTotal, setCarsTotal] = useState({ total: 0, offlineCars: 0 });


  const [isDirector, setIsDirector] = useState(false);

  const currentCategoriesRef = useRef([]);
  const toast = useRef(null);
  const drivesRef = useRef([]);
  const blockedDrivesRef = useRef([]);
  const shapesRef = useRef(null);

  const poisRef = useRef([]);

  const [flagCode, setFlagCode] = useState("");
  const [flagMessage, setFlagMessage] = useState("");
  const [flagDestination, setFlagDestination] = useState(null);
  const [showTrackerMenu, setShowTrackerMenu] = useState(true);

  const [showSendMessageMenu, setShowSendMessageMenu] = useState(false);
  const [alertMessageBox, setAlertMessageBox] = useState("");

  const [modalOneSelectCar, setModalOneSelectCar] = useState(false);
  const [askMessageConfirmation, setAskMessageConfirmation] = useState(null);
  const [modalConfirmation, setModalConfirmation] = useState(false);
  const [modalSelectSector, setModalSelectSector] = useState(false);

  const [modalSnapshot, setModalSnapshot] = useState(false);
  const [poiHideShowText, setPoiHideShowText] = useState(HIDE_TEXT);

  const [showMessageBox, setShowMessageBox] = useState({
    display: false,
    message: "",
    seconds: 0,
    class: "",
  });


  const handleSendFlag = async (code) => {
    setFlagCode(code);
    sendFlag(code, drivesRef.current, setShowMessageBox, safetyCarDelay);
  };

  const { initializeAll, getOfflineCars } = useTracker({ eventId });
  const {
    setMap,
    buildTrack,
    drawDrivers,
    updateDriverPosition,
    hideDriversByCategories,
    showDriversByCategories,
    changeOfflineState,
    getMap,
    setDriverAlert,
    updateDriverCounter,
    togglePois,
    toggleAPoiWindow,
  } = useDraw({
    eventId,
    cleckAPoiWindow: (poi, isOpen) => cleckAPoiWindow(poi, isOpen),

  });

  const { startShowOfflineCars } = useEvent(
    eventId,
    (position) => {
      changePosition(position);
    },
    (alert) => {
      processAlert(alert);
    },
    (counter) => {
      updateCounter(counter);
    },
    () => null
  );
  const { checkIsDirector, getInfoDriver } = useDriver({ eventId });

  const [resetLapsDialog, setResetLapsDialog] = useState(false);


  const { sendFlag, sendMessage, clearSOSFlag, lockedColorDriversRef } = useFlag({
    eventId,
    setFlagMessage,
    setFlagDestination,
    setModalCustomMessage,
    setAlertMessageBox,
    takePictiure: () => takePictiure(),
    showToast: (message, driver, type, life) =>
      showToast(message, driver, type, life),
    setModalOneSelectCar,
    setAskMessageConfirmation,
    setModalConfirmation,
    setShowSendMessageMenu,
    setModalSelectSector,
  });

  useEffect(() => {
    setIsDirector(checkIsDirector());
  }, []);

  const changePosition = async (position) => {
    if (blockedDrivesRef.current.includes(position.userId)) return;

    blockedDrivesRef.current.push(position.userId);

    let existCat = currentCategoriesRef.current.includes(
      parseInt(position?.categoryId)
    );

    if (existCat) {
      let newDriver = await updateDriverPosition(position, drivesRef.current);

      if (newDriver) addNewDriver(newDriver);
      updateDriverBox();
    }

    blockedDrivesRef.current = blockedDrivesRef.current.filter(
      (id) => id != position.userId
    );
  };

  const processAlert = async (alert) => {
    let date = moment(new Date(alert.date))
    .local()
    .format(`${format} HH:mm:ss`);
    const driverInfo = await getInfoDriver(alert.driver.User.Id, alert.categoryId);
    let driver = `${alert.driver.User.Name} ${alert.driver.User.Surname}  ${date}`;
    var playAlarm = false;
    let carAlertInfo = "";

    if(driverInfo){
      carAlertInfo = `- ${driverInfo.Car} (${driverInfo.CarNumber})`
    }

    if (alert.alertType == ALERT_TYPES.SOS) {
      showToast(`${driver}`, `S.O.S Alert ${carAlertInfo}`,  "error");
      playAlarm = true;
    }

    if (alert.alertType != ALERT_TYPES.REDFLAGACK) {
      await setDriverAlert(alert, drivesRef.current);
    }

    if (playAlarm) {
      var aud = new Audio(audio);
      aud.play();
    }
  };

  const updateCounter = async (counter) => {
    await updateDriverCounter(counter, drivesRef.current);
  };

  const addNewDriver = (newDriver) => {
    drivesRef.current = [...drivesRef.current, newDriver];
    setDriversBox(drivesRef.current);
  };

  const updateDriverBox = () => {
    setDriversBox([...drivesRef.current]);
  };

  const filterDriversByCategory = async (_selectedCategories) => {
    hideDriversByCategories(drivesRef.current, _selectedCategories);
    showDriversByCategories(drivesRef.current, _selectedCategories);
    updateSelectedCategoryIds(_selectedCategories);
  };

  const [safetyCarDelay, setSafetyCarDelay] = useState(0);

  const handleLoad = React.useCallback(async function callback(newMap) {
    setMap(newMap);
    const {
      drivers,
      mapData,
      trackId,
      scDelay,
      mapPosition,
      pois,
      shapes,
      newCategories,
    } = await initializeAll(showToast);

    setMapParameters({ mapData, trackId, mapPosition, extraPois: pois });
    shapesRef.current = shapes;
    const { newDrivers } = await drawDrivers(drivers, drivesRef.current);
    addNewDrivers(newDrivers);
    setNewCategories(newCategories);
    setSafetyCarDelay(scDelay);

    startShowOfflineCars(updateOfflineCars);
  }, []);

  const updateOfflineCars = async () => {
    if (currentCategoriesRef.current.length < 1) {
      setOfflineCars([]);
      setCarsTotal({ total: 0, offlineCars: 0 });
      return;
    }
    let { total, offlineDrivers } = await getOfflineCars(
      currentCategoriesRef.current
    );
    await changeOfflineState(drivesRef.current, offlineDrivers);

    setOfflineCars(offlineDrivers);
    setCarsTotal({
      total,
      offlineCars: offlineDrivers.length,
    });
  };

  const addNewDrivers = (drivers) => {
    let boxDrivers = [];
    drivers.forEach((driver) => {
      drivesRef.current.push(driver);
      boxDrivers.push(driver);
    });
    setDriversBox(boxDrivers);
  };

  const updateSelectedCategoryIds = (categoryIds) => {
    currentCategoriesRef.current = categoryIds;
    setSelectedCategories(categoryIds);
  };

  const setNewCategories = (newCategories) => {
    let selectedCategoriesIds = newCategories.map((category) =>
      parseInt(category.Id)
    );
    currentCategoriesRef.current = selectedCategoriesIds;
    setSelectedCategories(selectedCategoriesIds);
    setCategories(newCategories);
  };

  const setMapParameters = ({ mapData, trackId, mapPosition, extraPois }) => {
    const { newPoiTags, polylineTags } = buildTrack(
      mapData,
      poiTags,
      extraPois
    );
    setPoiTags(newPoiTags);
    poisRef.current = {
      newPoiTags,
      extraPois,
    };
    setPolylineTags(polylineTags);
    setTrackId(trackId);
    setMapLocation({ mapPosition });
  };

  const setMapLocation = (data) => {
    let zoom = data.mapPosition.zoom || DEFAULT_ZOOM;
    let mapPosition = DEFAULT_MAP_POSITION;
    if (data.mapPosition.lat) {
      mapPosition = {
        lat: parseFloat(data.mapPosition.lat),
        lng: parseFloat(data.mapPosition.long),
      };
    }
    setCenterPositions(mapPosition);
    setZoom(zoom);
  };

  const showToast = (message, driver, type, life) => {
    toast.current.show({
      severity: type,
      summary: driver,
      detail: message,
      life: life || 100000000,
    });
  };

  const backTrackerMenu = async () => {
    return props.history.push("/events");
  };

  const takePictiure = async () => {
    setModalSnapshot(true);
  };

  const hidePois = async () => {
    const { newPoiTags, poiBtnText, extraPois } = await togglePois(
      [...poiTags],
      showToast
    );
    setPoiHideShowText(poiBtnText);
    setPoiTags(newPoiTags);
    poisRef.current = {
      newPoiTags,
      extraPois,
    };
  };

  const clearFlags = async () => {
    await clearSOSFlag(drivesRef.current);
  };

  const cleckAPoiWindow = async (poi, isOpen) => {
    const { newPoiTags, extraPois } = await toggleAPoiWindow(
      poi,
      poisRef.current,
      isOpen
    );
    setPoiTags(newPoiTags);
    poisRef.current = {
      newPoiTags,
      extraPois,
    };
  };

  const resetLaps = async () => {
    const eventService = new EventService();
    await eventService.resetLaps(eventId);
    showToast("Lap counter reseted", "Succeful", "success");
  };


  return (
    <>
      <TrackerMenu
        hidePois={() => hidePois()}
        poiHideShowText={poiHideShowText}
        sendFlag={(code) => {
          handleSendFlag(code);
        }}
        showMenu={showSendMessageMenu}
        isDirector={isDirector}
        showTrackerMenu={showTrackerMenu}
        back={() => backTrackerMenu()}
        clearSelectedDrivers={() => { }}
        gmt={null}
        cleanFlags={clearFlags}
        resetLaps={() => setResetLapsDialog(true)}
      />
      <div>
        <CustomGoogleMap
          googleMapId={"drawing-manager-example"}
          libraries={LIBRARIES}
          zoom={zoom}
          googleOptions={googleMapOptions}
          centerPositions={centerPositions}
          drawingOptions={drawingOptions}
          drawingMode={drawingMode}
          poiTags={[...poiTags]}
          polylineTags={polylineTags}
          flagTags={flagTags}
          onLoad={handleLoad}
          infoWindowsTags={infoWindowsTags}
          onZoomChanged={() => { }}
        />
        <div className="container-boxes">
          <Timers timerCollapsed={timerCollapsed} setTimerCollapsed={setTimerCollapsed} />
          <CategoryBox
            setOfflineCollapsed={setCategoryBoxCollapsed}
            categoryBoxCollapsed={categoryBoxCollapsed}
            categories={categories}
            filterByCategory={filterDriversByCategory}
          ></CategoryBox>
          <CarBox
            setCarBoxCollapsed={setCarBoxCollapsed}
            carBoxCollapsed={carBoxCollapsed}
            driverPositions={drivesBox}
            categories={selectedCategories}
            lockedColors={lockedColorDriversRef.current}
          ></CarBox>
          <OfflineDrivers
            offlineCollapsed={offlineCollapsed}
            setOfflineCollapsed={setOfflineCollapsed}
            offlineCars={offlineCars}
            carsTotal={carsTotal}
          ></OfflineDrivers>

          {showMessageBox.display && (
            <ShortMessageBox
              message={showMessageBox.message}
              seconds={showMessageBox.seconds}
              className={showMessageBox.class}
            ></ShortMessageBox>
          )}
        </div>
      </div>
      {modalCustomMessage && (
        <SendMessageCustomModal
          //flagCode={flagCode}
          drivers={drivesBox}
          setModalState={setModalCustomMessage}
          sendMessage={(message, _selectedDrivers) => {
            sendMessage(
              flagCode,
              flagMessage + message,
              0,
              flagDestination,
              _selectedDrivers,
              drivesRef.current
            );
          }}
        />
      )}

      {modalOneSelectCar && (
        <SendMessageOneSelectCarModal
          title={flagMessage}
          drivers={drivesBox}
          setModalState={setModalOneSelectCar}
          sendMessage={(_selectedDrivers) => {
            sendMessage(
              flagCode,
              flagMessage,
              0,
              flagDestination,
              _selectedDrivers,
              drivesRef.current
            );
          }}
        />
      )}

      <FlagConfirmModal
        flagCode={flagCode}
        flagMessage={flagMessage}
        askMessageConfirmation={askMessageConfirmation}
        modalState={modalConfirmation}
        setModalState={setModalConfirmation}
        sendMessage={() =>
          sendMessage(
            flagCode,
            flagMessage,
            0,
            flagDestination,
            [],
            drivesRef.current
          )
        }
      />

      {modalSelectSector && (
        <SendSectorFlagModal
          trackId={trackId}
          title={flagMessage}
          showToast={(toastMessage) => showToast(toastMessage, "", "warn")}
          flagCode={flagCode}
          modalState={modalSelectSector}
          setModalState={setModalSelectSector}
          sendMessage={(sectorId) =>
            sendMessage(
              flagCode,
              flagMessage,
              sectorId,
              flagDestination,
              [],
              drivesRef.current
            )
          }
        />
      )}
      <div>
        <SnapshotModal
          modalState={modalSnapshot}
          setModalSnapshot={setModalSnapshot}
          centerPositions={centerPositions}
          poiTags={[...poiTags]}
          polylineTags={[...polylineTags]}
          infoWindowsTags={infoWindowsTags}
          zoom={zoom}
          getShapes={() => {
            return shapesRef.current;
          }}
          driverPositions={drivesBox}
          eventId={eventId}
          categories={selectedCategories}
        />
      </div>

      <Toast ref={toast} position="bottom-right" />
      <AlertMessageBox
        setAlertMessageBox={setAlertMessageBox}
        alertMessageBox={alertMessageBox}
      ></AlertMessageBox>
      <ConfirmResetLapsDialog
        visible={resetLapsDialog}
        hideDialog={() => setResetLapsDialog(false)}
        reset={resetLaps}
      ></ConfirmResetLapsDialog>
    </>
  );
};
