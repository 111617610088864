import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import React, { useEffect, createRef, useState } from "react";

import { SectorsService } from "../../../service/SectorsService";

var messageRef = createRef();

export const SendSectorFlagModal = (props) => {

  const apiUrl = process.env.REACT_APP_API_URL;

  const [sectors, setSectors] = useState(null);
  const [sectorId, setSectorId] = useState(null);

  useEffect(() => {
    try {
      const load = async () => {
        const sectorsService = new SectorsService();
        const res = await sectorsService.getAllByTrack(props.trackId);
        setSectors(res);
      };
      load();
    } catch (error) {
      console.log("Club Error");
    }
  }, []);

  var footer = (
    <div>
      <Button
        label="Cancel"
        className="btn-secondary"
        onClick={() => props.setModalState(false)}
      />
      <Button
        label="Send"
        className="btn-primary "
        onClick={(e) => sendMessage()}
      />
    </div>
  );

  const sendMessage = () => {
    if (sectorId) {
      props.sendMessage(sectorId);
      props.setModalState(false);
    } else {
      props.showToast("Must be select a sector");
    }
  };

  const setSectorValue = (ev) => {
    setSectorId(ev.target.value);
  };

  const sectorOptionTemplate = (option) => {
    return (
      <div>
          <span>{option.secNumber}</span>
          <img style={{width:"60px", marginLeft:10 }} width={50} alt={option.secNumber} src={`${apiUrl}/${option.secPicpath}`} />
      </div>
    );
  }

  return (
    <Dialog
      header={props.title}
      className="p-fluid"
      visible={props.modalState}
      contentClassName="overflow-y-visible"
      footer={footer}
      onHide={() => props.setModalState(false)}
    >
      <div className="p-grid">
        <div className="p-field p-col">
          {/* <label htmlFor="track">Track</label> */}
          <Dropdown
            id="track"
            value={sectorId}
            placeholder="select sector"
            optionLabel="secNumber"
            optionValue="secId"
            options={sectors}
            onChange={setSectorValue}
            itemTemplate={sectorOptionTemplate}
            //required className={classNames({ "p-invalid": submitted && !item.traId })}
          />
        </div>

        {/* <div className="p-field p-col-12">
          <input
            type="text"
            id="sectorId"
            placeholder="Input sectorId ( por ahora )"
            ref={messageRef}
            maxLength={10}
            defaultValue={""}
            className="p-inputtext p-component"
          />
        </div> */}
      </div>
    </Dialog>
  );
};
