import React, { useState, useEffect, useRef } from "react";

import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";

import { TokenAuthService } from "../service/TokenAuthService";

import { HeaderPageDetail } from "../components/headers/HeaderPageDetail";

import {
  ErrorDialog,
  ErrorDialogEmpty,
  ErrorDialogSet,
  ErrorDialogNoAuthorizedSet,
  ErrorDialogSetResponse,
} from "../dialogs/ErrorDialog";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { UserService } from "../service/UserService";

import { NamePersonalEditDialog } from "../dialogs/personalEditDialog/namePersonalEditDialog.js";
import { EmailPersonalEditDialog } from "../dialogs/personalEditDialog/emailPersonalEditDialog.js";
import { PasswordPersonalEditDialog } from "../dialogs/personalEditDialog/passwordPersonalEditDialog.js";
import { type_roles } from "../utilities/const";

const apiUrl = process.env.REACT_APP_API_URL;

export const PersonalDetails = () => {
  let emptyUser = {
    Id: null,
    FirstName: "",
    Lastame: "",
    Email: "",
    Phone: "",
    RolesId: [],
  };

  const [spinning, setSpinning] = useState(false);
  const [stateErrorDialog, setStateErrorDialog] = useState(ErrorDialogEmpty);
  const [loading, setLoading] = useState(true);

  const [user, setUser] = useState(emptyUser);
  const [roles, setRoles] = useState("");
  const [visibleNameDialog, setVisibleNameDialog] = useState(false);
  const [visibleEmailDialog, setVisibleEmailDialog] = useState(false);
  const [visiblePasswordDialog, setVisiblePasswordDialog] = useState(false);
  const [avatarPath, setAvatarPath] = useState();

  const toast = useRef(null);
  const params = useParams();
  const history = useHistory();
  const fileInputRef = useRef();

  // Se ejecuta luego del render
  useEffect(() => {
    var res = null;
    try {
      const load = async () => {
        setLoading(true);

        const auth = new TokenAuthService();
        const _cookieeUser = auth.getUser();
        setRoles(type_roles[_cookieeUser.roles[0].name]);

        const userService = new UserService();
        const _user = await userService.get(_cookieeUser.id);

        if (_user) {
          setUser(_user);

          setAvatarPath(_user.useAvatar);
        }

        setLoading(false);
      };
      load();
    } catch (error) {
      setStateErrorDialog(ErrorDialogNoAuthorizedSet(true, error));
    }
  }, []);

  if (loading)
    return (
      <div className="loading-container">
        <h3>Loading...</h3>
      </div>
    );

  const showNameDialog = () => {
    setVisibleNameDialog(true);
  };
  const hideNameDialog = (_user) => {
    if (_user) {
      setUser(_user);
      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "Personal details updated",
        life: 3000,
      });
    }
    setVisibleNameDialog(false);
  };

  const showEmailDialog = () => {
    setVisibleEmailDialog(true);
  };
  const hideEmailDialog = (_user) => {
    if (_user) {
      setUser(_user);
      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "Personal details updated",
        life: 3000,
      });
    }
    setVisibleEmailDialog(false);
  };

  const showPasswordDialog = () => {
    setVisiblePasswordDialog(true);
  };
  const hidePasswordDialog = (_user) => {
    if (_user) {
      setUser(_user);
      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "Personal details updated",
        life: 3000,
      });
    }
    setVisiblePasswordDialog(false);
  };

  const leftColumnTemplate = () => {
    // return <div className="p-m-0 p-col-4">BACK</div>;
    return (
      <div className="p-m-0 p-col-4">
        <Button
          label="Back"
          icon="pi pi-chevron-left"
          onClick={() => history.goBack()}
          className="p-button-text btn-back "
        />
      </div>
    );
  };

  const rightColumnTemplate = () => {
    return <div className="p-m-0 p-col-8 p-d-flex p-jc-end"></div>;
  };
  const fullColumnTemplate = () => {
    return (
      <>
        <h1 className="p-m-0 p-col-12 fs-x-big">Personal Details</h1>
        <h5 className="p-m-0 p-col-12 fs-x-big">
          {user.useName + " " + user.useSurname}
        </h5>
      </>
    );
  };

  const handleChange = async (e) => {
    try {
      if (!spinning) {
        const userService = new UserService();
        setSpinning(true);
        let filename = await userService.uploadAvatar(
          e.target.files[0],
          user.useId
        );
        setAvatarPath(filename.data.fileName);
        const auth = new TokenAuthService();
        auth.updateAvatar(filename.data.fileName);
        setSpinning(false);
        window.location.reload();
      }
    } catch (error) {
      setSpinning(false);
      toast.current.show({
        severity: "error",
        detail: "An error has occurred trying to get the driver position.",
        life: 3000,
      });
    }
  };

  return (
    <div className="p-grid ">
      <div className="p-col-12">
        <div className="inner-container">
          <Toast ref={toast} />
          <HeaderPageDetail
            headerStyles="p-mb-5 header-club-detail p-ai-start"
            leftColumn={leftColumnTemplate()}
            rightColumn={rightColumnTemplate()}
            fullColumn={fullColumnTemplate()}
          />

          <div className=" p-mb-3 p-d-flex p-flex-column personal-detail-card ">
            {avatarPath === null && (
              <div
                className="img-profile"
                onClick={() => fileInputRef.current.click()}
              >
                {user.useName[0].toUpperCase() +
                  user.useSurname[0].toUpperCase()}
              </div>
            )}
            {spinning && (
              <ul>
                <i
                  className="pi pi-spin pi-spinner"
                  style={{ fontSize: "2rem" }}
                ></i>
              </ul>
            )}

            {avatarPath !== null && (
              <div className="img-profile">
                <img
                  src={apiUrl + "/" + avatarPath}
                  onClick={() => fileInputRef.current.click()}
                  width="100%"
                  height="100%"
                ></img>
              </div>
            )}

            <input
              type="file"
              onChange={handleChange}
              ref={fileInputRef}
              type="file"
              hidden
            />

            <div className="p-grid">
              <div className="p-field p-col-12">
                <p className="fs-small txt-clrLight250">ROLE</p>
                <h2 className="p-m-0 fs-x-normal">{roles}</h2>
              </div>
            </div>

            <div className="p-grid">
              <div className="p-field p-col-5">
                <p className="fs-small txt-clrLight250">FIRST NAME</p>
                <h2 className="p-m-0 fs-x-normal">{user.useName}</h2>
              </div>
              <div className="p-field p-col-5">
                <p className="fs-small txt-clrLight250">LAST NAME</p>
                <h2 className="p-m-0 fs-x-normal">{user.useSurname}</h2>
              </div>
              <div className="p-field p-col-2 p-d-flex p-ai-end p-jc-end p-0">
                <Button
                  label=""
                  icon="pi pi-fw pi-pencil"
                  className="p-button-icon-only"
                  onClick={showNameDialog}
                />
              </div>
            </div>

            <div className="p-grid">
              <div className="p-field p-col-10">
                <p className="fs-small txt-clrLight250">EMAIL</p>
                <h2 className="p-m-0  fs-x-normal">{user.useEmail}</h2>
              </div>
              <div className="p-field p-col-2 p-d-flex p-ai-end p-jc-end p-0">
                <Button
                  label=""
                  icon="pi pi-fw pi-pencil"
                  className="p-button-icon-only"
                  onClick={showEmailDialog}
                />
              </div>
            </div>

            <div className="p-grid">
              <div className="p-field p-col-10">
                <p className="fs-small txt-clrLight250">PASSWORD</p>
                <h2 className="p-m-0  fs-x-normal">********</h2>
              </div>
              <div className="p-field p-col-2 p-d-flex p-ai-end p-jc-end p-0">
                <Button
                  label=""
                  icon="pi pi-fw pi-pencil"
                  className="p-button-icon-only"
                  onClick={showPasswordDialog}
                />
              </div>
            </div>
          </div>

          <NamePersonalEditDialog
            visibleDialog={visibleNameDialog}
            onClose={hideNameDialog}
            user={user}
          ></NamePersonalEditDialog>
          <EmailPersonalEditDialog
            visibleDialog={visibleEmailDialog}
            onClose={hideEmailDialog}
            user={user}
          ></EmailPersonalEditDialog>
          <PasswordPersonalEditDialog
            visibleDialog={visiblePasswordDialog}
            onClose={hidePasswordDialog}
            user={user}
          ></PasswordPersonalEditDialog>

          <ErrorDialog stateDialog={stateErrorDialog}></ErrorDialog>
        </div>
      </div>
    </div>
  );
};
