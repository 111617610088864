import axios from "../utilities/api-axios";

const apiUrl = process.env.REACT_APP_API_URL;

export class CircuitsService {
  getById(id) {
    return axios.get(`${apiUrl}/circuits/` + id).then((res) => res.data);
  }

  async getAll() {
    return axios.get(`${apiUrl}/circuits/`).then((res) => res.data);
  }

  updateCircuit(circuits) {
    const updItem = {
      Name: circuits.cirName,
      Visibility: circuits.cirVisibility,
      CouId: circuits.coudId,
      State: circuits.cirState,
      City: circuits.cirCity,
      Lat: circuits.cirLat,
      Long: circuits.cirLong,
    };
    return axios
      .put(`${apiUrl}/circuits/` + circuits.cirId, updItem)
      .then((res) => res.data);
  }

  createCircuit(circuits) {
    const newCircuits = {
      Name: circuits.cirName,
      Visibility: circuits.Visibility,
      CouId: circuits.coudId,
      State: circuits.cirState,
      City: circuits.cirCity,
      Lat: circuits.cirLat,
      Long: circuits.cirLong,
    };
    return axios.post(`${apiUrl}/circuits/`, newCircuits).then((res) => {
      return res.data;
    });
  }

  deleteCircuits(id) {
    return axios
      .delete(`${apiUrl}/circuits/` + id, { _method: "DELETE" })
      .then((res) => {
        return res.data;
      });
  }
}
