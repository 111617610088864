import React from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import {
  ErrorDialog,
  ErrorDialogEmpty,
  ErrorDialogNoAuthorizedSet,
  ErrorDialogSet,
  ErrorDialogSetResponse,
} from "../dialogs/ErrorDialog";

export const ConfirmResetLapsDialog = (props) => {
  const hideDialog = () => {
    props.hideDialog();
  };

  const reset = () => {
    hideDialog();
    props.reset();
  };

  const dialogFooter = (
    <>
      <Button label="Cancel" className="btn-secondary" onClick={hideDialog} />
      <Button label="Reset" className="btn-primary" onClick={reset} />
    </>
  );

  return (
    <Dialog
      header="Lap counter reset"
      visible={props.visible}
      className="p-dialog-small"
      modal
      footer={dialogFooter}
      onHide={hideDialog}
    >
      <div className="confirmation-content">
        <span>
          Are you sure you want to reset the lap counter for this event?
        </span>
      </div>
    </Dialog>
  );
};
