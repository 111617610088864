import { useRef, useState } from "react";
import { EventService } from "../service/EventService";
import { TracksService } from "../service/TracksService";
import { MapService } from "../service/MapService";
import { TokenAuthService } from "../service/TokenAuthService";

const useDriver = ({eventId}) =>{
    const mapService = new MapService();
    let tokenAuthService = new TokenAuthService();

    const driverTrakerByCategories = async (selectedCategories) => {
        try {
            var positions = [];
            var driverPositions = await mapService.getLastDriverPositionsByEventId(eventId,selectedCategories);

            driverPositions.forEach(async (item) => {
                positions.push({
                  invId: item.InvitationId,
                  userId: item.UserId,
                  categoryId: item.CategoryId,
                  category: item.Category,
        
                  name: item.Name,
                  surname: item.Surname,
        
                  carnumber: item.CarNumber,
                  counter: item.Counter || 0,
                  positionId: item.PositionId,
                  position: {
                    lat: parseFloat(item.Lat),
                    lng: parseFloat(item.Lng),
                    speed: item.Speed,
                  },
        
                  offline: false,
                  lastAlertType: item.LastAlertType,
                  lastFlagStatus: item.LastFlagStatus,
                  //driverAlertIds: item.driverAlertIds,
        
                  oldPosition: null,
                  lastUpdate: item.lastUpdate,
                  stoped: false,
                  icon: null,
                  animation: null,
                  adddate:item.Adddate
                });
            });

              

        } catch (error) {
            
        }
        return positions;
    }

    const getInfoDriver = async (userId, categoryId) => {
        try {
            var driver = await mapService.getInfoDriver(
              eventId,
              userId,
              categoryId
            );
            return driver[0];
          } catch (error) {
            console.log(error);
            console.log("An error has occurred trying to get the driver position.");
          }
    }

    const checkIsDirector = () => {
      return tokenAuthService.isDirector();
    }
    

    return {
        driverTrakerByCategories,
        getInfoDriver,
        checkIsDirector
    };
}

export default useDriver;