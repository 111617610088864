import React, { createRef, useRef, useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import classNames from "classnames";

export const SendMessageOneSelectCarModal = (props) => {
  const [selectedInvitation, setSelectedInvitation] = useState(null);
  const [submitted, setSubmitted] = useState(false);

  const [currentDrivers, setCurrentDrivers] = useState([]);

  useEffect(() => {
    refreshCurrentDrivers();
  },[props.drivers]);

  const refreshCurrentDrivers = () => {
    let _drivers = props.drivers.map((d) => {
      return {
        label: d.driverInfo.CarNumber,
        value: d.driverInfo.invId,
        categoryId: d.categoryId,
        userId: d.userId,
      };
    });
    setCurrentDrivers(_drivers);
  };

  var footer = (
    <div>
      <Button
        label="Cancel"
        className="btn-secondary"
        onClick={() => props.setModalState(false)}
      />
      <Button
        label="Send"
        className="btn-primary "
        onClick={(e) => sendMessage()}
      />
    </div>
  );

  const sendMessage = () => {
    setSubmitted(true);
    if (!selectedInvitation) {
      return;
    }

    props.sendMessage([selectedInvitation]);
    props.setModalState(false);
  };

  const close = () => {
    props.setModalState(false);
  };

  return (
    <Dialog
      header={props.title}
      className="p-fluid"
      visible={true}
      contentClassName="overflow-y-visible"
      footer={footer}
      onHide={() => close()}
    >
      <div className="p-grid">
        <div className="p-field p-col-12">
          <Dropdown
            placeholder="select car"
            value={selectedInvitation}
            options={currentDrivers}
            onChange={(e) => setSelectedInvitation(e.value)}
            className={classNames({
              "p-invalid": submitted && !selectedInvitation,
            })}
          />
        </div>
        {submitted && !selectedInvitation && (
          <small className="p-invalid">You must choose a driver.</small>
        )}
      </div>
    </Dialog>
  );
};
