import axios from "../utilities/api-axios";
import { TokenAuthService } from "../service/TokenAuthService";

const apiUrl = process.env.REACT_APP_API_URL;

export class DriverService {
  async getDrivers() {
    const res = await axios.get(`${apiUrl}/drivers/WithUser/`);

    return res.data;
  }

  async uploadDrivers(file) {
    let formData = new FormData();
    formData.append("DataWithHeader", "true");
    formData.append("file", file);

    return await axios.post(
      `${apiUrl}/commonServices/uploadDrivers/`,
      formData
    );
  }

  async updloadInvitations(file, eventId) {
    let formData = new FormData();
    formData.append("DataWithHeader", "true");
    formData.append("EventId", eventId);
    formData.append("file", file);

    return await axios.post(
      `${apiUrl}/commonServices/uploadInvitations/`,
      formData
    );
  }

  updateDriver(driver) {
    return axios
      .put(`${apiUrl}/drivers/WithUser/${driver.Id}`, driver)
      .then((res) => {
        return res.data;
      });
  }

  createDriver(driver) {
    var request = {
      DriverId: driver.driId,
      UserId: driver.useId,
      RolUseId: driver.rolUseId,
      Email: driver.Email,
      FirstName: driver.FirstName,
      LastName: driver.LastName,
      Phone: driver.Phone,
      GrupoSanguineo: driver.DriverGrupoSanguineo,
      Invitation: {
        EveId: driver.eveId,
        Number: driver.Number,
        Brand: driver.Brand,
        Model: driver.Model,
        CatId: driver.CategoryId,
        CodriverName: driver.CodriverName,
        CodriverSurname: driver.CodriverSurname,
        CodriverGrupoSang: driver.CodriverGrupoSanguineo,
      },
    };

    return axios
      .post(`${apiUrl}/drivers/WithUserAndInvitation/`, request)
      .then((res) => {
        return res.data;
      });
  }

  deleteDriver(id) {
    return axios
      .delete(`${apiUrl}/drivers/` + id, { _method: "DELETE" })
      .then((res) => {
        return res.data;
      });
  }

  //no se usa
  deleteSelectedDrivers(drivers) {
    const driversId = [];

    for (var key in drivers) driversId.push(drivers[key].Id);

    return axios
      .delete(`${apiUrl}/drivers/selected/`, driversId)
      .then((res) => {
        return res.data;
      });
  }

  getDriver(id) {
    return axios.get(`${apiUrl}/drivers/`, id).then((res) => res.data);
  }

  getDriverByMail(email) {
    return axios
      .get(`${apiUrl}/drivers/byEmail/` + email)
      .then((res) => res.data);
  }

  getDriverByMailClub(email, club) {
    return axios
      .get(`${apiUrl}/drivers/byEmailClub/` + email + "/" + club)
      .then((res) => res.data);
  }

  getAllDrivers() {
    return axios.get(`${apiUrl}/drivers/club`).then((res) => res.data);
  }

  getAllDriversByClub(clubId) {
    return axios
      .get(`${apiUrl}/drivers/driversByClub/` + clubId)
      .then((res) => res.data);
  }
}
