import { Dialog } from "primereact/dialog";
//import Select from "react-select";
import Select, { components } from "react-select";
import { POI_VALUES } from "../../consts/icons";

export const NewPoiModal = (props) => {
  const options = [
    { value: POI_VALUES.LOCATION, label: "Common", icon: "icon-location-on" },
    {
      value: POI_VALUES.AMBULANCIA,
      label: "ambulance",
      icon: "icon-ambulance",
    },
    {
      value: POI_VALUES.BANDERILLERO,
      label: "race official",
      icon: "icon-banderillero",
    },
    {
      value: POI_VALUES.BOMBEROAPIE,
      label: "fireman",
      icon: "icon-firefighter",
    },
    {
      value: POI_VALUES.BOMBEROMOTORIZADO,
      label: "fire truck",
      icon: "icon-motofirefighter",
    },
    { value: POI_VALUES.RESCATE, label: "rescue", icon: "icon-rescue" },
    {
      value: POI_VALUES.SAFETYCAR,
      label: "safety Car",
      icon: "icon-safetycar",
    },
  ];

  const changeIcon = (value, poiDetails, setPoiDetails) => {
    setPoiDetails({ ...poiDetails, icon: value });
  };

  //Icons en Options
  const { Option } = components;
  const IconOption = (props) => (
    <Option {...props}>
      <i
        className={props.data.icon}
        style={{ marginRight: "10px", fontSize: "0.75rem" }}
      ></i>

      {props.data.label}
    </Option>
  );

  return (
    <Dialog
      header="POI details"
      className="p-fluid"
      contentClassName="overflow-y-visible"
      visible={props.poiModalState}
      footer={props.newPoi.poiFooter}
      onHide={() => props.setPoiModalState(false)}
    >
      <div className="p-grid" style={{ paddingBottom: 10 }}>
        <div className="p-field p-col-6">
          <label htmlFor="segment" className="p-d-block">
            POI Name
          </label>
          <input
            type="text"
            id="segment"
            className="p-inputtext p-component"
            placeholder="POI Name"
            defaultValue={props.poiDetails.description}
            ref={props.poiDetails.descriptionInput}
          />
        </div>

        <div className="p-field p-col-6">
          <label htmlFor="icon" className="p-d-block">
            Icon
          </label>

          <Select
            id="icon"
            options={options}
            value={options.find((x) => x.value == props.poiDetails.icon)}
            components={{ Option: IconOption }}
            onChange={(e) => {
              changeIcon(e.value, props.poiDetails, props.setPoiDetails);
            }}
            className={"pois-icons"}
          />
        </div>

        <div className="p-field p-col-6">
          <label htmlFor="longitude" className="p-d-block">
            Longitude
          </label>
          <input
            type="text"
            id="longitude"
            className="p-inputtext p-component"
            placeholder="Latitud"
            defaultValue={props.poiDetails.lat}
            ref={props.poiDetails.latInput}
          />
        </div>
        <div className="p-field p-col-6">
          <label htmlFor="latitude" className="p-d-block">
            Latitude
          </label>
          <input
            type="text"
            id="latitude"
            className="p-inputtext p-component"
            placeholder="Longitud"
            defaultValue={props.poiDetails.lng}
            ref={props.poiDetails.lngInput}
          />
        </div>
      </div>
    </Dialog>
  );
};
