import React, { useState, useEffect, useRef } from "react";

//Components
import { DeleteDialog } from "./DeleteDialog";
import { TracksDataTable } from "./TracksDataTable";
import { EditDialog } from "./EditDialog";

//Dialogs
import {
  ErrorDialog,
  ErrorDialogEmpty,
  ErrorDialogNoAuthorizedSet,
  ErrorDialogSet,
  ErrorDialogSetResponse,
} from "../../dialogs/ErrorDialog";

//Primereact
import { Toast } from "primereact/toast";

//Services
import { TracksService } from "../../service/TracksService";

const EMPTY_TRACKS = {
  traId: null,
  traName: "",
};

export const Tracks = () => {
  const [stateErrorDialog, setStateErrorDialog] = useState(ErrorDialogEmpty);
  const [loading, setLoading] = useState(true);

  const [track, setTrack] = useState(EMPTY_TRACKS);
  const [tracks, setTracks] = useState(null);

  const [editDialogVisible, setEditDialogVisible] = useState(false);
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);

  const toast = useRef(null);

  useEffect(() => {
    try {
      const load = async () => {
        setLoading(true);

        const tracks = new TracksService();
        const res = await tracks.getAll();

        setTrack(EMPTY_TRACKS);
        setTracks(res);

        setLoading(false);
      };
      load();
    } catch (error) {
      setStateErrorDialog(ErrorDialogNoAuthorizedSet(true, error));
      console.log(error);
    }
  }, []);
  if (loading)
    return (
      <div className="loading-container">
        <h3>Loading...</h3>
      </div>
    );

  const deleteItem = () => {
    const tracksService = new TracksService();
    return tracksService.deleteTrack(track.traId);
  };

  const findIndexById = (id, items) => {
    let index = -1;
    for (let i = 0; i < items.length; i++) {
      if (items[i].traId === id) {
        index = i;
        break;
      }
    }
    return index;
  };
  const changeItems = (item) => {
    let _tracks = [...tracks];
    let index = findIndexById(item.traId, _tracks);
    if (index > -1) {
      _tracks[index] = item;
      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "Track Updated",
        life: 3000,
      });
    } else {
      _tracks.push(item);
      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "Track Created",
        life: 3000,
      });
    }
    setTracks(_tracks);
  };
  const onDeleted = () => {
    let _items = tracks.filter((val) => val.traId !== track.traId);
    setTracks(_items);
    setTrack(EMPTY_TRACKS);
    toast.current.show({
      severity: "success",
      summary: "Successful",
      detail: "Track Deleted",
      life: 3000,
    });
  };
  return (
    <div className="p-grid crud-demo">
      <div className="p-col-12">
        <div className="card ">
          <Toast ref={toast} />

          <TracksDataTable
            items={tracks}
            emptyItem={EMPTY_TRACKS}
            openDialog={(row) => {
              setTrack({ ...row });
              setEditDialogVisible(true);
            }}
            openDeleteDialog={(rowData) => {
              setTrack(rowData);
              setDeleteDialogVisible(true);
            }}
          ></TracksDataTable>

          <EditDialog
            visible={editDialogVisible}
            item={track}
            onChange={changeItems}
            hideDialog={() => setEditDialogVisible(false)}
            setStateErrorDialog={setStateErrorDialog}
          />

          <DeleteDialog
            visible={deleteDialogVisible}
            item={{ id: track.traId, name: track.traName }}
            header="Delete track"
            hideDialog={() => setDeleteDialogVisible(false)}
            deleteItem={deleteItem}
            onDeleted={onDeleted}
            setStateErrorDialog={setStateErrorDialog}
          />

          <ErrorDialog stateDialog={stateErrorDialog}></ErrorDialog>
        </div>
      </div>
    </div>
  );
};
