import React, { createRef } from "react";
import { Marker, Polyline, Polygon, InfoWindow } from "@react-google-maps/api";
import { Button } from "primereact/button";
import { ConfirmMessageBox } from "../components/modals/ConfirmMessageBox.js";

export class Draws {
  setVisibleConfirmMessageBox;
  drawTrack;

  constructor(setVisibleConfirmMessageBox, drawTrack, setPendingChanges) {
    this.setVisibleConfirmMessageBox = setVisibleConfirmMessageBox;
    this.drawTrack = drawTrack;
    this.setPendingChanges = setPendingChanges;
  }

  polylineOptions = {
    strokeColor: "#d6ff22",
    strokeWeight: 4,
  };

  polygonOptions = {
    strokeColor: "#d6ff22",
    strokeWeight: 4,
    fillColor: "#3c79de",
    fillOpacity: "0.35",
  };

  drawingOverlayComplete(e) {
    if (this.shape) this.shape.setMap(null);

    var newShape = e.overlay;
    this.setShape(newShape);

    window.google.maps.event.addListener(newShape, "click", function (e) {
      if (e.vertex !== undefined) {
        var path = newShape.getPath();
        if (path.length > 2) {
          path.removeAt(e.vertex);
        }
      }
    });
  }

  drawPoint(poi, index, icon, ref, edit) {
    var position = {
      lat: parseFloat(poi.lat),
      lng: parseFloat(poi.lng),
    };

    return (
      <Marker
        icon={icon}
        position={position}
        clickable={edit}
        draggable={edit}
        key={index}
        ref={ref}
        onDblClick={() => {
          if (edit) this.setVisibleConfirmMessageBox(index + 1, true, null);
        }}
      ></Marker>
    );
  }

  drawPolyline2(positions, index, edit, ref) {
    var positionsFloat = positions.map((route) => {
      return { lat: parseFloat(route.lat), lng: parseFloat(route.lng) };
    });

    return (
      <Polyline
        ref={ref}
        options={this.polylineOptions}
        key={index}
        path={positionsFloat}
        geodesic={edit}
        editable={edit}
        draggable={edit}
        clickable={edit}
        onDblClick={(e) => {
          if (edit) {
            this.setVisibleConfirmMessageBox(1, true, e);
          }
        }}
      />
    );
  }

  removePoint(e, ref) {
    if (e.vertex !== undefined) {
      if (ref.current.props.path.length > 2) {
        ref.current.props.path.splice(e.vertex, 1);
        return true;
      }
    }
    return false;
  }

  drawPolygon2(positions, index, edit, ref) {
    var positionsFloat = positions.map((route) => {
      return { lat: parseFloat(route.lat), lng: parseFloat(route.lng) };
    });

    return (
      <Polygon
        ref={ref}
        options={this.polygonOptions}
        key={index}
        path={positionsFloat}
        geodesic={edit}
        editable={edit}
        draggable={edit}
        clickable={edit}
        onClick={(e) => {
          //this.showWindow(routes, route, true);
          if (edit) {
            this.setVisibleConfirmMessageBox(4, true, e);
          }
        }}
      />
    );
  }

  drawPois(pois, editable) {
    const listItems = pois.map((poi, index) => {
      poi.index = index;

      const icon = poi.icon || {
        url: this.getSvgPath(poi.icon),
      };

      return (
        <Marker
          icon={icon}
          position={{ lat: poi.lat, lng: poi.lng }}
          clickable={true}
          key={index}
          editable={editable}
          draggable={editable}
          onClick={() => {
            poi.isOpen = !poi.isOpen;
            this.drawPois(pois, editable);
          }}
          onDblClick={() => {}}
          onDragEnd={(e) => {
            poi.lat = e.latLng.lat();
            poi.lng = e.latLng.lng();
            this.drawPois(pois, editable);
          }}
        >
          {poi.isOpen && (
            <InfoWindow
              key={index}
              position={{ lat: poi.lat, lng: poi.lng }}
              onCloseClick={() => {
                poi.isOpen = !poi.isOpen;
                this.drawPois(pois, editable);
              }}
            >
              <div className="info-window-body">
                {
                  <>
                    <div className="p-d-flex  p-jc-between p-ai-center p-mb-2">
                      <p className="fs-normal txt-bold"> {poi.description} </p>

                      {editable && (
                        <div>
                          <Button
                            icon="pi pi-pencil"
                            className="info-window-edit-btn"
                            onClick={() => this.openModal(poi)}
                          />
                          <Button
                            icon="pi pi-trash"
                            className="info-window-edit-btn"
                            onClick={() => this.deletePoi(pois, poi)}
                          />
                        </div>
                      )}
                    </div>
                    <p className="fs-small">
                      {parseFloat(poi.lat).toFixed(5)},{" "}
                      {parseFloat(poi.lng).toFixed(5)}
                    </p>
                  </>
                }
              </div>
            </InfoWindow>
          )}
        </Marker>
      );
    });

    return listItems;
    // let _points = [...this.poiTags];
    // this.setPoiTags([_points[0], _points[1], ...listItems]);
    // this.setPoiValues(pois);
  }
}
