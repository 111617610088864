import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";

import { UserService } from "../service/UserService";
import { DriverService } from "../service/DriverService";
import { EventService } from "../service/EventService";
import { ClubService } from "../service/ClubService";
import { StateService } from "../service/StateService";
import { InvitationService } from "../service/InvitationService";
import { LicenseService } from "../service/LicenseService";
import { CategoriesService } from "../service/CategoriesService";
import { TokenAuthService } from "../service/TokenAuthService";

import { HeaderPageDetail } from "../components/headers/HeaderPageDetail";

import { $ } from "react-jquery-plugin";
import { useHistory, useParams } from "react-router-dom";
import {
  ErrorDialog,
  ErrorDialogEmpty,
  ErrorDialogSet,
  ErrorDialogSetResponse,
} from "../dialogs/ErrorDialog";
import { Utils } from "../utilities/Utils";
import { ConfirmMessageBox } from "../components/modals/ConfirmMessageBox";

export const Drivers = () => {
  let emptyDriver = {
    DriverId: null,
    InvitationId: null,
    UserId: null,
    EventId: null,
    Number: "",
    Authorized: 0,
    FirstName: "",
    LastName: "",
    CategoryId: null,
    StatusId: 1504,
    Category: "",
    State: "NOTSEND_INVITATION",
    CodriverName: "",
    CodriverSurname: "",
    DriverGrupoSanguineo: "",
    CodriverGrupoSanguineo: "",
    Phone: "",
    Email: "",
    Brand: "",
    Model: "",
  };

  let emptyLicense = {
    LicId: null,
    ClubId: null,
    Total: 0,
    Given: 0,
    From: null,
    To: null,
    Events: 0,
  };

  const [stateErrorDialog, setStateErrorDialog] = useState(ErrorDialogEmpty);
  const [drivers, setDrivers] = useState(null);
  const [categories, setCategories] = useState(null);
  const [clubs, setClubs] = useState(null);
  const [isAdmin, setIsAdmin] = useState(null);
  const [clubId, setClubId] = useState(null);
  const [driverDialog, setDriverDialog] = useState(false);
  // const [deleteDriverDialog, setDeleteDriverDialog] = useState(false);
  const [driver, setDriver] = useState(emptyDriver);
  const [phoneEnabled, setPhoneEnabled] = useState(true);
  const [selectedDrivers, setSelectedDrivers] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [actionConfirmMessage, setActionConfirmMessage] = useState(false);
  const [actionConfirmTextButton, setActionConfirmTextButton] = useState("");
  const [actionConfirmDialog, setActionConfirmDialog] = useState(false);

  const [states, setStates] = useState(null);
  const [applyOption, setApplyOption] = useState(0);
  const [license, setLicense] = useState(emptyLicense);
  const [isEditable, setEditable] = useState(false);
  const [removeAllDialogVisible, setRemoveAllDialogVisible] = useState(false);

  const applyOptions = [
    { Id: "1", Description: "Authorize to run" },
    { Id: "2", Description: "Unauthorize to run" },
    { Id: "3", Description: "Send invite" },
    { Id: "4", Description: "Delete" },
  ];

  const toast = useRef(null);
  const dt = useRef(null);
  const params = useParams();
  const [loading, setLoading] = useState(true);

  const history = useHistory();

  //let refreshGrid = false;

  const exportAllDrivers = async () => {
    const driverService = new DriverService();
    const resDrivers = await driverService.getAllDriversByClub(clubId);

    if (resDrivers.length === 0) {
      resDrivers = [
        {
          Address: "",
          Brand: "",
          Category: "",
          CodriverGrupoSang: "",
          CodriverName: "",
          CodriverSurname: "",
          Email: "",
          FirstName: "",
          GrupoSanguineo: "",
          LastName: "",
          Model: "",
          Number: "",
          Phone: "",
        },
      ];
    }

    Utils.exportJsonToCSV(resDrivers, "Drivers", true);
  };

  // Se ejecuta luego del render
  useEffect(() => {
    try {
      const load = async () => {
        setLoading(true);

        const categoriesService = new CategoriesService();
        const resCategories = await categoriesService.getCategoriesByEvent(
          params.eveId
        );
        setCategories(resCategories.filter((e) => e.selected != null));
        //setCategories(resCategories);

        const stateService = new StateService();
        const resState = await stateService.getStatesIdDescription(1500);
        setStates(resState);

        const clubService = new ClubService();
        const resClubs = await clubService.getClubsIdDescription();
        setClubs(resClubs);

        const eventsService = new EventService();
        const resDrivers = await eventsService.getDrivers(params.eveId);
        setDrivers(resDrivers);

        const resEvent = await eventsService.getEvent(params.eveId);
        const auth = new TokenAuthService();
        let _isAdmin = auth.isAdmin();
        let isPlanner = auth.isPlanner();
        if (resEvent.past) setEditable(false);
        else setEditable(_isAdmin || isPlanner);

        setClubId(resEvent.cluId);
        setIsAdmin(_isAdmin);

        const licenseService = new LicenseService();
        const resLicenses = await licenseService.getCurrent(params.eveId);
        if (resLicenses.length > 0) {
          let _license = {
            Given: resLicenses[0].licLicenseGiven,
            Total: resLicenses[0].licTotal,
          };
          setLicense(_license);
        } else {
          setLicense(emptyLicense);
        }

        setLoading(false);
      };
      load();
    } catch (error) {
      setStateErrorDialog(ErrorDialogSet(true, ""));
    }
  }, []);

  if (loading)
    return (
      <div className="loading-container">
        <h3>Loading...</h3>
      </div>
    );

  const saveDriver = async () => {
    setSubmitted(true);

    try {
      if (validateRequired()) {
        let _drivers = [...drivers];
        let _driver = { ...driver };

        if (_driver.InvitationId) {
          let invitationService = new InvitationService();
          await invitationService
            .updateInvitationDriver(_driver)
            .then(() => {
              let index = findIndexById(_driver.InvitationId);
              _drivers[index] = _driver;

              toast.current.show({
                severity: "success",
                summary: "Successful",
                detail: "Invitation updated",
                life: 3000,
              });
              closeDialog(_drivers);
            })
            .catch((res) => {
              setStateErrorDialog(ErrorDialogSetResponse(res));
            });
        } else {
          _driver.EventId = params.eveId;
          let invitationService = new InvitationService();
          await invitationService
            .createInvitationDriver(_driver)
            .then((res) => {
              _driver.InvitationId = res.data.Invitation.Id;
              _driver.EventId = res.data.Invitation.EveId;
              _driver.UserId = res.data.User.Id;
              _driver.DriverId = res.data.Driver.Id;
              _driver.Number = res.data.Invitation.Number;
              _driver.Authorized = res.data.Invitation.Authorized ? 1 : 0;
              _driver.FirstName = res.data.User.Name; // + " " + data.User.Surname;
              _driver.LastName = res.data.User.Surname;
              _driver.CategoryId = res.data.Invitation.CatId;
              _driver.StatusId = res.data.Invitation.StsId;
              _driver.Category = "";
              _driver.State = "NOTSEND_INVITATION";
              _driver.CodriverName = res.data.Invitation.CodriverName;
              _driver.CodriverSurname = res.data.Invitation.CodriverSurname;
              _driver.CodriverName = res.data.Invitation.CodriverName; // + " " + data.Invitation.CodriverSurname;
              _driver.CodriverSurname = res.data.Invitation.CodriverSurname;
              _driver.DriverGrupoSanguineo = res.data.Driver.GrupoSanguineo;
              _driver.CodriverGrupoSanguineo =
                res.data.Invitation.CodriverGrupoSang;
              _driver.Phone = res.data.User.Phone;
              _driver.Email = res.data.User.Email;
              _driver.Brand = res.data.Invitation.Brand;
              _driver.Model = res.data.Invitation.Model;

              _drivers.push(_driver);
              toast.current.show({
                severity: "success",
                summary: "Successful",
                detail: "Invitation created",
                life: 3000,
              });
              closeDialog(_drivers);
            })
            .catch((res) => {
              setStateErrorDialog(ErrorDialogSetResponse(res));
            });
        }
      }
    } catch (error) {
      setStateErrorDialog(ErrorDialogSet(true, ""));
    }
  };

  const removeAllInvitations = async () => {
    try {
      var invitations = [];

      drivers.map((invitation) => {
        invitations.push(invitation.InvitationId);
      });

      let invitationService = new InvitationService();
      await invitationService.deleteSelected(invitations);
      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "invitations deleted",
        life: 3000,
      });
      const eventsService = new EventService();
      const resDrivers = await eventsService.getDrivers(params.eveId);
      setDrivers(resDrivers);
    } catch (res) {
      setStateErrorDialog(ErrorDialogSetResponse(res));
    }
  };

  const findIndexById = (id) => {
    let index = -1;
    for (let i = 0; i < drivers.length; i++) {
      if (drivers[i].InvitationId === id) {
        index = i;
        break;
      }
    }
    return index;
  };

  const validateForAuthorizeRow = (row) => {
    let r = row.CategoryId && row.Number && row.Brand && row.Model;
    if (r) return true;
    return false;
  };

  const validateForAuthorize = async () => {
    let invitationsValid = [];

    await Promise.all(
      selectedDrivers.map(async (item) => {
        let invitationService = new InvitationService();
        let invitation = await invitationService.getById(item.InvitationId);

        if (
          invitation.catId &&
          invitation.invNumber &&
          // invitation.invCodriverName &&
          // invitation.invCodriverSurname &&
          // invitation.invCodriverGrupoSanguineo &&
          invitation.invBrand &&
          invitation.invModel
        ) {
          invitationsValid.push(invitation.invId);
        }
      })
    );

    return invitationsValid;
  };

  async function applyAction() {
    hideActionConfirmDialog();
    try {
      let invitationService = new InvitationService();

      var invitations = [];

      selectedDrivers.map((invitation) => {
        invitations.push(invitation.InvitationId);
      });

      switch (applyOption) {
        case "1":
          //Authorize
          let invitationsValid = await validateForAuthorize();
          let total = invitationsValid.length + " of " + invitations.length;
          if (invitationsValid.length === 0) {
            toast.current.show({
              severity: "warn",
              summary: "Successful",
              detail:
                "The selected pilots, do not have the necessary information for authorize",
              life: 6000,
            });
          } else {
            await invitationService.authorized(true, invitationsValid);
            if (invitationsValid.length === invitations.length) {
              toast.current.show({
                severity: "success",
                summary: "Successful",
                detail: "Drivers Authorized " + total,
                life: 3000,
              });
            } else {
              toast.current.show({
                severity: "warn",
                summary: "Successful",
                detail:
                  "There are selected pilots, who do not have the necessary information. They will not be authorized",
                life: 6000,
              });
              toast.current.show({
                severity: "success",
                summary: "Successful",
                detail: "Drivers Authorized " + total,
                life: 6000,
              });
            }
          }
          break;
        case "2":
          //UnAuthorize
          await invitationService.authorized(false, invitations);
          toast.current.show({
            severity: "success",
            summary: "Successful",
            detail: "Drivers Unauthorized",
            life: 3000,
          });
          break;
        case "3":
          //Send
          await invitationService.send(invitations);
          toast.current.show({
            severity: "success",
            summary: "Successful",
            detail: "Invitations sent",
            life: 3000,
          });
          break;
        case "4":
          //Delete
          await invitationService.deleteSelected(invitations);
          setSelectedDrivers(null);
          invitations = [];
          toast.current.show({
            severity: "success",
            summary: "Successful",
            detail: "Drivers deleted",
            life: 3000,
          });
          break;
        default:
          //
          break;
      }

      const eventsService = new EventService();
      const resDrivers = await eventsService.getDrivers(params.eveId);
      setDrivers(resDrivers);
    } catch (res) {
      setStateErrorDialog(ErrorDialogSetResponse(res));
    }
  }

  // #region Auxiliars
  const refreshGrid = async () => {
    const eventsService = new EventService();
    const resDrivers = await eventsService.getDrivers(params.eveId);
    setDrivers(resDrivers);
  };

  const openNew = () => {
    setDriver(emptyDriver);
    setSubmitted(false);
    setDriverDialog(true);
  };
  const hideDialog = () => {
    setSubmitted(false);
    setDriverDialog(false);
    setPhoneEnabled(true);
  };

  const hideActionConfirmDialog = () => {
    setActionConfirmDialog(false);
  };

  const validateRequired = () => {
    for (let k = 0; k < $("[Required]").length; k++) {
      if (!$("[Required]")[k].value) return false;
    }
    return true;
  };
  const closeDialog = (drivers) => {
    setDrivers(drivers);
    setDriverDialog(false);
    setDriver(emptyDriver);
  };

  const exportCSV = () => {
    dt.current.exportCSV();
  };
  const downloadDrivers = async () => {
    const eventsService = new EventService();
    const resDrivers = await eventsService.getDrivers(params.eveId);
    setDrivers(resDrivers);

    let formatDrivers;

    if (drivers.length === 0) {
      formatDrivers = [
        {
          Email: "",
          FirstName: "",
          LastName: "",
          Address: "",
          Phone: "",
          GrupoSanguineo: "",
          Number: "",
          Brand: "",
          Model: "",
          Category: "",
          CodriverName: "",
          CodriverSurname: "",
          CodriverGrupoSang: "",
          // Invited: "",
          // Authorized: "",
        },
      ];
    } else {
      formatDrivers = drivers.map((d) => {
        return {
          Email: d.Email,
          FirstName: d.FirstName,
          LastName: d.LastName,
          Address: "",
          Phone: d.Phone,
          GrupoSanguineo: d.DriverGrupoSanguineo,
          Number: d.Number,
          Brand: d.Brand,
          Model: d.Model,
          Category: d.Category,
          CodriverName: d.CodriverName,
          CodriverSurname: d.CodriverSurname,
          CodriverGrupoSang: d.CodriverGrupoSanguineo,
          // Invited: d.State,
          // Authorized: d.Authorized,
        };
      });
    }
    Utils.exportJsonToCSV(formatDrivers, "Drivers", true);
  };
  const importCSV = async ({ files }) => {
    try {
      const driverService = new DriverService();
      var currFile = files[0];
      await driverService.updloadInvitations(currFile, params.eveId);
      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "The invitations were created",
        life: 3000,
      });
      refreshGrid(); //refresco la grilla
    } catch (error) {
      let errormessage = "";
      if (error.response) {
        errormessage = error.response.data.message;
      }

      let formattedMessage = JSON.parse(errormessage).map((m) => {
        let errors = "";
        let errorList = m.Errors || m.msg;
        if (Array.isArray(errorList)) {
          errors = errorList.map((e) => e.msg).join(",");
        } else {
          errors = errorList;
        }
        //let text = `Row: ${m.Fila}, error: ${errors}\n`;
        let text = m.Fila
          ? `Row: ${m.Fila}, error: ${errors}\n`
          : `error: ${errors}\n`;
        return text;
      });

      setStateErrorDialog(ErrorDialogSet(true, formattedMessage));
      // setErrorMessage(errormessage);
      // setErrorDialog(true);
    }
  };
  const onActionChange = (e) => {
    const val = (e.target && e.target.value) || "";
    setApplyOption(val);
  };
  async function confirmAction() {
    try {
      let notSelectedDrivers = true;
      if (selectedDrivers) {
        notSelectedDrivers = notSelectedDrivers.length == 0;
      }
      if (notSelectedDrivers) {
        setStateErrorDialog(
          ErrorDialogSet(true, "There are no selected competitors")
        );
        return;
      }

      if (applyOption == "0") {
        setStateErrorDialog(ErrorDialogSet(true, "Select action, please"));
        return;
      }

      //console.log(applyOption);
      switch (applyOption) {
        case "1":
          //Authorize
          setActionConfirmMessage(
            "Do you want to authorize " +
              selectedDrivers.length +
              " selected competitors?"
          );
          setActionConfirmTextButton("Authorize");
          break;
        case "2":
          //UnAuthorize
          setActionConfirmMessage(
            "Do you want to unauthorize " +
              selectedDrivers.length +
              " selected competitors?"
          );
          setActionConfirmTextButton("Unauthorize");
          break;
        case "3":
          //Send
          setActionConfirmMessage(
            "Do you want to invite " +
              selectedDrivers.length +
              " selected competitors? They'll receive an invitation via email and the competitor app"
          );
          setActionConfirmTextButton("Send");
          break;
        case "4":
          //Delete
          setActionConfirmMessage(
            "Do you want to delete " +
              selectedDrivers.length +
              " competitors? You'll no longer be able to edit them unless you regiter them again"
          );
          setActionConfirmTextButton("Delete");
          break;
        default:
          //
          break;
      }

      setActionConfirmDialog(true);
    } catch (error) {
      let errormessage = "";
      if (error.response) {
        errormessage = error.response.data.message;
      }
      setStateErrorDialog(ErrorDialogSet(true, errormessage));
      // setErrorMessage(errormessage);
      // setErrorDialog(true);
    }
  }
  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _driver = { ...driver };
    _driver[`${name}`] = val;

    setDriver(_driver);
  };
  const loadDataDriver = (drv) => {
    let _driver = { ...driver };

    _driver.DriverId = drv.driId;
    _driver.UserId = drv.rolUse.use.useId;

    _driver.FirstName = drv.rolUse.use.useName;
    _driver.LastName = drv.rolUse.use.useSurname;

    _driver.DriverGrupoSanguineo = drv.driGrupoSanguineo;

    _driver.Email = drv.rolUse.use.useEmail;
    _driver.Phone = drv.rolUse.use.usePhone;
    if (!isAdmin) setPhoneEnabled(false);

    setDriver(_driver);
  };

  const invitationExists = async (driId, categoryId) => {
    const invitationService = new InvitationService();

    const invitation = await invitationService.getByDriverEvent(
      driId,
      params.eveId,
      categoryId
    );
    console.log("invitation.data", invitation.data);
    if (invitation.data && invitation.data.length > 0) {
      return true;
    }
    return false;
  };

  const validateEmail = async (mail, categoryId) => {
    try {
      let toastMessage = "";
      if (mail) {
        const driverService = new DriverService();
        const existDriver = await driverService.getDriverByMailClub(
          mail,
          clubId
        );
        if (existDriver) {
          if (!categoryId) {
            loadDataDriver(existDriver);
          } else {
            let _invitationExists = await invitationExists(
              existDriver.driId,
              categoryId
            );
            if (!_invitationExists) {
              loadDataDriver(existDriver);
            } else {
              toastMessage =
                "The email is already added to the event\\category";
            }
          }
        } else {
          //validar si existe como usuario
          const userService = new UserService();
          const existsUser = await userService.getUsersByMail(mail);
          if (existsUser) {
            toastMessage =
              "The email is associated with a user who is not a Competitor. It can not be added!";
          }
        }
      }
      if (toastMessage !== "")
        toast.current.show({
          severity: "error",
          summary: "error",
          detail: toastMessage,
          life: 3000,
        });
    } catch (error) {
      setStateErrorDialog(ErrorDialogSet(true, ""));
    }
  };

  const onEmailBlur = async (e) => {
    const mail = (e.target && e.target.value) || "";
    const categoryId = driver.CategoryId;
    validateEmail(mail, categoryId);
  };

  const validateCategory = async (mail, categoryId) => {
    try {
      let toastMessage = "";
      if (categoryId) {
        if (mail) {
          const driverService = new DriverService();
          const existDriver = await driverService.getDriverByMail(mail);
          if (existDriver) {
            let _invitationExists = await invitationExists(
              existDriver.driId,
              categoryId
            );
            if (_invitationExists) {
              toastMessage =
                "The email is already added to the event\\category";
            }
          }
        }
      }

      if (toastMessage !== "")
        toast.current.show({
          severity: "error",
          summary: "error",
          detail: toastMessage,
          life: 3000,
        });
    } catch (error) {
      setStateErrorDialog(ErrorDialogSet(true, ""));
    }
  };

  const onCategoryBlur = async (e) => {
    const mail = driver.Email;
    const categoryId = (e.target && e.target.value) || "";
    validateCategory(mail, categoryId);
  };
  //#endregion

  const competitorsAuthorized = () => {
    const aut = drivers.filter((d) => d.Authorized === 1);
    return aut.length;
  };

  // #region Templatedatatable
  const onErrorFind = (e) => {
    if (e) return e.Description;
    else return "error";
  };

  const onErrorFindCategory = (e) => {
    if (e) return e.Description;
    else return "";
  };

  const authorizedFormat = (e) => {
    if (e.Authorized == 1)
      return (
        <span className="p-column-title txt-clrSucces500">
          <i
            className="pi pi-check-circle p-mr-1"
            style={{ fontSize: "0.75rem" }}
          ></i>
          Yes
        </span>
      );
    else
      return (
        <span className="p-column-title  txt-clrAlert500">
          <i
            className="pi pi-times-circle p-mr-1"
            style={{ fontSize: "0.75rem" }}
          ></i>
          {validateForAuthorizeRow(e) ? "No" : "Missing data"}
        </span>
      );
  };

  const numberBodyTemplate = (rowData) => {
    return <span className="p-column-title">{rowData.Number}</span>;
  };
  const authorizationBodyTemplate = (rowData) => {
    return <>{authorizedFormat(rowData)}</>;
  };
  const statusBodyTemplate = (rowData) => {
    return (
      <span className="p-column-title">
        {rowData.State == "PENDING_INVITATION" && "Pending"}
        {rowData.State == "ACEPTED_INVITATION" && "Accepted"}
        {rowData.State == "REJECTED_INVITATION" && "Rejected"}
        {rowData.State == "NOTSEND_INVITATION" && "Not Send"}
      </span>
    );
  };
  const pilotBodyTemplate = (rowData) => {
    return (
      <span className="p-column-title">
        {rowData.FirstName} {rowData.LastName} ({rowData.DriverGrupoSanguineo})
      </span>
    );
  };

  const copilotBodyTemplate = (rowData) => {
    return (
      <span className="p-column-title">
        {rowData.CodriverName} {rowData.CodriverSurname} (
        {rowData.CodriverGrupoSanguineo})
      </span>
    );
  };

  const categoryBodyTemplate = (rowData) => {
    return (
      <span className="p-column-title">
        {onErrorFindCategory(
          categories.find((e) => e.Id == rowData.CategoryId)
        )}
      </span>
    );
  };
  const brandBodyTemplate = (rowData) => {
    return <span className="p-column-title">{rowData.Brand}</span>;
  };
  const modelBodyTemplate = (rowData) => {
    return <span className="p-column-title">{rowData.Model}</span>;
  };

  const emailBodyTemplate = (rowData) => {
    return <span className="p-column-title">{rowData.Email}</span>;
  };

  const editInvitation = (row) => {
    let _driver = row;

    setDriver(_driver);
    setSubmitted(false);
    setDriverDialog(true);
    if (!isAdmin) setPhoneEnabled(false);
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        {rowData.Authorized == 0 && (
          <Button
            icon="pi pi-pencil"
            className="p-mr-1"
            onClick={() => editInvitation(rowData)}
          />
        )}
        {/* <Button icon="pi pi-trash" className="btn-alert" onClick={() => confirmDeleteClub(rowData)} /> */}
      </div>
    );
  };
  //#endregion

  const header = (
    <div className="table-header">
      {isEditable && (
        <h2 className="p-m-0 fs-x-normal">
          {/* Competitors ({drivers ? drivers.length : 0}) <span className="fs-medium txt-clrDark850 txt-regular">{license.Given} out of {license.Total} licenses available</span> */}
          Competitors ({drivers ? drivers.length : 0}){" "}
          <span className="fs-medium txt-clrDark850 txt-regular p-ml-2">
            {competitorsAuthorized()} Authorized
          </span>
        </h2>
      )}

      <div>
        {!isEditable && isAdmin && (
          <Button
            label="remove all"
            icon="pi pi-trash"
            className="btn-secondary   p-mr-4"
            onClick={() => setRemoveAllDialogVisible(true)}
          ></Button>
        )}

        <Button
          label="Download"
          icon="pi pi-download"
          className="btn-secondary   p-mr-4"
          onClick={downloadDrivers}
        ></Button>
        {/* {!isEditable && ...} */}

        <span className="p-input-icon-right p-mr-4">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Search competitor"
          />
        </span>

        {isEditable && (
          <React.Fragment>
            <Dropdown
              value={applyOption}
              optionLabel="Description"
              optionValue="Id"
              options={applyOptions}
              placeholder="Select action"
              onChange={(e) => onActionChange(e)}
              className="p-mr-3"
            ></Dropdown>
            <Button
              label="Apply"
              className="btn-primary "
              style={{ minWidth: "auto" }}
              onClick={confirmAction}
            />
          </React.Fragment>
        )}
      </div>
    </div>
  );
  const eventDialogFooter = (
    <>
      <Button label="Cancel" className="btn-secondary" onClick={hideDialog} />
      <Button label="Save" className="btn-primary" onClick={saveDriver} />
    </>
  );
  // const deleteDriverDialogFooter = (
  //     <>
  //         <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteDriverDialog} />
  //         <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteDriver} />
  //     </>
  // );
  const actionConfirmDialogFooter = (
    <>
      <Button
        label="No"
        //icon="pi pi-times"
        //className="p-button-text"
        className="btn-secondary"
        onClick={hideActionConfirmDialog}
      />
      <Button
        label={actionConfirmTextButton}
        //icon="pi pi-check"
        //className="p-button-text"
        className="btn-primary "
        onClick={applyAction}
      />
    </>
  );

  //Header Pages Templates Parts
  const leftColumnTemplate = () => {
    return (
      <div className="p-m-0 p-col-6">
        <Button
          icon="pi pi-chevron-left"
          onClick={() => history.goBack()}
          className="p-button-rounded  p-button-outlined p-button-icon-only p-mr-3"
        />
      </div>
    );
  };

  const rightColumnTemplate = () => {
    return (
      <div className="p-m-0 p-col-6 p-d-flex p-jc-end">
        {isEditable && (
          <Button
            label={"Add new competitor"}
            icon="pi pi-plus"
            className="btn-primary  p-mr-2"
            onClick={openNew}
          />
        )}
      </div>
    );
  };

  return (
    <div className="p-grid crud-demo">
      <div className="p-col-12">
        <div className="inner-container">
          <Toast ref={toast} />

          {/*  <Toolbar className="p-mb-5 header-drivers-list" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar> */}

          <HeaderPageDetail
            headerStyles="p-mb-5 header-drivers-list p-ai-center"
            leftColumn={leftColumnTemplate()}
            rightColumn={rightColumnTemplate()}
          />

          {isEditable && (
            <div className="card">
              <React.Fragment>
                <FileUpload
                  // onUpload={importCSV}
                  uploadHandler={importCSV}
                  customUpload={true}
                  mode="basic"
                  auto={true}
                  label="Import"
                  chooseLabel="Import"
                  className="p-mr-2 p-d-inline-block btn-primary-outline "
                />
                {/* <Button label="Export" icon="pi pi-upload" className="btn-primary p-mr-2" onClick={exportCSV} /> */}
                <Button
                  label="All Export"
                  icon="pi pi-upload"
                  className="btn-primary "
                  onClick={exportAllDrivers}
                />
              </React.Fragment>
            </div>
          )}
          <DataTable
            ref={dt}
            value={drivers}
            selection={selectedDrivers}
            onSelectionChange={(e) => setSelectedDrivers(e.value)}
            dataKey="InvitationId"
            paginator
            rows={10}
            rowsPerPageOptions={[5, 10, 25]}
            className="table-layout-auto"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} drivers"
            globalFilter={globalFilter}
            emptyMessage="No drivers found."
            header={header}
          >
            {isEditable && (
              <Column selectionMode="multiple" className="is-first" />
            )}
            <Column
              field="Number"
              header="CAR"
              body={numberBodyTemplate}
              headerClassName="txt-center"
              bodyClassName="txt-right"
              sortable
            />
            <Column
              field="State"
              header="INVITED"
              sortable
              body={statusBodyTemplate}
            />
            <Column
              field="Authorized"
              header="AUTHORIZED"
              sortable
              body={authorizationBodyTemplate}
            />

            <Column
              field="FirstName"
              header="DRIVER"
              sortable
              body={pilotBodyTemplate}
            />
            {/* <Column field="DriverGrupoSanguineo" header="(P) BLOOD TYPE" sortable body={driverGupoSanguineoBodyTemplate}/> */}

            <Column
              field="CodriverName"
              header="CODRIVER"
              sortable
              body={copilotBodyTemplate}
            />

            {/* <Column field="CodriverGrupoSanguineo" header="(COD) BLOOD TYPE" sortable body={codriverGupoSanguineoBodyTemplate}/> */}

            <Column
              field="Category"
              header="CATEGORY"
              sortable
              body={categoryBodyTemplate}
            />
            <Column
              field="Brand"
              header="BRAND"
              body={brandBodyTemplate}
              sortable
            />
            <Column
              field="Model"
              header="MODEL"
              body={modelBodyTemplate}
              sortable
            />
            <Column
              field="Email"
              header="EMAIL"
              body={emailBodyTemplate}
              sortable
            />
            {/* <Column field="Phone" header="PHONE" body={phoneBodyTemplate} sortable/> */}

            {isEditable && (
              <Column body={actionBodyTemplate} className="is-last" />
            )}
          </DataTable>

          <Dialog
            visible={driverDialog}
            style={{ width: "650px" }}
            header={
              driver.InvitationId ? "Edit competitor" : "Add new competitor"
            }
            modal
            className="p-fluid"
            footer={eventDialogFooter}
            onHide={hideDialog}
          >
            <p className="fs-m-normal p-mb-4">Contact details</p>

            {/* <div className="p-field">
                                    <label htmlFor="address">Address</label>
                                    <InputText id="address" maxlength="100" value={driver.Address} onChange={(e) => onInputChange(e, "Address")} required rows={3} cols={20} />
                                </div> */}
            <div className="p-grid">
              <div className="p-field p-col">
                <label htmlFor="email">Email</label>
                <InputText
                  id="email"
                  disabled={driver.InvitationId ? true : false}
                  maxlength="50"
                  value={driver.Email}
                  onChange={(e) => onInputChange(e, "Email")}
                  required="true"
                  onBlur={(e) => onEmailBlur(e)}
                  className={classNames({
                    "p-invalid": submitted && !driver.Email,
                  })}
                />
                {submitted && !driver.Email && (
                  <small className="p-invalid">Email is required.</small>
                )}
              </div>
              <div className="p-field p-col">
                <label htmlFor="phone">Phone</label>
                <InputText
                  id="phone"
                  maxlength="20"
                  value={driver.Phone}
                  onChange={(e) => onInputChange(e, "Phone")}
                  disabled={!phoneEnabled}
                  // className={classNames({
                  //   "p-invalid": submitted && !driver.Phone,
                  // })}
                />
                {/* {submitted && !driver.Phone && (
                  <small className="p-invalid">Phone is required.</small>
                )} */}
              </div>
            </div>

            <hr />
            <p className="fs-m-normal p-mb-4">Info</p>

            <div className="p-grid">
              <div className="p-field p-col-6">
                <label>Category</label>
                <Dropdown
                  id="categoryId"
                  optionLabel="Description"
                  optionValue="Id"
                  value={driver.CategoryId}
                  options={categories}
                  onChange={(e) => {
                    onInputChange(e, "CategoryId");
                    onCategoryBlur(e);
                  }}
                  required
                  className={classNames({
                    "p-invalid": submitted && !driver.CategoryId,
                  })}
                />
                {submitted && !driver.CategoryId && (
                  <small className="p-invalid">Category is required.</small>
                )}
              </div>
              <div className="p-field p-col">
                <label htmlFor="number">Car Number</label>
                <InputText
                  id="number"
                  maxlength="20"
                  value={driver.Number}
                  onChange={(e) => onInputChange(e, "Number")}
                  // required className={classNames({ "p-invalid": submitted && !driver.Number })}
                />
                {/* {submitted && !driver.Number && <small className="p-invalid">Car Number is required.</small>} */}
              </div>
            </div>

            <hr />
            <p className="fs-m-normal p-mb-4">Competitor details</p>

            <div className="p-grid p-mb-3">
              <div className="p-field p-col-6">
                <label htmlFor="firstname">Driver's name</label>
                <InputText
                  id="firstname"
                  maxlength="100"
                  value={driver.FirstName}
                  onChange={(e) => onInputChange(e, "FirstName")}
                  required="true"
                  autoFocus
                  className={classNames({
                    "p-invalid": submitted && !driver.FirstName,
                  })}
                />
                {submitted && !driver.FirstName && (
                  <small className="p-invalid">First Name is required.</small>
                )}
              </div>
              <div className="p-field p-col-6">
                <label htmlFor="lastname">Driver's last name</label>
                <InputText
                  id="lastname"
                  maxlength="100"
                  value={driver.LastName}
                  onChange={(e) => onInputChange(e, "LastName")}
                  required
                  className={classNames({
                    "p-invalid": submitted && !driver.LastName,
                  })}
                />
                {submitted && !driver.LastName && (
                  <small className="p-invalid">Last Name is required.</small>
                )}
              </div>

              <div className="p-field p-col-6">
                <label htmlFor="bloodType">Driver's blood type</label>
                <InputText
                  id="bloodType"
                  maxlength="20"
                  value={driver.DriverGrupoSanguineo}
                  onChange={(e) => onInputChange(e, "DriverGrupoSanguineo")}
                  required="true"
                  className={classNames({
                    "p-invalid": submitted && !driver.DriverGrupoSanguineo,
                  })}
                />
                {submitted && !driver.DriverGrupoSanguineo && (
                  <small className="p-invalid">
                    Driver's blood type is required.
                  </small>
                )}
              </div>
            </div>
            <div className="p-formgrid p-grid">
              <div className="p-field  p-col-6">
                <label htmlFor="copilot">Co-driver name</label>
                <InputText
                  id="copilot"
                  value={driver.CodriverName}
                  onChange={(e) => onInputChange(e, "CodriverName")}
                  //  required className={classNames({ "p-invalid": submitted && !driver.CodriverName })}
                />
                {/* {submitted && !driver.CodriverName && <small className="p-invalid">Co-driver Name is required.</small>} */}
              </div>
              <div className="p-field  p-col-6">
                <label htmlFor="copilotLastName">Co-driver last name</label>
                <InputText
                  id="copilotLastName"
                  value={driver.CodriverSurname}
                  onChange={(e) => onInputChange(e, "CodriverSurname")}
                  // required className={classNames({ "p-invalid": submitted && !driver.CodriverSurname })}
                />
                {/* {submitted && !driver.CodriverSurname && <small className="p-invalid">Co-driver is required.</small>} */}
              </div>

              <div className="p-field p-col-6">
                <label htmlFor="codriverGrupoSanguineo">
                  Co-driver's blood type
                </label>
                <InputText
                  id="codriverGrupoSanguineo"
                  maxlength="20"
                  value={driver.CodriverGrupoSanguineo}
                  onChange={(e) => onInputChange(e, "CodriverGrupoSanguineo")}
                  // required className={classNames({ "p-invalid": submitted && !driver.CodriverGrupoSanguineo })}
                />
                {/* {submitted && !driver.CodriverGrupoSanguineo && <small className="p-invalid">Co-driver's blood type is required.</small>} */}
              </div>
            </div>

            <hr />
            <p className="fs-m-normal p-mb-4">Vehicle details</p>

            <div className="p-grid">
              <div className="p-field p-col-6">
                <label htmlFor="brand">Brand</label>
                <InputText
                  id="brand"
                  maxlength="100"
                  value={driver.Brand}
                  onChange={(e) => onInputChange(e, "Brand")}
                  // required className={classNames({ "p-invalid": submitted && !driver.Brand })}
                />
                {/* {submitted && !driver.Brand && <small className="p-invalid">Brand is required.</small>} */}
              </div>
              <div className="p-field p-col-6">
                <label htmlFor="model">Model</label>
                <InputText
                  id="model"
                  maxlength="100"
                  value={driver.Model}
                  onChange={(e) => onInputChange(e, "Model")}
                  //  required className={classNames({ "p-invalid": submitted && !driver.Model })}
                />
                {/* {submitted && !driver.Model && <small className="p-invalid">Model is required.</small>} */}
              </div>
            </div>
          </Dialog>

          <Dialog
            visible={actionConfirmDialog}
            style={{ width: "450px" }}
            header="Confirm"
            modal
            footer={actionConfirmDialogFooter}
            onHide={hideActionConfirmDialog}
          >
            <div className="confirmation-content">
              <i
                className="pi pi-exclamation-triangle p-mr-3"
                style={{ fontSize: "2rem" }}
              />
              {driver && <span>{actionConfirmMessage}</span>}
            </div>
          </Dialog>

          <ConfirmMessageBox
            visible={removeAllDialogVisible}
            header=""
            yesno={false}
            message={"remove all invitations?"}
            close={(r) => {
              setRemoveAllDialogVisible(false);
              if (r) {
                removeAllInvitations();
              }
            }}
          ></ConfirmMessageBox>

          <ErrorDialog stateDialog={stateErrorDialog}></ErrorDialog>
        </div>
      </div>
    </div>
  );
};
