import React, { useState, useEffect } from "react";

import classNames from "classnames";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
// import { Calendar } from "primereact/calendar";
// import { InputNumber } from "primereact/inputnumber";

import { $ } from "react-jquery-plugin";

import { Utils } from "../../utilities/Utils";
import {
  ErrorDialog,
  ErrorDialogEmpty,
  ErrorDialogSet,
  ErrorDialogSetResponse,
  ErrorDialogNoAuthorizedSet,
} from "../ErrorDialog";
import { UserService } from "../../service/UserService";
import { Password } from "../../components/login/Password";

export const PasswordPersonalEditDialog = (props) => {
  let emptyUser = {
    useId: null,
    useName: "",
    useSurname: "",
    useEmail: "",
    usePhone: "",
    RolesId: [],
  };

  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(null);
  const [submitted, setSubmitted] = useState(false);

  const [user, setUser] = useState(emptyUser);
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [stateErrorDialog, setStateErrorDialog] = useState(ErrorDialogEmpty);

  useEffect(() => {
    const load = async () => {
      setLoading(true);
      setSubmitted(false);

      setVisible(props.visibleDialog);

      setUser(props.user);

      setLoading(false);
    };
    load();
  }, [props.visibleDialog, props.user]);

  if (loading)
    return (
      <div className="loading-container">
        <h3>Loading...</h3>
      </div>
    );

  const validateRequired = () => {
    for (let k = 0; k < $("[Required]").length; k++) {
      if (!$("[Required]")[k].value) return false;
    }
    return true;
  };

  const validatePasswords = () => {
    let val = Utils.validPassword(newPassword);
    // && Utils.validPassword(confirmPassword));

    return val;
  };

  const closeCancel = () => {
    props.onClose(null);
  };
  const closeOk = (_item) => {
    setSubmitted(false);
    props.onClose(_item);
  };
  const cancel = () => {
    closeCancel();
  };

  const saveItem = async () => {
    setSubmitted(true);

    try {
      if (validateRequired() && validatePasswords()) {
        let userService = new UserService();
        userService
          .updatePasswordWithValidation(password, newPassword)
          .then(() => {
            closeOk(user);
          })
          .catch((res) => {
            setStateErrorDialog(ErrorDialogSetResponse(res));
          });
      }
    } catch (error) {
      setStateErrorDialog(ErrorDialogSet(true, ""));
    }
  };

  const isMatch = () => {
    if (newPassword !== confirmPassword) {
      return false;
    }
    return true;
  };

  const eventDialogFooter = (
    <>
      <Button label="Cancel" className="btn-secondary" onClick={cancel} />
      <Button
        label="Save changes"
        className="btn-primary "
        onClick={saveItem}
      />
    </>
  );
  return (
    <>
      <Dialog
        visible={visible}
        style={{ width: "650px" }}
        header="Edit personal details"
        modal
        className="p-fluid"
        footer={eventDialogFooter}
        contentClassName="overflow-y-visible"
        onHide={closeCancel}
      >
        <div className="p-field p-mb-2">
          <label htmlFor="current" className="p-d-block">
            Current password
          </label>
          <Password
            value={password}
            onChange={setPassword}
            placeholder="Password"
            required={submitted}
            messageRequired="Password is required."
          ></Password>

          {/* <InputText type="password" id="current" maxLength="20" value={password} placeholder="Password" required="true"
                    value={password} onChange={(e) => setPassword(e.target.value)} /> */}
        </div>
        {/* {submitted && !password && <small className="p-invalid">Password is required. </small>} */}
        {/* {submitted && password && !Utils.validPassword(password) && <p className="p-invalid fs-x-small txt-clrAlert500">Your password must be between 8 and 20 characters long, including a number and a capital letter.</p>} */}

        <div className="p-field">
          <label htmlFor="newpassword" className="p-d-block">
            New password
          </label>
          <Password
            value={newPassword}
            onChange={setNewPassword}
            placeholder="New Password"
            required={submitted}
            messageRequired="New password is required."
          ></Password>
          {/* <InputText
                        required="true"
                        type="text"
                        id="newpassword"
                        value={newPassword}
                        placeholder="New password"
                        onChange={(e) => {
                            setNewPassword(e.target.value);
                        }} maxLength="20"
                    /> */}
        </div>
        {submitted && !newPassword && (
          <small className="p-invalid">New password is required. </small>
        )}

        <div className="p-field p-mb-2">
          <label htmlFor="confirm" className="p-d-block">
            Repeat password
          </label>
          <Password
            value={confirmPassword}
            onChange={setConfirmPassword}
            placeholder="Repeat password"
            required={submitted}
            messageRequired="Repeat password is required."
          ></Password>
          {/* <InputText type="text" id="confirm" maxLength="20" value={confirmPassword} placeholder="Repeat password" required="true"
                    onChange={(e) => setConfirmPassword(e.target.value)}  /> */}
        </div>
        {/* {submitted && !confirmPassword && <small className="p-invalid">Repeat password is required. </small>} */}
        {submitted && !validatePasswords() && (
          <p className="p-invalid fs-x-small txt-clrAlert500">
            Your password must have between 8 and 20 characters long, including
            a number and a capital letter.
          </p>
        )}
        {!isMatch() && (
          <p className="p-invalid fs-x-small txt-clrAlert500">
            Passwords do not match please check
          </p>
        )}
      </Dialog>

      <ErrorDialog stateDialog={stateErrorDialog}></ErrorDialog>
    </>
  );
};
