import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";

import classNames from "classnames";
import { $ } from "react-jquery-plugin";

import { CircuitsService } from "../../service/CircuitsService";
import { CountryService } from "../../service/CountryService";
import {
  ErrorDialog,
  ErrorDialogEmpty,
  ErrorDialogNoAuthorizedSet,
  ErrorDialogSet,
  ErrorDialogSetResponse,
} from "../../dialogs/ErrorDialog";

export const EditDialog = (props) => {
  const [submitted, setSubmitted] = useState(false);
  const [item, setItem] = useState(props.item);
  const [countries, setCountries] = useState(null);

  useEffect(() => {
    const _item = { ...props.item };
    setItem(_item);
    const load = async () => {
      const countryService = new CountryService();
      const resCountries = await countryService.getCountries();
      setCountries(resCountries);
    };
    load();
  }, [props.visible]);

  const setField = (field) => (ev) =>
    setItem({ ...item, [field]: ev.target.value });
  const setCheckBoxField = (field) => (ev) =>
    setItem({ ...item, [field]: ev.checked });

  const validateRequired = () => {
    //if (!user.isPlanner && !user.isViewer && !user.isDirector) return false;

    for (let k = 0; k < $("[Required]").length; k++) {
      if (!$("[Required]")[k].value) return false;
    }
    return true;
  };

  const save = () => {
    setSubmitted(true);

    try {
      if (validateRequired()) {
        let _circuit = { ...item };
        if (_circuit.cirId) {
          const circuitsService = new CircuitsService();
          circuitsService
            .updateCircuit(_circuit)
            .then((data) => {
              props.onChange(_circuit);
              props.hideDialog();
            })
            .catch((res) => {
              var message = "";
              if (
                res.response &&
                res.response.data &&
                res.response.data.statusCode === 418
              )
                message = res.response.data.message;

              props.setStateErrorDialog(ErrorDialogSet(true, message));
            });
        } else {
          const circuitsService = new CircuitsService();
          circuitsService
            .createCircuit(_circuit)
            .then((data) => {
              _circuit.cirId = data.cirId;
              _circuit.cirName = data.cirName;
              props.onChange(_circuit);
              props.hideDialog();
            })
            .catch((res) => {
              props.setStateErrorDialog(ErrorDialogSetResponse(res));
            });
        }
      }
    } catch (error) {
      props.setStateErrorDialog(ErrorDialogSet(true, ""));
      console.log(error.message);
    }
  };

  const dialogFooter = (
    <>
      <Button
        label="Cancel"
        className="btn-secondary"
        onClick={props.hideDialog}
      />
      <Button label="Save" className="btn-primary" onClick={save} />
    </>
  );
  const checkedVal = (val) => {
    return val;
  };

  return (
    <Dialog
      visible={props.visible}
      style={{ width: "450px" }}
      header="Circuits Details"
      modal
      className="p-fluid"
      footer={dialogFooter}
      onHide={props.hideDialog}
    >
      <div className="p-field">
        <label htmlFor="name">Name</label>
        <InputText
          id="name"
          maxLength="100"
          value={item.cirName}
          onChange={setField("cirName")}
          required={true}
          autoFocus
          className={classNames({ "p-invalid": submitted && !item.cirName })}
        />
        {submitted && !item.cirName && (
          <small className="p-invalid">Name is required.</small>
        )}
      </div>

      <div className="p-col-12 p-md-4">
        <div className="p-field-radiobutton">
          <Checkbox
            inputId="optionVisibility"
            name="option"
            value={item.cirVisibility}
            checked={checkedVal(item.cirVisibility)}
            onChange={setCheckBoxField("cirVisibility")}
            className={classNames({
              "p-invalid":
                submitted &&
                !item.cirVisibility &&
                !item.cirVisibility &&
                !item.cirVisibility,
            })}
          />
          <label htmlFor="optionVisibility">Visibility</label>
        </div>
      </div>

      <p className="fs-m-normal p-mb-4">Location</p>

      <div className="p-grid">
        <div className="p-field p-col-6">
          <label htmlFor="country">Country</label>
          <Dropdown
            id="country"
            optionLabel="couName"
            optionValue="couId"
            value={item.couId}
            options={countries}
            onChange={setField("couId")}
            required
            className={classNames({ "p-invalid": submitted && !item.couId })}
          />
          {submitted && !item.couId && (
            <small className="p-invalid">Country is required.</small>
          )}
        </div>
        <div className="p-field p-col-6">
          <label htmlFor="name">State</label>
          <InputText
            id="state"
            maxLength="100"
            value={item.cirState}
            onChange={setField("cirState")}
            required
            className={classNames({ "p-invalid": submitted && !item.cirState })}
          />
          {submitted && !item.cirState && (
            <small className="p-invalid">State/Province is required.</small>
          )}
        </div>
      </div>

      <div className="p-field">
        <label htmlFor="name">City</label>
        <InputText
          id="city"
          maxLength="100"
          value={item.cirCity}
          onChange={setField("cirCity")}
          required
          className={classNames({ "p-invalid": submitted && !item.cirCity })}
        />
        {submitted && !item.cirCity && (
          <small className="p-invalid">City is required.</small>
        )}
      </div>
    </Dialog>
  );
};
