import { Dialog } from "primereact/dialog";
import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import "react-datepicker/dist/react-datepicker.css";
import { Dropdown } from "primereact/dropdown";

import { CircuitsService } from "../../service/CircuitsService";

export const NewpolylineModal = (props) => {
  const [circuits, setCircuits] = useState(null);
  const [item, setItem] = useState({
    trackId: props.trackId,
    trackName: props.trackName,
  });

  useEffect(() => {
    const load = async () => {
      // let circuitService = new CircuitsService();
      // let res = await circuitService.getAll();
      // setCircuits(res);
    };
    load();
  }, []);

  const setField = (field) => (ev) =>
    setItem({ ...item, [field]: ev.target.value });

  const polylineFooter = (
    <div>
      <Button
        label="Cancel"
        className="btn-secondary"
        onClick={() => props.setNewPolylineModal(false)}
      />
      <Button
        label="Save"
        className="btn-primary "
        onClick={() => {
          props.save(item.trackId, item.trackName);
          props.setNewPolylineModal(false);
        }}
      />
    </div>
  );

  return (
    <Dialog
      header="Track details"
      visible={props.newPolylineModal}
      style={{ width: "70vw" }}
      className="p-fluid"
      footer={polylineFooter}
      contentClassName="overflow-y-visible"
      onHide={() => props.setNewPolylineModal(false)}
    >
      <div className="p-grid" style={{ paddingBottom: 50 }}>
        <div className="p-field p-col-6">
          <label htmlFor="segment" className="p-d-block">
            Track name
          </label>
          <input
            type="text"
            placeholder="Track Name"
            //defaultValue={props.detailsNewPolyline.trackName}
            value={item.trackName}
            //ref={props.detailsNewPolyline.inputTrackName}
            onChange={setField("trackName")}
            className="p-inputtext p-component"
          />
        </div>

        {/* <div className="p-field p-col-6">
          <label htmlFor="circuit">Circuit</label>
          <Dropdown
            ref={props.detailsNewPolyline.inputCircuitId}
            id="circuit"
            optionLabel="cirName"
            optionValue="cirId"
            value={item.circuitId}
            options={circuits}
            onChange={setField("circuitId")}
            required
          />
        </div> */}

        {props.erroMessage != "" && (
          <div className="p-col-12">{props.erroMessage}</div>
        )}
      </div>
    </Dialog>
  );
};
