import { Button } from "primereact/button";
// import { FileUpload } from "primereact/fileupload";

export const NewPolylineMenu = (props) => {
  return (
    <div className="menu-edit-map p-jc-between">
      <div className="p-d-flex p-ai-center">
        <Button
          icon="pi pi-chevron-left"
          className="p-button-rounded  p-button-outlined p-button-icon-only p-mr-3"
          onClick={props.back}
        />
        <p className="fs-x-normal p-mr-2">{props.trackStatusText}</p>
        <input
          type="text"
          placeholder="Latitude"
          ref={props.referencePoint.latInput}
          className="p-inputtext p-component p-mr-2"
        />
        <input
          type="text"
          placeholder="Longitude"
          ref={props.referencePoint.lngInput}
          className="p-inputtext p-component p-mr-2"
        />
        <div
          className={"pi pi-fw pi-search"}
          style={{ cursor: "pointer" }}
          onClick={props.findPoint}
        ></div>
      </div>
      <div className="p-d-flex p-ai-center">
        <Button
          label="Cancel"
          className="btn-outline-invert  p-mr-2"
          onClick={props.cancel}
        />
        {props.drawingMode === null && (
          <Button
            label="Save"
            className="btn-primary-invert"
            //onClick={props.continuePolyline}
            onClick={props.savePolyline}
          />
        )}
      </div>
    </div>
  );
};
