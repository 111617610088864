import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";

import { Toast } from "primereact/toast";

import {
  ErrorDialog,
  ErrorDialogEmpty,
  ErrorDialogNoAuthorizedSet,
  ErrorDialogSet,
  ErrorDialogSetResponse,
} from "../../dialogs/ErrorDialog";
import { SectorsService } from "../../service/SectorsService";

//Components
import { DeleteDialog } from "./DeleteDialog";
import { DataTableGrid } from "./DataTableGrid";
import { EditDialog } from "./EditDialog";

const EMPTY_SECTOR = {
  secId: null,
  secNumber: "",
  traId: null,
  secPicpath: "",
};

export const Sectors = () => {
  const [stateErrorDialog, setStateErrorDialog] = useState(ErrorDialogEmpty);
  const [loading, setLoading] = useState(true);

  const [sector, setSector] = useState(EMPTY_SECTOR);
  const [sectors, setSectors] = useState(null);

  const [editDialogVisible, setEditDialogVisible] = useState(false);
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);

  const toast = useRef(null);
  const params = useParams();

  useEffect(() => {
    try {
      const load = async () => {
        setLoading(true);

        const sectors = new SectorsService();
        const res = await sectors.getAllByTrack(params.trackId);

        EMPTY_SECTOR.traId = params.trackId;
        setSector(EMPTY_SECTOR);
        setSectors(res);

        setLoading(false);
      };
      load();
    } catch (error) {
      setStateErrorDialog(ErrorDialogNoAuthorizedSet(true, error));
      console.log(error);
    }
  }, []);
  if (loading)
    return (
      <div className="loading-container">
        <h3>Loading...</h3>
      </div>
    );

  const deleteItem = () => {
    const sectorsService = new SectorsService();
    return sectorsService.deleteSector(sector.secId);
  };

  const findIndexById = (id, items) => {
    let index = -1;
    for (let i = 0; i < items.length; i++) {
      if (items[i].secId === id) {
        index = i;
        break;
      }
    }
    return index;
  };
  const changeItems = (item) => {
    let _sectors = [...sectors];
    let index = findIndexById(item.secId, _sectors);
    if (index > -1) {
      _sectors[index] = item;
      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "Sector Updated",
        life: 3000,
      });
    } else {
      _sectors.push(item);
      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "Sector Created",
        life: 3000,
      });
    }
    setSectors(_sectors);
  };
  const onDeleted = () => {
    let _items = sectors.filter((val) => val.secId !== sector.secId);
    setSectors(_items);
    setSector(EMPTY_SECTOR);
    toast.current.show({
      severity: "success",
      summary: "Successful",
      detail: "Sector Deleted",
      life: 3000,
    });
  };
  return (
    <div className="p-grid crud-demo">
      <div className="p-col-12">
        <div className="card ">
          <Toast ref={toast} />

          <DataTableGrid
            items={sectors}
            emptyItem={EMPTY_SECTOR}
            openDialog={(row) => {
              setSector({ ...row });
              setEditDialogVisible(true);
            }}
            openDeleteDialog={(rowData) => {
              setSector(rowData);
              setDeleteDialogVisible(true);
            }}
          ></DataTableGrid>

          <EditDialog
            visible={editDialogVisible}
            item={sector}
            onChange={changeItems}
            hideDialog={() => setEditDialogVisible(false)}
            setStateErrorDialog={setStateErrorDialog}
          />

          <DeleteDialog
            visible={deleteDialogVisible}
            item={{ id: sector.secId, name: sector.secNumber }}
            header="Delete sector"
            hideDialog={() => setDeleteDialogVisible(false)}
            deleteItem={deleteItem}
            onDeleted={onDeleted}
            setStateErrorDialog={setStateErrorDialog}
          />

          <ErrorDialog stateDialog={stateErrorDialog}></ErrorDialog>
        </div>
      </div>
    </div>
  );
};
