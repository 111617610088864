import { Panel } from "primereact/panel";
import { ScrollPanel } from "primereact/scrollpanel";
import React, { useEffect, useState } from "react";
import { DriverPositionTracker } from "../core/DriverPositionTracker";

export const CarBox = (props) => {
  let positionTracker = new DriverPositionTracker();

  const [dirvers, setDrivers] = useState([]);

  useEffect(() => {
    let filterDrivers = props.driverPositions.filter((driver) =>
      props.categories.includes(parseInt(driver.categoryId))
    );
    setDrivers(filterDrivers);
  }, [props.categories, props.driverPositions]);

  return (
    <div className="box">
      <Panel
        header={`Cars`}
        toggleable
        collapsed={props.carBoxCollapsed}
        onToggle={(e) => props.setCarBoxCollapsed(e.value)}
      >
        <ScrollPanel style={{ width: "100%", height: "400px" }}>
          {dirvers.map((car, index) => {
            const infoCar = positionTracker.getIconUrl(car, props.lockedColors);
            let iconUrl = infoCar.iconUrl;
            let textColor = infoCar.color;

            return (
              <div className="col-12" key={index}>
                <div
                  style={{
                    backgroundImage: `url(${iconUrl})`,
                    color: textColor,
                    textAlign: "center",
                    paddingTop: 8,
                    width: 30,
                    height: 30,
                    float: "left",
                    marginRight: 2,
                  }}
                >
                  {car.driverInfo.CarNumber}
                </div>
                <div style={{ paddingTop: 8 }}>
                  <div className="p-grid" style={{ paddingTop: 8 }}>
                    <div className="divLap col-2">Lap:</div>
                    <div className="divLap2 col-2">
                      {car.driverInfo?.Counter}
                    </div>
                    <div className="divSpeed col-3">Speed:</div>
                    <div className="divSpeed2 col-2">
                      {" "}
                      {car.position?.speed}{" "}
                    </div>
                    <div className="col-2"> km/h</div>
                  </div>
                </div>
                <br />
              </div>
            );
          })}
        </ScrollPanel>
      </Panel>
    </div>
  );
};
