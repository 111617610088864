import React, { useState, useEffect } from "react";

import classNames from "classnames";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { MultiSelect } from "primereact/multiselect";

import { $ } from "react-jquery-plugin";

import { ClubService } from "../service/ClubService";
import { EventService } from "../service/EventService";
import { TokenAuthService } from "../service/TokenAuthService";
import { CountryService } from "../service/CountryService";
import { CircuitsService } from "../service/CircuitsService";
//import { DriverCategoriesService } from "../service/DriverCategoriesService";
import { CategoriesService } from "../service/CategoriesService";

import {
  ErrorDialog,
  ErrorDialogEmpty,
  ErrorDialogSet,
  ErrorDialogSetResponse,
} from "../dialogs/ErrorDialog";

const defaultGeoRadius = process.env.REACT_APP_GEO_RADIUS;

export const EventDialog = (props) => {
  let emptyEvent = {
    eveId: null,
    stsId: null,
    eveName: "",
    eveStart: null,
    eveFinish: null,
    eveUpddate: null,
    eveDeleted: false,
    eveUpdUserId: null,
    cluId: null,
    couId: null,
    eveState: "",
    eveCity: "",
    eveCircuitName: "",
    eveGeoRadius: "",
    eveSafetyCarDelay: 7,
  };

  var language = navigator.language || navigator.userLanguage;
  let dateFormat = "mm/dd/yy";

  if (language.toLowerCase().indexOf("es") != -1) {
    dateFormat = "dd/mm/yy";
  }

  const [visible, setVisible] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [stateErrorDialog, setStateErrorDialog] = useState(ErrorDialogEmpty);

  const [event, setEvent] = useState(emptyEvent);
  const [clubs, setClubs] = useState(null);
  const [loading, setLoading] = useState(true);
  const [countries, setCountries] = useState(null);
  const [circuits, setCircuits] = useState(null);
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);

  const auth = new TokenAuthService();

  useEffect(() => {
    try {
      const load = async () => {
        setLoading(true);
        setVisible(props.visibleDialog);
        setSubmitted(false);

        const countryService = new CountryService();
        const resCountries = await countryService.getCountries();
        setCountries(resCountries);

        const clubService = new ClubService();
        const resClubs = await clubService.getClubsIdDescription();
        setClubs(resClubs);

        let clubId = null;

        let _event = { ...props.event };

        _event.eveGeoRadius = _event.eveGeoRadius ? _event.eveGeoRadius : "";
        _event.eveSafetyCarDelay = _event.eveSafetyCarDelay ? _event.eveSafetyCarDelay : 7;

        if (!auth.isAdmin() && props.event.ClubId === null) {
          const _club = await auth.getClub();
          const club = await clubService.getClub(_club.Id);
          _event.couId = club.couId;
          _event.eveState = club.cluState;
          _event.eveCity = club.cluCity;

          clubId = club.cluId;
        }

        const categoriesService = new CategoriesService();
        let resCategories = null;
        if (clubId) {
          resCategories = await categoriesService.getCategoriesByClub(clubId);
        } else {
          resCategories = await categoriesService.getCategoriesByEvent(
            props.event.eveId
          );
          setSelectedCategories(
            resCategories.filter((e) => e.selected != null).map((e) => e.Id)
          );
        }
        setCategories(resCategories);

        if (!_event.eveGeoRadius) _event.eveGeoRadius = defaultGeoRadius;

        setEvent(_event);
        setLoading(false);
      };
      load();
    } catch (error) {
      setStateErrorDialog(ErrorDialogSet(true, ""));
    }
  }, [props]);

  if (loading)
    return (
      <div className="loading-container">
        <h3>Loading...</h3>
      </div>
    );

  const saveItem = async () => {
    setSubmitted(true);

    if (!validateRequired()) {
      return;
    }

    try {
      let _event = { ...event };
      _event.categories = selectedCategories;
      if (!auth.isAdmin()) {
        let club = await auth.getClub();
        _event.cluId = club.Id;
      }

      if (event.eveId) {
        let eventService = new EventService();
        eventService
          .updateEvent(_event)
          .then((data) => {
            closeOk(_event);
          })
          .catch((res) => {
            setStateErrorDialog(ErrorDialogSetResponse(res));
            });
      } else {
        let eventService = new EventService();
        eventService
          .createEvent(_event)
          .then((data) => {
            _event.eveId = data.eveId;
            _event.stsId = data.stsId;
            closeOk(_event);
          })
          .catch((res) => {
            setStateErrorDialog(ErrorDialogSetResponse(res));
          });
      }
    } catch (error) {
      setStateErrorDialog(ErrorDialogSet(true, ""));
    }
  };

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _event = { ...event };
    _event[`${name}`] = val;
    setEvent(_event);
  };

  const onInputNumberChange = (e, name) => {
    const num = (e && e.value) || 0;
    let val = num;
    if(name === 'eveSafetyCarDelay'){
      val = event.eveSafetyCarDelay < 0 ? 0 : event.eveSafetyCarDelay > 7 ? 7 : num
    }
    let _event = { ...event };
    _event[`${name}`] = val;
    setEvent(_event);
  };

  const onInputChangeInstitution = async (e, name) => {
    const val = (e.target && e.target.value) || "";

    const clubService = new ClubService();
    const club = await clubService.getClub(e.value);

    let _event = { ...event };
    _event[`${name}`] = val;
    _event["couId"] = club.couId;
    _event["eveState"] = club.cluState;
    _event["eveCity"] = club.cluCity;

    setEvent(_event);

    let clubId = club.cluId;
    let resCategories = null;
    const categoriesService = new CategoriesService();
    if (clubId) {
      resCategories = await categoriesService.getCategoriesByClub(clubId);
    } else {
      resCategories = await categoriesService.getCategoriesByEvent(
        props.event.eveId
      );
    }
    setCategories(resCategories);

    setSelectedCategories(
      resCategories.filter((e) => e.selected != null).map((e) => e.Id)
    );
  };
  const validateRequired = () => {
    for (let k = 0; k < $("[Required]").length; k++) {
      if (!$("[Required]")[k].value) return false;
    }
    return true && selectedCategories.length > 0 && validateRadius() && validateSafetyDelay();
  };
  const formatDate = (d) => {
    if (d) d = new Date(d);
    return d;
  };

  const closeCancel = () => {
    props.onClose(null);
  };
  const closeOk = (_item) => {
    //alert("Hide");
    setSubmitted(false);
    props.onClose(_item);
  };
  const cancel = () => {
    setEvent(emptyEvent);
    closeCancel();
  };


  const dialogFooter = (
    <>
      <Button label="Cancel" className="btn-secondary" onClick={cancel} />
      <Button label="Save" className="btn-primary " onClick={saveItem} />
    </>
  );

  const validateRadius = () => {
    return event.eveGeoRadius !== 0;
  };
  const validateSafetyDelay = () => {
    const validDelay = event.eveSafetyCarDelay < 0 || event.eveSafetyCarDelay > 7
    return !validDelay
  };


  return (
    <>
      <Dialog
        visible={visible}
        style={{ width: "450px" }}
        header={event.eveId ? "Event Details" : "Create new event"}
        modal
        className="p-fluid"
        footer={dialogFooter}
        onHide={closeCancel}
      >
        <div className="p-field">
          <label htmlFor="name">Name</label>
          <InputText
            id="name"
            maxLength="100"
            value={event.eveName}
            onChange={(e) => onInputChange(e, "eveName")}
            required
            className={classNames({ "p-invalid": submitted && !event.eveName })}
          />
          {submitted && !event.eveName && (
            <small className="p-invalid">Name is required.</small>
          )}
        </div>
        {auth.isAdmin() && (
          <div className="p-field">
            <label htmlFor="club">Institution</label>
            <Dropdown
              id="club"
              optionLabel="Description"
              optionValue="Id"
              value={event.cluId}
              options={clubs}
              onChange={(e) => onInputChangeInstitution(e, "cluId")}
              required
              className={classNames({ "p-invalid": submitted && !event.cluId })}
            />
            {submitted && !event.cluId && (
              <small className="p-invalid">Institution is required.</small>
            )}
          </div>
        )}

        <hr />
        <p className="fs-m-normal p-mb-4">Location</p>

        <div className="p-grid">
          <div className="p-field p-col-6">
            <label htmlFor="country">Country</label>
            <Dropdown
              id="country"
              optionLabel="couName"
              optionValue="couId"
              value={event.couId}
              options={countries}
              onChange={(e) => onInputChange(e, "couId")}
              required
              className={classNames({ "p-invalid": submitted && !event.couId })}
            />
            {submitted && !event.couId && (
              <small className="p-invalid">Country is required.</small>
            )}
          </div>
          <div className="p-field p-col-6">
            <label htmlFor="name">State</label>
            <InputText
              id="state"
              maxLength="100"
              value={event.eveState}
              onChange={(e) => onInputChange(e, "eveState")}
              required
              className={classNames({
                "p-invalid": submitted && !event.eveState,
              })}
            />
            {submitted && !event.eveState && (
              <small className="p-invalid">State/Province is required.</small>
            )}
          </div>
        </div>

        <div className="p-field">
          <label htmlFor="name">City</label>
          <InputText
            id="city"
            maxLength="100"
            value={event.eveCity}
            onChange={(e) => onInputChange(e, "eveCity")}
            required
            className={classNames({ "p-invalid": submitted && !event.eveCity })}
          />
          {submitted && !event.eveCity && (
            <small className="p-invalid">City is required.</small>
          )}
        </div>
        <div className="p-grid">
          <div className="p-field p-col-6">
            <label htmlFor="circuitName">Circuit Name</label>
            <InputText
              id="circuitName"
              maxLength="100"
              value={event.eveCircuitName}
              onChange={(e) => onInputChange(e, "eveCircuitName")}
              required
              className={classNames({
                "p-invalid": submitted && !event.eveCircuitName,
              })}
            />
            {submitted && !event.eveCircuitName && (
              <small className="p-invalid">Circuit Name is required.</small>
            )}
          </div>
          <div className="p-field p-col-6">
            <label htmlFor="categories">Categories</label>
            <MultiSelect
              id="categories"
              optionLabel="Description"
              optionValue="Id"
              value={selectedCategories}
              options={categories}
              onChange={(e) => setSelectedCategories(e.value)}
              className={classNames({
                "p-invalid": submitted && selectedCategories.length === 0,
              })}
            />
            {submitted && selectedCategories.length === 0 && (
              <small className="p-invalid">Categories is required.</small>
            )}
          </div>
        </div>

        <hr />
        <p className="fs-m-normal p-mb-4">Date</p>

        <div className="p-grid" style={{ marginBottom: "120px" }}>
          <div className="p-field p-col-6">
            <label htmlFor="start">Start</label>
            <Calendar
              id="start"
              value={formatDate(event.eveStart)}
              dateFormat={dateFormat}
              onChange={(e) => onInputChange(e, "eveStart")}
              required
              showIcon={true}
              className={classNames({
                "p-invalid": submitted && !event.eveStart,
              })}
            />
            {submitted && !event.eveStart && (
              <small className="p-invalid">Start is required.</small>
            )}
          </div>
          <div className="p-field p-col-6">
            <label htmlFor="finish">Finish</label>
            <Calendar
              id="finish"
              value={formatDate(event.eveFinish)}
              dateFormat={dateFormat}
              onChange={(e) => onInputChange(e, "eveFinish")}
              required
              showIcon={true}
              className={classNames({
                "p-invalid": submitted && !event.eveFinish,
              })}
            />
            {submitted && !event.eveFinish && (
              <small className="p-invalid">Finish is required.</small>
            )}
          </div>

          <div className="p-field p-col-6">
            <label htmlFor="name">Finish Radius</label>
            <div
              className="p-d-flex  p-ai-center "
              style={{ position: "relative" }}
            >
              <InputNumber
                id="radius"
                value={event.eveGeoRadius}
                onChange={(e) => onInputNumberChange(e, "eveGeoRadius")}
                tooltip="The Finish Radius is the maximum distance around the Finish Line to consider that a competitor has crossed it"
                className={classNames({
                  "p-invalid": submitted && !validateRadius(),
                })}
                required
              />{" "}
              meters
              <div
                style={{
                  marginLeft: "10px",
                  position: "absolute",
                  right: 60,
                }}
              >
                <i className="pi pi-info-circle" />
              </div>
            </div>
            {submitted && !validateRadius() && (
              <small className="p-invalid">Finish Radius is required.</small>
            )}
          </div>

          <div className="p-field p-col-6">
            <label htmlFor="name">Safety Car Delay</label>
            <div
              className="p-d-flex  p-ai-center "
              style={{ position: "relative" }}
            >
              <InputNumber
                id="scDelay"
                value={event.eveSafetyCarDelay}
                onChange={(e) => onInputNumberChange(e , "eveSafetyCarDelay")}
                tooltip="Time in seconds before the safety car flag is deployed"
                className={classNames({
                  "p-invalid": submitted && !validateSafetyDelay(),
                })}
                required
              />{" "}
              seconds
              <div
                style={{
                  marginLeft: "10px",
                  position: "absolute",
                  right: 60,
                }}
              >
                <i className="pi pi-info-circle" />
              </div>
            </div>
            {!validateSafetyDelay() && (
              <small className="p-invalid">Safety car Delay must be a value between 0 and 7</small>
            )}
          </div>

        </div>
      </Dialog>

      <ErrorDialog stateDialog={stateErrorDialog}></ErrorDialog>
    </>
  );
};
