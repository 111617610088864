import { SOCKET_EVENTS } from "../consts/socketEvents";
import socket from "./Socket";
import React, { useEffect, useRef, useState, createRef } from "react";
// const INTERVAL_TIME_TO_GET_OFFLINE_CARS = 2000;
const INTERVAL_TIME_TO_GET_OFFLINE_CARS =
  process.env.REACT_APP_INTERVAL_TIME_TO_GET_OFFLINE_CARS;

const useEvent = (
  eventId,
  cbUpdateNewDriverPosition,
  cbAlertReceived,
  cbUpdateCounter,
  cbConfirmFlagReceived
) => {
  const offlineCarTimerId = useRef(null);

  useEffect(() => {
    socket.on("BACKOFFICE", async (result) => {
      if (eventId != result.data.eventId) return;
      switch (result.type) {
        case SOCKET_EVENTS.CHANGE_POSITION:
          cbUpdateNewDriverPosition(result.data);
          break;
        case SOCKET_EVENTS.DRIVER_ALERT:
          await cbAlertReceived(result.data);
          break;
        case SOCKET_EVENTS.UPDATE_COUNTER:
          await cbUpdateCounter(result.data);
          break;
        case SOCKET_EVENTS.FLAG_CONFIRMED:
          await cbConfirmFlagReceived(result.data);
          break;
        default: {
          break;
        }
      }
    });
    return () => {
      socket.off();
      if (offlineCarTimerId.current) {
        clearInterval(offlineCarTimerId.current);
        offlineCarTimerId.current = null;
      }
    };
  }, []);


  useEffect(() => {
    socket.on("connect_error", (e) => {
      setTimeout(() => {
        socket.connect();
        console.log("", "Server connection error, connecting...");
      }, 3000);
    });
  }, []);


  const startShowOfflineCars = async (cbUpdateOfflineCars) => {
    // offlineCarTimerId.current = setInterval(async () => {
    //   await cbUpdateOfflineCars();
    // }, INTERVAL_TIME_TO_GET_OFFLINE_CARS);
  };

  return {
    startShowOfflineCars,
  };
};

export default useEvent;
