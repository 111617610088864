import { React } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

export const AlertMessageBox = (props) => {
  var footer = (
    <div>
      <Button
        label="Ok"
        className="btn-primary "
        onClick={(e) => props.setAlertMessageBox("")}
      />
    </div>
  );

  return (
    <Dialog
      header={"Alert"}
      className="p-fluid"
      visible={props.alertMessageBox !== ""}
      contentClassName="overflow-y-visible"
      footer={footer}
      onHide={() => props.setAlertMessageBox("")}
    >
      <span>{props.alertMessageBox}</span>
    </Dialog>
  );
};
