import { Panel } from "primereact/panel";
import { ScrollPanel } from "primereact/scrollpanel";

export const OfflineDrivers = (props) => {
  return (
    <>
      {props.offlineCars.length > 0 && (
        <div className="box" data-html2canvas-ignore>
          <Panel
            header={`Offline cars ${props.carsTotal.offlineCars}/${props.carsTotal.total}`}
            toggleable
            collapsed={props.offlineCollapsed}
            onToggle={(e) => props.setOfflineCollapsed(e.value)}
          >
            <ScrollPanel style={{ width: "100%", height: "400px" }}>
              <ul className={"car-list"}>
                {props.offlineCars.map((car, index) => {
                  return (
                    <li className="map-item border-bottom" key={index}>
                      {car.carnumber} - {car.name} {car.surname}
                    </li>
                  );
                })}
              </ul>
            </ScrollPanel>
          </Panel>
        </div>
      )}
    </>
  );
};
