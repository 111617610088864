import html2canvas from "html2canvas";

const exportAsImage = async (element, imageFileName, scale, extraWidth = 0,  extraHeight = 0) => {
    const canvas = await html2canvas(element, {backgroundColor:null, useCORS:true, allowTaint:true, logging:true, height:(scale * 2) + extraHeight, width: (scale * 2)+extraWidth});
    const image = canvas.toDataURL("image/png", 1.0);
    
    downloadImage(image, imageFileName);
};

const downloadImage = (blob, fileName) => {

    const fakeLink = window.document.createElement("a");
    fakeLink.style = "display:none;";
    fakeLink.download = fileName;
    
    fakeLink.href = blob;
    
    document.body.appendChild(fakeLink);
    fakeLink.click();
    document.body.removeChild(fakeLink);
    
    fakeLink.remove();
};
    
export default exportAsImage;