import { useRef, useState, createRef } from "react";
import { EventService } from "../service/EventService";
import { TracksService } from "../service/TracksService";
import { MapService } from "../service/MapService";
import { CategoriesService } from "../service/CategoriesService";
import useDriver from "./useDriver";
const ALL_CATEGORIES = 999;
const useTracker = ({ eventId }) => {
  const [selectedCategories, setSelectedCategories] = useState([
    ALL_CATEGORIES,
  ]);

  const eventService = new EventService();
  const trackService = new TracksService();
  const mapService = new MapService();
  const categoryServices = new CategoriesService();

  const { driverTrakerByCategories } = useDriver({ eventId });

  const initializeAll = async (cbShowToast) => {
    let _event = await eventService.getEvent(eventId);
    let res = await trackService.getTrackPointsById(_event.traId);

    let mapData = [
      { coords: null, ref: createRef() },
      { coords: null, ref: createRef() },
      { coords: null, ref: createRef() },
      { coords: null, ref: createRef() },
    ];

    let shapes = {
      PointsTrack: null,
      pointsMetaStart: null,
      pointsMetaFinish: null,
      PointsBoxes: null,
    };

    let mapPosition = {};

    if (res) {
      let pointsMetaStart =
        res.shapes.PointsMetaStart.length > 0
          ? res.shapes.PointsMetaStart[0]
          : null;

      let pointsMetaFinish =
        res.shapes.PointsMetaFinish.length > 0
          ? res.shapes.PointsMetaFinish[0]
          : null;

      mapData = [
        { coords: res.shapes.PointsTrack, ref: createRef() },
        { coords: pointsMetaStart, ref: createRef() },
        { coords: pointsMetaFinish, ref: createRef() },
        { coords: res.shapes.PointsBoxes, ref: createRef() },
      ];

      shapes = {
        PointsTrack: res.shapes.PointsTrack,
        pointsMetaStart,
        pointsMetaFinish,
        PointsBoxes: res.shapes.PointsBoxes,
      };

      mapPosition = res.mapPosition;
    }

    let drivers = await driverTrakerByCategories(selectedCategories);

    let pois = await initializePois(cbShowToast);

    let newCategories = await getCategoriesByEvent();

    return {
      drivers,
      mapData,
      shapes,
      trackId: _event.traId,
      scDelay: _event.eveSafetyCarDelay,
      mapPosition: mapPosition,
      pois,
      newCategories,
    };
  };

  const initializePois = async (cbShowToast) => {
    const pois = [];
    try {
      var response = await mapService.getPoisByEventId(eventId);
      response.data.pois.forEach((item, index) => {
        var data = {
          lat: parseFloat(item.Lat),
          lng: parseFloat(item.Long),
          index,
          isOpen: false,
          input: createRef(),
          description: item.Description,
          id: item.Id,
          icon: item.Icon,
        };
        pois.push(data);
      });
    } catch (error) {
      cbShowToast(``, "An error has occurred trying to get the pois", "error");
      console.log(error);
    }
    return pois;
  };

  //   const getCategories = async () => {
  //     const categories = await categoryServices.getCategories(eventId);
  //     return categories.filter((e) => e.selected != null);
  //   };

  const getCategoriesByEvent = async () => {
    const categories = await categoryServices.getCategoriesByEvent(eventId);
    return categories.filter((e) => e.selected != null);
  };

  const getOfflineCars = async (categories) => {
    let offlineDrivers = await mapService.getOfflineDrivers(
      eventId,
      categories
    );
    return {
      total: offlineDrivers.data.total,
      offlineDrivers: offlineDrivers.data.drivers,
    };
  };

  return {
    initializeAll,
    getCategoriesByEvent,
    getOfflineCars,
    initializePois,
  };
};

export default useTracker;
