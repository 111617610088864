import React, { createRef, useRef, useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

export const ConfirmMessageBox = (props) => {
  var footer = (
    <div>
      <Button
        label={props.yesno ? "No" : "Cancel"}
        className="btn-secondary"
        onClick={() => props.close(false)}
      />
      <Button
        label={props.yesno ? "Yes" : "Ok"}
        className="btn-primary "
        onClick={(e) => props.close(true)}
      />
    </div>
  );

  return (
    <Dialog
      header={props.header || " Confirm"}
      className="p-fluid"
      visible={props.visible}
      contentClassName="overflow-y-visible"
      footer={footer}
      onHide={() => props.close(false)}
    >
      <span>{props.message}</span>
    </Dialog>
  );
};
