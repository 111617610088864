import moment from "moment";
import { Button } from "primereact/button";
import React, { useEffect, useState, useRef } from "react";
import { flag_types } from "../../utilities/const";
import { useAppContext } from "../../AppContext";

const CLEAR_BTN = "Clear SOS";

export const TrackerMenu = (props) => {
  const [timer, setTimer] = useState(moment().format("HH:mm:ss"));
  const [statesText, setStatesText] = useState("");
  const timerId = useRef(null);

  const sendFlag = (code) => {
    props.sendFlag(code);
  };

  /*useEffect(() => {
    const load = async () => {
      let stages = "";
      if (props.route.Stages?.length > 0) {
        props.route?.Stages.forEach((item) => {
          stages = `${stages} / SS ${item.Name}`;
        });
        setStatesText(stages.substring(2));
      }
    };
    load();
  }, [props.route, setStatesText]);*/

  useEffect(() => {
    const load = async () => {
      timerId.current = setInterval(() => {
        setTimer(moment().format("HH:mm:ss"));
      }, 1000);
    };
    load();

    return () => {
      if (timerId.current) {
        clearInterval(timerId.current);
        timerId.current = null;
      }
    };
  }, []);

const {toggleSidebar} = useAppContext();

  return (
    <>
      {props.showTrackerMenu && (
        <div className="menu-edit-map p-jc-between">
          <div className="p-d-flex p-ai-center">
            <Button
                icon="pi pi-bars"
                className="p-button-rounded p-button-outlined p-button-icon-only p-mr-3"
                onClick={() => {toggleSidebar()}}
            />
            <Button
              icon="pi pi-chevron-left"
              className="p-button-rounded  p-button-outlined p-button-icon-only p-mr-3"
              onClick={props.back}
            />
            {props.isDirector && (
              <>
                {props.showMenu && (
                  <>
                    <Button
                      label={""}
                      className="btn-outline-invert2"
                      style={{ minWidth: "0rem", marginLeft: "5px" }}
                      onClick={() => props.clearSelectedDrivers()}
                    >
                      <div
                        className={"pi pi-fw pi-times"}
                        style={{ cursor: "pointer" }}
                      ></div>
                    </Button>
                  </>
                )}
              </>
            )}
          </div>

          <div className="p-d-flex p-ai-center">
            {props.isDirector && (
              <>
                <div className="p-grid" style={{ paddingBottom: 0 }}>
                  <Button
                    label="Message"
                    className="btn-outline-invert2 p-mr-3"
                    style={{
                      minWidth: "0rem",
                      padding: "0.25rem",
                      fontSize: "0.775rem",
                    }}
                    onClick={() => {
                      sendFlag(flag_types.CUTOMMESSAGE);
                    }}
                  />

                  <Button
                    label="Green"
                    className="btn-flag p-mr-1 p-col-0"
                    onClick={() => {
                      sendFlag(flag_types.GREEN);
                    }}
                  >
                    <div className="flag-container">
                      <img src="../../assets/flags/green.jpg" alt="Green" />
                    </div>
                  </Button>

                  <Button
                    label="Yellow"
                    className="btn-flag p-mr-1 p-col-0"
                    onClick={() => {
                      sendFlag(flag_types.YELLOW);
                    }}
                  >
                    <div className="flag-container">
                      <img src="../../assets/flags/yellow.jpg" alt="Yellow" />
                    </div>
                  </Button>

                  <Button
                    label="D Yellow"
                    className="btn-flag p-mr-1 p-col-0"
                    onClick={() => {
                      sendFlag(flag_types.DOUBLEYELLOW);
                    }}
                  >
                    <div className="flag-container">
                      <img
                        src="../../assets/flags/double-yellow.jpg"
                        alt="Double Yellow"
                      />
                    </div>
                  </Button>

                  <Button
                    label="Surface"
                    className="btn-flag p-mr-1 p-col-0"
                    onClick={() => {
                      sendFlag(flag_types.LOWADHESION);
                    }}
                  >
                    <div className="flag-container">
                      <img src="../../assets/flags/surface.jpg" alt="Surface" />
                    </div>
                  </Button>

                  <Button
                    label="S/C"
                    className="btn-flag p-mr-1 p-col-0"
                    onClick={() => {
                      sendFlag(flag_types.SAFETYCAR);
                    }}
                  >
                    <div className="flag-container">
                      <img src="../../assets/flags/sc.jpg" alt="S/C" />
                    </div>
                  </Button>

                  <Button
                    label="Red"
                    className="btn-flag p-mr-1 p-col-0"
                    onClick={() => {
                      sendFlag(flag_types.REDFLAG);
                    }}
                  >
                    <div className="flag-container">
                      <img src="../../assets/flags/red.jpg" alt="Red" />
                    </div>
                  </Button>

                  <Button
                    label="Check"
                    className="btn-flag p-mr-3 p-col-0"
                    onClick={() => {
                      sendFlag(flag_types.CHECKERED);
                    }}
                  >
                    <div className="flag-container" style={{width: 42.66, height: 32}}>
                      <img src="/assets/flags/checkered.png" alt="Nueva" />
                    </div>
                  </Button>

                  <Button
                    label="White"
                    className="btn-flag p-mr-1 p-col-0"
                    onClick={() => {
                      sendFlag(flag_types.WHITE);
                    }}
                  >
                    <div className="flag-container">
                      <img src="../../assets/flags/white.jpg" alt="White" />
                    </div>
                  </Button>

                  <Button
                    label="Penalty"
                    className="btn-flag p-mr-1 p-col-0"
                    onClick={() => {
                      sendFlag(flag_types.PENALTY);
                    }}
                  >
                    <div className="flag-container">
                      <img src="../../assets/flags/penalty.jpg" alt="Penalty" />
                    </div>
                  </Button>

                  <Button
                    label="Warning"
                    className="btn-flag p-mr-1 p-col-0"
                    onClick={() => {
                      sendFlag(flag_types.BLACKANDWHITE);
                    }}
                  >
                    <div className="flag-container">
                      <img src="../../assets/flags/warning.jpg" alt="warning" />
                    </div>
                  </Button>

                  <Button
                    label="Black"
                    className="btn-flag p-mr-3 p-col-0"
                    onClick={() => {
                      sendFlag(flag_types.BLACK);
                    }}
                  >
                    <div className="flag-container">
                      <img src="../../assets/flags/black.jpg" alt="Black" />
                    </div>
                  </Button>
                  <Button
                    label={CLEAR_BTN}
                    className="btn-outline-invert2 p-mr-2"
                    style={{
                      minWidth: "0rem",
                      padding: "0.25rem",
                      fontSize: "0.775rem",
                    }}
                    onClick={() => props.cleanFlags()}
                  />
                  <Button
                    label={props.poiHideShowText}
                    className="btn-outline-invert2 p-mr-2"
                    style={{
                      minWidth: "0rem",
                      padding: "0.25rem",
                      fontSize: "0.775rem",
                    }}
                    onClick={() => props.hidePois()}
                  />
                  <Button
                    label="Reset Laps"
                    className="btn-outline-invert2 p-mr-2"
                    style={{
                      minWidth: "0rem",
                      padding: "0.25rem",
                      fontSize: "0.775rem",
                    }}
                    onClick={() => props.resetLaps()}
                  />
                </div>
              </>
            )}
            {!props.isDirector && (
              <Button
                label={props.poiHideShowText}
                className="btn-outline-invert2 p-mr-2"
                onClick={() => props.hidePois()}
              />
            )}
            {<span className="timer">{timer}</span>}
          </div>
        </div>
      )}
    </>
  );
};
