import moment from "moment";
import { ALERT_TYPES } from "../consts/alertTypes";
import { FLAGS } from "../consts/flags";
import { GoogleMapTool } from "../core/GoogleMapTool";
import { flag_types } from "../utilities/const";
import useDriver from "./useDriver";
import useEvent from "./useEvent";

let lockedColorDriversRef = { current: {} };
const delayedFlags = [ FLAGS.YELLOW, FLAGS.DOUBLEYELLOW, FLAGS.LOWADHESION, FLAGS.SAFETYCAR, FLAGS.RED, FLAGS.WHITE, FLAGS.PENALTY, FLAGS.BLACK, FLAGS.BLACKANDWHITE];


const useDrawDriver = ({ eventId }) => {

  const LOWADHESION = "circle-lowadhesion.svg";
  const WHITE_CIRCLE = "circle-white.svg";
  const GREEN_CIRCLE = "circle-green.svg";
  const YELLOW_CIRCLE = "circle-yellow-flag.svg";
  const DOUBLE_YELLOW_CIRCLE = "circle-double-yellow.svg";

  const WARNING_CIRCLE = "circle-warning.svg";
  const PENALTY_CIRCLE = "circle-penalty.svg";
  const BLACK_CIRCLE = "circle-black.svg";

  const SAFETYCAR_ICON_URL = "circle-sc.svg";
  const RED_CIRCLE = "circle-red.svg";

  const GRAY_CIRCLE = WHITE_CIRCLE;
  const OFFLINE_CAR = "offline-circle.svg";
  const STOPPED_CAR = "stopped-circle.svg";
  const TOLERANCE_LEVEL = 20e-5;
  const RED_CIRCLE_SOS = "circle-red-black.svg";
  const WAIT_TIME = 10;
  const ALL_CATEGORIES = 999;
  const DIFF_OFFLINE_SECONDS = 15;

  const { getInfoDriver } = useDriver({ eventId });

  useEvent(eventId, () => null, () => null, () => null, (flag) => {
    updateLockedColors(flag);
  });

  const updateLockedColors = (flag) => {
    lockedColorDriversRef.current = {
      ...lockedColorDriversRef.current,
      [flag.userId]: flag.state,
    };

    // logica para borrar las banderas cada 4 segundos
    // setTimeout(() => {
    //   lockedColorDriversRef.current[id] = null;
    // }, 4000);
  };


  const mapTools = new GoogleMapTool();

  const drawDriverTags = async (drivers, currentDrivers, map) => {
    let newDrivers = [];
    for await (let driver of drivers) {
      var existDriver = currentDrivers.find(
        (item) =>
          item.id == driver.userId && item.categoryId == driver.categoryId
      );
      let offline = false;
      let lastHours = moment(driver.lastUpdate).utc().local();
      let nowHours = moment(new Date());

      let diffSeconds = nowHours.diff(lastHours, "seconds", true);
      if (diffSeconds > DIFF_OFFLINE_SECONDS) {
        offline = true;
      }

      if (existDriver) {
        existDriver.offline = offline;
        updateMarker(existDriver, driver, false);
      } else {
        driver.offline = offline;
        let newDriver = await createMarker(driver, map);
        newDrivers.push(newDriver);
      }
    }
    return { newDrivers };
  };

  const updateDriverTag = async (driverPosition, drivers, map) => {
    let newDriver = null;

    let existDriver = drivers.find(
      (item) =>
        parseInt(item.userId) == parseInt(driverPosition.userId) &&
        parseInt(item.categoryId) == parseInt(driverPosition.categoryId)
    );

    let newPosition = {
      lat: parseFloat(driverPosition.position.lat),
      lng: parseFloat(driverPosition.position.lng),
      speed: driverPosition.position.speed,
    };

    if (existDriver) {
      let stoped = false;
      let lastUpdate = existDriver.lastUpdate;
      let oldPosition = existDriver.oldPosition;

      if (
        mapTools.checkTimeHasPassed(existDriver.lastUpdate, WAIT_TIME) &&
        existDriver.oldPosition
      ) {
        let isSamePosition = mapTools.isSamePosition(
          newPosition,
          existDriver.oldPosition
        );

        if (!isSamePosition) {
          lastUpdate = new Date();
          oldPosition = newPosition;
          stoped = false;
        } else {
          stoped = true;
        }
      }
      existDriver.stoped = stoped;
      existDriver.lastUpdate = lastUpdate || new Date();
      existDriver.oldPosition = oldPosition || newPosition;
      existDriver.position = newPosition;
      existDriver.offline = false;
      if (driverPosition.lastFlagStatus !== flag_types.CUTOMMESSAGE) {
        existDriver.lastFlagStatus = driverPosition.lastFlagStatus;
      }
      updateMarker(existDriver, { position: newPosition }, false);
      updateInfoWindow(existDriver);
    } else {
      newDriver = {
        ...driverPosition,
        position: newPosition,
      };
      newDriver = await createMarker(newDriver, map);
    }
    return newDriver;
  };

  const updateMarker = (existDriver, driver, notPosition) => {
    if (!notPosition) existDriver.marker.setPosition(driver.position);

    const driverIcon = getIconUrl(existDriver);

    let label = {
      text: `${existDriver.driverInfo.CarNumber}`,
      color: driverIcon.color,
    };

    existDriver.marker.setLabel(label);
    existDriver.marker.setIcon(driverIcon.iconUrl);
  };

  const createMarker = async (driver, map) => {
    const driverIcon = getIconUrl(driver);

    let driverInfo = await getInfoDriver(driver.userId, driver.categoryId);

    let label = {
      text: `${driverInfo.CarNumber}`,
      color: driverIcon.color,
    };

    const marker = new window.google.maps.Marker({
      position: driver.position,
      map: map,
      id: driver.userId,
      animation: null,
      icon: driverIcon.iconUrl,
      label: label,
      categoryId: driver.categoryId,
    });

    driver.driverInfo = { ...driverInfo, LastPosition: driver.position };
    driver.isOpen = true;

    let speed =
      driver.driverInfo?.LastPosition?.speed ||
        driver.driverInfo?.LastPosition?.speed == 0
        ? parseFloat(driver.driverInfo.LastPosition.speed).toFixed(0)
        : "Not informed";

    let counter =
      driverInfo.Counter === 0 || driverInfo.Counter
        ? driverInfo.Counter
        : "Not informed";

    const infowindow = new window.google.maps.InfoWindow({
      content: `<div className="info-window-body" style="color:black">

                  <div>${driver.driverInfo?.Pilot || "Not informed"}</div>
                  <div>Copilot: ${driver.driverInfo?.Copilot || "Not informed"
        }</div>
                  <div>Category: ${driver.driverInfo?.Category}</div>

                  <div>LAP ${counter}</div>
                  <div>Speed: ${speed}</div>

                  </div>`,

      //<div>LAST KNOWN LOCATION ${lat};${lng}</div>
      //<div>CAR ${pos.driverInfo?.CarNumber || "Not informed"}</div>
    });

    marker.addListener("click", async () => {
      infowindow.open({
        anchor: marker,
        map,
        title: "Racer",
      });
    });

    driver.infowindow = infowindow;
    driver.marker = marker;

    return driver;
  };

  const updateInfoWindow = (driver) => {
    const infoWindow = driver.infowindow;

    let counter =
      driver.driverInfo.Counter === 0 || driver.driverInfo.Counter
        ? driver.driverInfo.Counter
        : "Not informed";

    let content = `<div className="info-window-body" style="color:black">

        <div>${driver.driverInfo?.Pilot || "Not informed"}</div>
        <div>Copilot: ${driver.driverInfo?.Copilot || "Not informed"}</div>
        <div>Category: ${driver.driverInfo?.Category}</div>
        <div>Lap: ${counter}</div>
        <div>Speed: ${driver.position?.speed}</div>
        </div>`;

    infoWindow.setContent(content);
  };

  const getIconUrl = (pos) => {
    let iconUrl = pos.icon;
    let color = "#FFFFFF";
    const shouldLockFlag = lockedColorDriversRef.current[pos.userId];
    let flagStatus = shouldLockFlag ? lockedColorDriversRef.current[pos.userId] : pos.lastFlagStatus;


    if (pos.lastAlertType === ALERT_TYPES.SOS) {
      iconUrl = RED_CIRCLE_SOS;
    } else {
      if (pos.offline) {
        iconUrl = OFFLINE_CAR;
      } else {
        if (!shouldLockFlag && pos.stoped && parseInt(flagStatus) != FLAGS.RED) {
          iconUrl = STOPPED_CAR;
        } else {
          if (flagStatus || shouldLockFlag) {
            switch (parseInt(flagStatus)) {
              case FLAGS.RED:
                iconUrl = RED_CIRCLE;
                break;
              case FLAGS.SAFETYCAR:
                iconUrl = SAFETYCAR_ICON_URL;
                break;
              case FLAGS.YELLOW:
                iconUrl = YELLOW_CIRCLE;
                color = "#000000";
                break;
              case FLAGS.DOUBLEYELLOW:
                iconUrl = DOUBLE_YELLOW_CIRCLE;
                break;
              case FLAGS.GREEN:
                iconUrl = GREEN_CIRCLE;
                color = "#000000";
                break;
              case FLAGS.WHITE:
                iconUrl = WHITE_CIRCLE;
                color = "#000000";
                break;
              case FLAGS.LOWADHESION:
                iconUrl = LOWADHESION;
                color = "#000000";
                break;
              case FLAGS.PENALTY:
                iconUrl = PENALTY_CIRCLE;
                break;
              case FLAGS.BLACK:
                iconUrl = BLACK_CIRCLE;
                break;
              case FLAGS.BLACKANDWHITE:
                iconUrl = WARNING_CIRCLE;
                break;
              default:
                break;
            }
          }
        }
      }
    }

    if (iconUrl == null) {
      iconUrl = GREEN_CIRCLE;
    }
    return { iconUrl, color };
  };

  const hidePositionByCategories = (drivers, selectedCategories) => {
    drivers.forEach((driver) => {
      if (!selectedCategories.includes(driver.categoryId)) {
        driver.marker.setMap(null);
      }
    });
  };

  const showPositionsByCategories = (drivers, selectedCategories, map) => {
    drivers.forEach((driver) => {
      if (
        selectedCategories.includes(parseInt(driver.categoryId)) ||
        selectedCategories.includes(ALL_CATEGORIES)
      ) {
        driver.marker.setMap(map);
      }
    });
  };

  const changeOfflineDrivers = (drivers, offlineDrivers, map) => {
    offlineDrivers.forEach((driver) => {
      let offlineDriver = drivers.find(
        (d) => d.categoryId == driver.categoryId && d.userId == driver.userId
      );

      if (offlineDriver) {
        offlineDriver.offline = true;
        updateMarker(offlineDriver, null, true);
      }
    });
  };


  const changeCircleOnDrivers = (drivers, selectedDrivers, code) => {

    drivers.forEach((driver) => {
      if (code == FLAGS.BLACKANDWHITE) {
        let exists = selectedDrivers.find((invId) => invId == driver.invId);
        if (exists) driver.lastFlagStatus = code;
      }

      driver.lastFlagStatus = code;

      // if (!lockedColorDriversRef.current[driver.userId] && delayedFlags.includes(+code)) {
      //   updateLockedColors({userId: driver.userId, state: code});
      // }

      driver.animation = null;
      driver.icon = null;

      updateMarker(driver, null, true);
    });
  };

  const processAlert = (alert, drivers, map) => {
    const driver = drivers.find(
      (d) =>
        d.userId == alert.driver.User.Id && d.categoryId == alert.categoryId
    );
    if (driver) {
      driver.lastAlertType = alert.alertType;
      updateMarker(driver, null, true);
    }
  };

  const updateCounter = async (counter, drivers) => {
    const driver = drivers.find(
      (d) => d.userId == counter.userId && d.categoryId == counter.categoryId
    );
    if (driver) {
      driver.counter = counter.counter;
      driver.driverInfo.Counter = counter.counter;
      updateInfoWindow(driver);
    }
  };

  const clearSOSDriver = (drivers) => {
    drivers.forEach(async (driver) => {
      driver.lastAlertType = null;
      updateMarker(driver, null, true);
    });
  };

  const drawDriversOnSpecificMap = (drivers, map) => {
    console.log(drivers);
    drivers.forEach((driver) => {
      createMarker(driver, map);
    });
  };

  return {
    drawDriverTags,
    updateDriverTag,
    hidePositionByCategories,
    showPositionsByCategories,
    changeOfflineDrivers,
    changeCircleOnDrivers,
    processAlert,
    updateCounter,
    clearSOSDriver,
    drawDriversOnSpecificMap,
    lockedColorDriversRef
  };
};

export default useDrawDriver;
