import React, { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { useHistory } from "react-router-dom";

export const TracksDataTable = (props) => {
  const history = useHistory();
  const [globalFilter, setGlobalFilter] = useState(null);

  const goMapEdit = (id) => {
    if (id) {
      history.push("/mapTrack/" + id);
    }
  };

  const nameBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title txt-bold">{rowData.traName}</span>
      </>
    );
  };

  const mapEditBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title txt-bold">
          <Button
            label="View detail"
            className="p-button-text no-decoration txt-clrLight200 p-mr-4"
            onClick={() => goMapEdit(rowData.traId)}
          />
        </span>
      </>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-pencil"
          className="p-mr-1"
          onClick={() => props.openDialog(rowData)}
        />
        <Button
          icon="pi pi-trash"
          className="btn-alert"
          onClick={() => props.openDeleteDialog(rowData)}
        />
      </div>
    );
  };

  const header = (
    <div className="table-header p-mb-5">
      <h1 className="p-m-0 fs-x-normal">Tracks</h1>
      <div>
        <span className="p-input-icon-right p-mr-4">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Search Track"
          />
        </span>
        <Button
          label="New Track"
          icon="pi pi-plus"
          className="btn-primary"
          onClick={() => props.openDialog(props.emptyItem)}
        />
      </div>
    </div>
  );

  return (
    <DataTable
      value={props.items}
      dataKey="traId"
      paginator
      rows={10}
      rowsPerPageOptions={[5, 10, 25]}
      className="datatable-responsive table-layout-auto"
      globalFilter={globalFilter}
      emptyMessage="No Tracks found."
      header={header}
    >
      <Column field="traName" header="NAME" sortable body={nameBodyTemplate} />
      <Column body={mapEditBodyTemplate} />
      <Column body={actionBodyTemplate} className="is-last" />
    </DataTable>
  );
};
