import { Dialog } from "primereact/dialog";
import { useEffect, useRef } from "react";
import exportAsImage from "../../pages/exportAsImage";
import moment from "moment";

export const SnapshotModal = (props) => {
  const MAX_SCALE = 400;
  const MAX_GRADE = 180;
  const ROTATION_ANGLE_FOR_IMG_TXT = 7.71;
  const driverRef = useRef([]);
  const exportRef = useRef();
  const timeRef = useRef(moment().format("MM/DD/YYYY HH:mm:ss"));
  const MAX_DECIMAL_IN_LAT_AND_LNG = 10000;
  const POLYLINE_LINE_WIDTH = 2;

  const canvasRef = useRef(null);

  useEffect(() => {
    let filterDrivers = props.driverPositions.filter((driver) =>
      props.categories.includes(parseInt(driver.categoryId))
    );
    driverRef.current = filterDrivers;
  }, [props.driverPositions, props.categories]);

  useEffect(() => {
    if (props.modalState) {
      timeRef.current = moment().format("MM/DD/YYYY HH:mm:ss");
      takePictureMap();
    }
  }, [props.modalState]);

  const takePictureMap = () => {
    if (!canvasRef.current) {
      setTimeout(() => {
        takePictureMap();
      }, 1000);
    } else {
      drawCanvas(canvasRef.current);
    }
  };

  const drawPolyline = (ctx, shapes) => {
    const { newPoints, extremsPoints } = buildCoordinates(shapes.PointsTrack);

    ctx.lineWidth = POLYLINE_LINE_WIDTH;
    ctx.strokeStyle = "#000";
    ctx.beginPath();

    let first = false;

    newPoints.forEach((point) => {
      if (first) {
        ctx.moveTo(point.x, point.y);
        first = true;
      } else {
        ctx.lineTo(point.x, point.y);
      }
    });

    ctx.stroke();

    return extremsPoints;
  };

  const drawDrivers = (ctx, extremsPoints) => {
    driverRef.current.forEach((driver) => {
      ctx.beginPath();
      let lat =
        (parseFloat(driver.position.lat) + MAX_GRADE) *
        MAX_DECIMAL_IN_LAT_AND_LNG;
      let lng =
        (parseFloat(driver.position.lng) + MAX_GRADE) *
        MAX_DECIMAL_IN_LAT_AND_LNG;

      let latDif = lat - extremsPoints.minlatitude;
      let lngDif = lng - extremsPoints.minlngitude;

      let newPoint = {
        x: ((latDif * MAX_SCALE) / extremsPoints.maxlatdiff).toFixed(0),
        y: ((lngDif * MAX_SCALE) / extremsPoints.maxlngdiff).toFixed(0),
        carNumber: `${driver.driverInfo?.CarNumber}`,
      };

      ctx.arc(newPoint.x, newPoint.y, 10, 0, (Math.PI / 180) * 360, true);
      ctx.fillStyle = "#2d7df1";
      ctx.fill();

      ctx.save();
      ctx.font = "8pt Verdana";
      ctx.fillStyle = "black";

      ctx.translate(newPoint.x, newPoint.y);
      ctx.rotate(ROTATION_ANGLE_FOR_IMG_TXT);

      ctx.translate(-newPoint.x, -newPoint.y);

      let dif = 4 * newPoint.carNumber.length;

      ctx.fillText(
        newPoint.carNumber,
        parseInt(newPoint.x) - dif,
        parseInt(newPoint.y) + 4
      );

      ctx.restore();
    });
  };

  const drawPois = (ctx, extremsPoints) => {
    props.poiTags.forEach((poi) => {
      if (!poi) return;

      ctx.beginPath();
      let lat =
        (parseFloat(poi.props.position.lat) + MAX_GRADE) *
        MAX_DECIMAL_IN_LAT_AND_LNG;
      let lng =
        (parseFloat(poi.props.position.lng) + MAX_GRADE) *
        MAX_DECIMAL_IN_LAT_AND_LNG;

      let latDif = lat - extremsPoints.minlatitude;
      let lngDif = lng - extremsPoints.minlngitude;

      let newPoi = {
        x: ((latDif * MAX_SCALE) / extremsPoints.maxlatdiff).toFixed(0),
        y: ((lngDif * MAX_SCALE) / extremsPoints.maxlngdiff).toFixed(0),
      };

      ctx.save();
      ctx.translate(newPoi.x, newPoi.y);
      ctx.rotate(ROTATION_ANGLE_FOR_IMG_TXT);

      ctx.translate(-newPoi.x, -newPoi.y);

      let newImages = new Image();
      newImages.src = poi.props.icon.url;

      ctx.drawImage(newImages, newPoi.x, newPoi.y);

      ctx.restore();
    });
  };

  const drawCanvas = (canvas) => {
    let shapes = props.getShapes();
    if (shapes.PointsTrack) {
      var ctx = canvas.getContext("2d");
      ctx.translate(100, 50);
      //Ajustar mapa
      ctx.translate(MAX_SCALE / 2, (MAX_SCALE + 100) / 2);
      ctx.rotate(30);
      ctx.translate(-(MAX_SCALE / 2), -(MAX_SCALE / 2));

      let extremsPoints = drawPolyline(ctx, shapes);
      drawDrivers(ctx, extremsPoints);
      drawPois(ctx, extremsPoints);
    }
    setTimeout(async () => {
      await exportAsImage(exportRef.current, "snapshot", MAX_SCALE, 200, 0);
      props.setModalSnapshot(false);
    }, 500);
  };

  const buildCoordinates = (pointsTrack) => {
    let newPoints = [];
    pointsTrack.forEach((point) => {
      newPoints.push({
        lat: (parseFloat(point.lat) + MAX_GRADE) * MAX_DECIMAL_IN_LAT_AND_LNG,
        lng: (parseFloat(point.lng) + MAX_GRADE) * MAX_DECIMAL_IN_LAT_AND_LNG,
      });
    });

    let extremsPoints = extremesOfLatitude(newPoints);

    let pixelPoints = getPixelPonts(newPoints, extremsPoints);
    /*maxlatitude: _maxlatitude, 
    //minlatitude: _minlatitude, 
    maxlngitud: _maxlngitud, */
    return {
      newPoints: pixelPoints,
      extremsPoints,
    };
  };

  const getPixelPonts = (newPoints, extremsPoints) => {
    let newPointRefs = [];
    newPoints.forEach((point) => {
      let latDif = point.lat - extremsPoints.minlatitude;
      let lngDif = point.lng - extremsPoints.minlngitude;

      newPointRefs.push({
        x: ((latDif * MAX_SCALE) / extremsPoints.maxlatdiff).toFixed(0),
        y: ((lngDif * MAX_SCALE) / extremsPoints.maxlngdiff).toFixed(0),
      });
    });
    return newPointRefs;
  };

  const extremesOfLatitude = (newPoints) => {
    let maxlatitude = null;
    let minlatitude = null;

    let maxlngitud = null;
    let minlngitude = null;
    newPoints.forEach((point) => {
      if (!maxlatitude) {
        maxlatitude = point.lat;
        minlatitude = point.lat;
      } else {
        if (point.lat > maxlatitude) {
          maxlatitude = point.lat;
        }
        if (point.lat < minlatitude) {
          minlatitude = point.lat;
        }
      }

      if (!maxlngitud) {
        minlngitude = point.lng;
        maxlngitud = point.lng;
      } else {
        if (point.lng > maxlngitud) {
          maxlngitud = point.lng;
        }

        if (point.lng < minlngitude) {
          minlngitude = point.lng;
        }
      }
    });

    let _maxlatitude = maxlatitude.toFixed(4);
    let _minlatitude = minlatitude.toFixed(4);
    let _maxlngitud = maxlngitud.toFixed(4);
    let _minlngitude = minlngitude.toFixed(4);
    return {
      //maxlatitude: _maxlatitude,
      minlatitude: _minlatitude,
      //maxlngitud: _maxlngitud,
      minlngitude: _minlngitude,
      maxlngdiff: _maxlngitud - _minlngitude,
      maxlatdiff: _maxlatitude - _minlatitude,
    };
  };

  const InputList = () => {
    const listItems = driverRef.current.map((driver, index) => {
      return (
        <div
          key={index}
          style={{ padding: "0.2rem", borderBottom: "1px solid black" }}
        >
          <div
            style={{
              width: "30px",
              float: "left",
              fontWeight: "bold",
              fontSize: "12px",
            }}
          >
            {driver.driverInfo?.CarNumber}
          </div>{" "}
          Lap:{driver.driverInfo?.Counter} speed:{driver.position?.speed}km/h
        </div>
      );
    });
    return <>{listItems}</>;
  };
  return (
    <Dialog
      modal={false}
      header={"Taking a snapshot"}
      className="p-fluid"
      visible={props.modalState}
      contentClassName="overflow-y-visible"
      onHide={() => props.setModalSnapshot(false)}
      closable={false}
      showHeader={false}
      style={{ width: "70vw" }}
    >
      <div className="p-grid snapshot" ref={exportRef}>
        <div
          className="p-field p-col-12"
          style={{ textAlign: "center", paddingTop: "20px" }}
          data-html2canvas-ignore="true"
        >
          Taking a snapshot...
        </div>
        <div className="p-field p-col-3">
          {timeRef.current}
          <div className="car-list">
            <InputList />
          </div>
        </div>
        <div className="p-field p-col-6">
          <canvas
            ref={canvasRef}
            width={MAX_SCALE + 200}
            height={MAX_SCALE + 200}
            style={{ border: "1px solid #d3d3d3", background: "white" }}
          >
            Your browser does not support the HTML canvas tag.
          </canvas>
        </div>
      </div>
    </Dialog>
  );
};
