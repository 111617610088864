import React, { createRef, useRef, useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import classNames from "classnames";

export const SendMessageCustomModal = (props) => {
  const [selectedDrivers, setSelectedDrivers] = useState([]);
  const [messageText, setMessageText] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const [currentDrivers, setCurrentDrivers] = useState([]);

  useEffect(() => {
    refreshCurrentDrivers();
  },[props.drivers]);

  const refreshCurrentDrivers = () => {
    let _drivers = props.drivers.map((d) => {
      return {
        label: d.driverInfo.CarNumber,
        value: d.driverInfo.invId,
        categoryId: d.categoryId,
        userId: d.userId,
      };
    });
    setCurrentDrivers(_drivers);
  };

  var footer = (
    <div>
      <Button
        label="Cancel"
        className="btn-secondary"
        onClick={() => close()}
      />
      <Button
        label="Send"
        className="btn-primary "
        onClick={(e) => sendMessage()}
      />
    </div>
  );

  const sendMessage = () => {
    setSubmitted(true);
    if (validateRequired()) {
      let drivers = selectedDrivers.map((d) => {
        return d;
      });

      props.sendMessage(messageText, drivers);
      props.setModalState(false);
    }
  };

  const close = () => {
    props.setModalState(false);
  };

  const validateRequired = () => {
    if (messageText === "") return false;
    if (selectedDrivers.length === 0) return false;
    return true;
  };

  return (
    <Dialog
      header="Send message"
      className="p-fluid"
      //visible={props.modalState}
      visible={true}
      contentClassName="overflow-y-visible"
      footer={footer}
      onHide={() => close()}
    >
      <div className="p-grid">
        <div className="p-field p-col-12">
          <InputText
            placeholder="message"
            id="messageText"
            value={messageText}
            maxLength="100"
            onChange={(e) => setMessageText(e.target.value)}
            required
            className={classNames({
              "p-invalid": submitted && messageText === "",
            })}
            autocomplete="off"
          />
          {submitted && messageText === "" && (
            <small className="p-invalid">Text is required.</small>
          )}
        </div>
        <div className="p-field p-col-12">
          <MultiSelect
            placeholder="select cars"
            name="carNumber"
            value={selectedDrivers}
            options={currentDrivers}
            itemTemplate={(option) => option.label }
            onChange={(e) => setSelectedDrivers(e.value)}
            required
            className={classNames({
              "p-invalid": submitted && selectedDrivers.length === 0,
            })}
          />
        </div>
        {submitted && selectedDrivers.length === 0 && (
          <small className="p-invalid">
            You must choose at least one driver.
          </small>
        )}
      </div>
    </Dialog>
  );
};
