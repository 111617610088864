export const ALERT_TYPES = {
  FIRE: 3,
  ROAD_BLOCKED: 2,
  MEDICAL: 1,
  RC: 4,
  SOS: 5,
  REDFLAGACK: 6,
  MESSAGEFLAGACK: 7,
  FLAGACK: 8,
  OK: 9,
};
