import { flag_types, flag_names } from "../utilities/const";
import { flag_destination } from "../utilities/const";
import { MapService } from "../service/MapService";
import useDrawDriver from "./useDrawDriver";

const useFlag = ({
  eventId,
  setFlagMessage,
  setFlagDestination,
  setModalCustomMessage,
  setAlertMessageBox,
  takePictiure,
  showToast,
  setModalOneSelectCar,
  setAskMessageConfirmation,
  setModalConfirmation,
  setShowSendMessageMenu,
  setModalSelectSector,
}) => {
  const mapService = new MapService();

  const { changeCircleOnDrivers, clearSOSDriver, lockedColorDriversRef } = useDrawDriver({ eventId });

  const sendFlag = (code, drivers, setShowMessageBox, safetyCarDelay) => {
    switch (code) {
      case flag_types.CUTOMMESSAGE:
        sendMessageWithCustom("", flag_destination.SELECTED_DRIVERS);
        break;
      case flag_types.REDFLAG:
        sendMessageWithConfirmation(
          "RED FLAG",
          flag_destination.ALLDRIVERS,
          "Are you sure you want to send a red flag?"
        );
        break;
      case flag_types.CHECKERED:
          sendMessageWithConfirmation(
            "CHECKERED FLAG",
            flag_destination.ALLDRIVERS,
            "Are you sure you want to send a checkered flag?"
          );
          break;
      case flag_types.SAFETYCAR:
        setShowMessageBox((m) => {
          return {
            display:true,
            message: "The sc flag will be sent",
            seconds: safetyCarDelay,
            class:"sc-flag-shortmesaagebox"
          }
        });

        setTimeout(()=>{
          sendMessage(code, "SC", 0, flag_destination.ALLDRIVERS, [], drivers);
          setShowMessageBox((m) => {
            return {
              ...m,
              display:false,
              message: "",
              seconds: 0
            }
          });
        }, (safetyCarDelay * 1000));
        break;
      case flag_types.BLACK:
        sendMessageWithSelectOne(
          "BLACK FLAG",
          flag_destination.ALLDRIVERS_AND_ONE
        );
        break;
      case flag_types.PENALTY:
        sendMessageWithSelectOne(
          "PENALTY FLAG",
          flag_destination.ALLDRIVERS_AND_ONE
        );
        break;
      case flag_types.BLACKANDWHITE:
        //sendMessageWithSelectOne("WARNING FLAG", flag_destination.ONE_DRIVER);
        sendMessageWithSelectOne(
          "WARNING FLAG",
          flag_destination.ALLDRIVERS_AND_ONE
        );
        break;
      case flag_types.YELLOW:
        sendMessageWithSector("YELLOW FLAG", 0, flag_destination.ALLDRIVERS);
        break;
      case flag_types.DOUBLEYELLOW:
        sendMessageWithSector(
          "DOUBLE YELLOW FLAG",
          0,
          flag_destination.ALLDRIVERS
        );
        break;
      case flag_types.GREEN:
        sendMessage(
          code,
          "GREEN FLAG",
          0,
          flag_destination.ALLDRIVERS,
          [],
          drivers
        );
        break;
      case flag_types.WHITE:
        sendMessage(
          code,
          "WHITE FLAG",
          0,
          flag_destination.ALLDRIVERS,
          [],
          drivers
        );
        break;
      case flag_types.LOWADHESION:
        sendMessageWithSector("SURFACE FLAG", 0, flag_destination.ALLDRIVERS);
        break;

      default:
        break;
    }
  };

  const sendMessageWithSector = async (message, destination) => {
    setFlagMessage(message);
    setFlagDestination(destination);
    setModalSelectSector(true);
  };

  const sendMessageWithSelectOne = async (message, destination) => {
    setFlagMessage(message);
    setFlagDestination(destination);
    setModalOneSelectCar(true);
  };

  const sendMessageWithConfirmation = async (
    message,
    destination,
    askMessageConfirmation
  ) => {
    setFlagMessage(message);
    setFlagDestination(destination);
    setAskMessageConfirmation(askMessageConfirmation);
    setModalConfirmation(true);
  };

  const sendMessageWithCustom = async (message, destination) => {
    setFlagMessage(message);
    setFlagDestination(destination);
    setModalCustomMessage(true);
  };

  const sendMessage = async (
    code,
    message,
    sectorId,
    destination,
    selectedDrivers,
    drivers
  ) => {
    if (
      (destination === flag_destination.ALLDRIVERS_AND_ONE ||
        destination === flag_destination.ONE_DRIVER) &&
      selectedDrivers.length > 1
    ) {
      setAlertMessageBox("Cannot send more than one pilot at a time");
      return;
    }

    try {
      let invitations = [];
      let sanctionedDriver = 0;

      //Determinar el Sanctioned
      if (
        destination === flag_destination.ALLDRIVERS_AND_ONE ||
        destination === flag_destination.ONE_DRIVER
      ) {
        sanctionedDriver = selectedDrivers[0];
      }

      //Determinar los destinatarios
      if (
        destination === flag_destination.SELECTED_DRIVERS ||
        destination === flag_destination.ONE_DRIVER
      ) {
        selectedDrivers.forEach((invitationId) => {
          invitations.push(invitationId);
        });
      }

      //Banderas que no muestra el mensaje
      let WHITOUTMESSAGEFLAGS = [
        flag_types.PENALTY,
        flag_types.BLACKANDWHITE,
        flag_types.YELLOW,
        flag_types.DOUBLEYELLOW,
        flag_types.LOWADHESION,
      ];

      if (WHITOUTMESSAGEFLAGS.indexOf(code) > -1) message = "";

      if (code === flag_types.SAFETYCAR) {
        await takePictiure();
      }
      await mapService.sendMessageToDrivers({
        StateId: code,
        InvitationsSelected: invitations,
        EventId: eventId,
        Message: message,
        SectorId: sectorId,
        SanctionedDriver: sanctionedDriver,
        //Categories: [ALL_CATEGORIES],
        });

      setShowSendMessageMenu(false);

      if (code === flag_types.CUTOMMESSAGE) {
        showToast(`message sent!`, "", "success", 3000);
      } else {
        changeCircleOnDrivers(drivers, selectedDrivers, code);
        showToast(flag_names[code] + ` flag sent!`, "", "success", 3000);
      }
    } catch (error) {}
  };

  const clearSOSFlag = async (drivers) => {
    await mapService.cleanFlags(eventId);
    clearSOSDriver(drivers);
  };

  return {
    sendFlag,
    sendMessage,
    clearSOSFlag,
    lockedColorDriversRef
  };
};

export default useFlag;
